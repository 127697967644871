/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {AuthVideo} from "../../common/authVideo/AuthVideo";

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        {/* begin::Body */}
        <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
          {/* begin::Form */}
          <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
            {/* begin::Wrapper */}
            <div className='w-lg-500px p-10'>
              <Outlet/>
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Form */}
        </div>
        {/* end::Body */}

        {/* begin::Aside */}
        <div
            className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
            style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
        >
          {/* begin::Content */}
          <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
            {/*<div className={"position-fixed"}>*/}
            {/*  <div className={"d-flex gap-10"}>*/}
            {/*    <div className={"d-flex flex-column gap-10 -mt-10"}>*/}
            {/*      <AuthVideo/>*/}
            {/*      <AuthVideo/>*/}
            {/*      <AuthVideo/>*/}
            {/*    </div>*/}
            {/*    <div className={"d-flex flex-column gap-10"}>*/}
            {/*      <AuthVideo/>*/}
            {/*      <AuthVideo/>*/}
            {/*      <AuthVideo/>*/}
            {/*      <AuthVideo/>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <img
                className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
                src={toAbsoluteUrl('/media/misc/auth-screens.png')}
                alt=''
            />
          </div>
          {/* end::Content */}
        </div>
        {/* end::Aside */}
      </div>
  )
}

export {AuthLayout}
