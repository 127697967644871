const getDeviceType = () => {
  const width = window.innerWidth;

  return {
    isMobile: width <= 768,
    isTablet: width > 768 && width <= 1024,
    isDesktop: width > 1024
  };
}

export default getDeviceType;
