import localTranslate from "./localTranslate";

export default function timeSince(date: string): string {
  const now = new Date();
  const then = new Date(date);
  const diffInMs = now.getTime() - then.getTime();

  const msToTime = (ms: number) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    return { seconds, minutes, hours, days, months, years };
  };

  const { seconds, minutes, hours, days, months, years } = msToTime(diffInMs);

  if (years > 0) return `${years} ${localTranslate("yil oldin", "год назад", "year ago")}`;
  if (months > 0) return `${months} ${localTranslate("oy oldin", "месяц назад", "month ago")}`;
  if (days >= 14) return `${Math.floor(days / 7)} ${localTranslate("hafta oldin", "неделя назад", "week ago")}`;
  if (days >= 1) return `${days} ${localTranslate("kun oldin", "день назад", "day ago")}`;
  if (hours > 0) return `${hours} ${localTranslate("soat oldin", "час назад", "hour ago")}`;
  if (minutes > 0) return `${minutes} ${localTranslate("daqiqa oldin", "минут назад", "minute ago")}`;
  if (seconds > 0) return `${seconds} ${localTranslate("soniya oldin", "секунд назад", "second ago")}`;

  return localTranslate("Hozir", "Сейчас", "Now");
}
