import React, {useEffect, useState} from 'react'
import {numberFormatter} from "../../helpers";
import {Field, Form, Formik} from "formik";
import {RegionProps} from "../../types/region";
import {StatsChart} from "../../components/common";
import {useIntl} from "react-intl";
import {useQuery} from "react-query";
import {getModeratorRegions, getModeratorScience, getStatics} from "../../api/_requests";
import {queryCacheOptions} from "../../mock/cacheOptions";
import {useAuth} from "../../components/layout/auth";
import {PageLink, PageTitle} from "../../components/layout/core";


type SetFieldValue = (field: string, value: string | number) => void;

export const StatisticsPage = () => {
  const intl = useIntl();
  const {currentUser} = useAuth();

  const breadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: "Arizalar bo'yicha statistikalar"}),
      path: "/",
      isSeparator: false,
      isActive: false,
    },
  ];

  const {data: regionData} = useQuery("getModeratorRegions", getModeratorRegions, {...queryCacheOptions});
  const {data: scienceData} = useQuery("getModeratorScience", getModeratorScience, {...queryCacheOptions});
  const [selectedUserRegionId, setSelectedUserRegionId] = useState<number | string>();
  const [selectedScienceId, setSelectedScienceId] = useState<number | string>();

  const typeStats = currentUser?.data.role === "moderator" ? {
    type: selectedScienceId ? "science" : "district",
    region: selectedScienceId ? null : selectedUserRegionId,
    science: selectedScienceId ? selectedScienceId : "all"
  } : {type: "region"};

  const {data: staticsData, refetch: refetchStatics} = useQuery(
      ["getStatics", typeStats],
      () => getStatics(typeStats),
      {
        enabled: !!typeStats.region || currentUser?.data.role !== "moderator",
      }
  );

  const staticsSeparator = (): { labels: string[], values: number[] } => {
    const labels: string[] = [];
    const values: number[] = [];

    staticsData?.data.results[0].items.map(item => {
      labels.push(item.name);
      values.push(item.appeals);
    });
    return {labels, values};
  };

  useEffect(() => {
    refetchStatics()
  }, [selectedScienceId, selectedUserRegionId]);


  const handleRegionChange = (e: React.ChangeEvent<HTMLSelectElement>, setFieldValue: SetFieldValue) => {
    const selectedRegionId = e.target.value;
    setSelectedUserRegionId(selectedRegionId);
    setSelectedScienceId("");
    setFieldValue("region", selectedRegionId);
    setFieldValue("science", "");
  };

  const handleScienceChange = (e: React.ChangeEvent<HTMLSelectElement>, setFieldValue: SetFieldValue) => {
    const selectedScienceId = e.target.value;
    setSelectedScienceId(selectedScienceId);
    setSelectedUserRegionId("");
    setFieldValue("science", selectedScienceId);
    setFieldValue("region", "");
  };

  return (
      <div className={"px-10"}>
        <PageTitle breadcrumbs={breadCrumbs}  description={intl.formatMessage({id: "Statistika"})}/>
        <div className="card rounded-4 border border-gray-200 p-10">
          <div className="row mb-5 row-gap-6">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div style={{borderWidth: "0.5px"}} className={"text-center border-dashed border-gray-400 rounded p-5"}>
                <h3>{intl.formatMessage({id: "Arizalar"})}</h3>
                <h1 className={"display-6 text-info"}>{numberFormatter(staticsData?.data.results[0].appeals || 0)}</h1>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div style={{borderWidth: "0.5px"}} className={"text-center border-dashed border-gray-400 rounded p-5"}>
                <h3>{intl.formatMessage({id: "Baholangan"})}</h3>
                <h1 className={"display-6 text-success"}>{numberFormatter(staticsData?.data.results[0].rated || 0)}</h1>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div style={{borderWidth: "0.5px"}} className={"text-center border-dashed border-gray-400 rounded p-5"}>
                <h3>{intl.formatMessage({id: "Baholanmagan"})}</h3>
                <h1 className={"display-6 text-warning"}>{numberFormatter(staticsData?.data.results[0].evaluation || 0)}</h1>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div style={{borderWidth: "0.5px"}} className={"text-center border-dashed border-gray-400 rounded p-5"}>
                <h3>{intl.formatMessage({id: "Rad etilgan"})}</h3>
                <h1 className={"display-6 text-danger"}>{numberFormatter(staticsData?.data.results[0].failed || 0)}</h1>
              </div>
            </div>
          </div>

          <Formik
              initialValues={{
                region: "",
                science: "",
              }}
              enableReinitialize={true}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={() => {
                refetchStatics();
              }}
          >
            {({setFieldValue, handleChange}) => (
                <Form>
                  <div className="row row-gap-4">
                    <div className="col-md-6">
                      <label className="form-label">{intl.formatMessage({id: "Viloyat"})}</label>
                      <Field
                          as="select"
                          name="region"
                          className="form-select"
                          onChange={(e) => {
                            handleRegionChange(e, setFieldValue);
                            handleChange(e);
                          }}
                      >
                        <option disabled selected value="">
                          {intl.formatMessage({id: "Tanlash"})}
                        </option>
                        <option value="all">
                          {intl.formatMessage({id: "Hammasi"})}
                        </option>
                        {regionData?.data?.map((region: RegionProps) => (
                            <option key={region.id} value={region.id}>
                              {region.name}
                            </option>
                        ))}
                      </Field>
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">{intl.formatMessage({id: "Fan"})}</label>
                      <Field
                          as="select"
                          name="science"
                          className="form-select"
                          onChange={(e) => {
                            handleScienceChange(e, setFieldValue);
                            handleChange(e);
                          }}
                      >
                        <option disabled selected value="">
                          {intl.formatMessage({id: "Tanlash"})}
                        </option>
                        <option value="all">
                          {intl.formatMessage({id: "Hammasi"})}
                        </option>
                        {scienceData?.data.map((science) => (
                            <option key={science.id} value={science.id}>
                              {science.name}
                            </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </Form>
            )}
          </Formik>

          <StatsChart labels={staticsSeparator().labels} values={staticsSeparator().values}/>
        </div>
      </div>
  )
}
