import React, {Fragment} from 'react'
import {KTIcon} from "../../../../../_metronic/helpers";
import {localTranslate} from "../../../../helpers";
import {useQuery} from "react-query";
import {getLandingRegulatoryDocuments} from "../../../../api/_requests";
import {BASE_URL} from "../../../../api/URLs";
import downloadFile from "../../../../helpers/downloadFile";
import {queryCacheOptions} from "../../../../mock/cacheOptions";

export const DocumentsSection = () => {
  const {data: regulatoryDocumentData} = useQuery("getLandingRegulatoryDocuments", getLandingRegulatoryDocuments, {...queryCacheOptions})

  return (
      <div className={"pt-10"} id={"regulatory-documents"}>
        <div className="container w-md-50 w-100 p-10 p-md-0" style={{ marginBottom: "10rem"}}>
          <div className="text-center pb-2">
            <h1 className={"display-6 my-5"}>{localTranslate("Normativ hujjatlar", "Нормативные документы", "Regulatory documents"
            )}</h1>
            <p className="fs-5 mb-10">{localTranslate("Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aut autem\n" +
                "              consectetur doloremque facere id natus necessitatibus numquam quo temporibus!", "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aut autem\n" +
                "              consectetur doloremque facere id natus necessitatibus numquam quo temporibus! ru", "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aut autem\n" +
                "              consectetur doloremque facere id natus necessitatibus numquam quo temporibus! en")}</p>
          </div>
          <hr style={{color: "#a2a2a2"}}/>
          {
            regulatoryDocumentData?.results?.map((document, index) => (
                <Fragment  key={index}>
                  {
                    index % 2 !== 0 ? <div className={`row my-15 row-gap-20 flex-column-reverse flex-md-row`}>
                      <div className="col-md-6">
                        <a rel="noreferrer" className={"h2"} href={BASE_URL + document?.file} target={"_blank"}>{document?.title} <KTIcon
                            iconName={"arrow-up-right"} iconType={"solid"} className={"fs-1 text-dark"}/></a>
                        <p className={"fs-5 mt-3"}>{document?.description}</p>
                        <a download onClick={() => downloadFile(BASE_URL + document?.file)}
                           className="btn btn-primary btn-sm">
                          <KTIcon iconName={"cloud-download"} iconType={"duotone"}
                                  className={"fs-1"}/> {localTranslate("Yuklab olish", "Скачать", "Download")}
                        </a>
                      </div>
                      <div className="col-md-6 d-flex align-items-center justify-content-center">
                        <img src={BASE_URL + document?.image} className={"w-100px"}
                             alt="O'zbekiston Respublikasi Davlat gerbi"/>
                      </div>
                    </div> : <div className="row my-15 row-gap-20">
                      <div className="col-md-6 d-flex align-items-center justify-content-center">
                        <img src={BASE_URL + document?.image} className={"w-100px"}
                             alt="O'zbekiston Respublikasi Davlat gerbi"/>
                      </div>
                      <div className="col-md-6">
                        <a rel="noreferrer" className={"h2"} href={BASE_URL + document?.file} target={"_blank"}>{document?.title} <KTIcon
                            iconName={"arrow-up-right"} iconType={"solid"} className={"fs-1 text-dark"}/></a>
                        <p className={"fs-5 mt-3"}>{document?.description}</p>
                        <a download onClick={() => downloadFile(BASE_URL + document?.file)}
                           className="btn btn-primary btn-sm">
                          <KTIcon iconName={"cloud-download"} iconType={"duotone"}
                                  className={"fs-1"}/> {localTranslate("Yuklab olish", "Скачать", "Download")}
                        </a>
                      </div>
                    </div>
                  }
                  {regulatoryDocumentData?.results?.length-1 > index && <hr style={{color: "#a2a2a2"}}/>}
                </Fragment>
            ))
          }
        </div>
      </div>
  )
}
