import {localTranslate} from "../helpers";

const teacherAppealsHeader = [
  { label: "№" },
  { label: "Turi" },
  { label: "Nomi" },
  { label: "Fan" },
  { label: "Ball" },
  { label: "O'quv yili" },
  { label: "Status" },
  { label: "Kerakli" },
];

const teacherAppealsBodyKey:string[] = [
  "id",
  "method.name",
  "name",
  "science.name",
  "ball",
  "academic_year.name",
  "status",
];



const teacherReportsBodyKey = [
  "id",
  "title",
  "message",
  "status",
  "academic_year",
  "created_at",
];

const methodistAppealsHeader = [
  {label: "№"},
  {label: localTranslate("F.I.O", "Ф.И.О.", "F.I.O")},
  {label: localTranslate("Viloyat", "Вилоят", "Region")},
  {label: localTranslate("Tuman / Shahar", "Туман / Шахар", "District")},
  {label: localTranslate("Fan", "Фан", "Science")},
  {label: localTranslate("Status", "Статус", "Status")},
  {label: localTranslate("Kerakli", "Керакли", "Required")},
];

const methodistAppealsBodyKey = [
  "id",
  "user.first_name",
  "user.address.region.name",
  "user.address.district.name",
  "_science.name",
  "status",
];

const methodistReportsHeader = [
  {label: "№"},
  {label: localTranslate("F.I.O", "Ф.И.О.", "F.I.O")},
  {label: localTranslate("Viloyat", "Вилоят", "Region")},
  {label: localTranslate("Tuman / Shahar", "Туман / Шахар", "District")},
  {label: localTranslate("Fan", "Фан", "Science")},
  {label: "ID"},
  {label: localTranslate("Status", "Статус", "Status")},
  {label: localTranslate("Kerakli", "Керакли", "Required")},
];

const methodistReportsBodyKey = [
  "id",
  "appeal.user.first_name",
  "appeal.user.address.region.name",
  "appeal.user.address.district.name",
  "appeal._science.name",
  "data_id",
  "status",
];

const adminAppealsHeader = [
  {label: "№"},
  {label: localTranslate("F.I.O", "Ф.И.О.", "F.I.O")},
  {label: localTranslate("Viloyat", "Вилоят", "Region")},
  {label: localTranslate("Tuman / Shahar", "Туман / Шахар", "District")},
  {label: localTranslate("Fan", "Фан", "Science")},
  {label: localTranslate("Status", "Статус", "Status")},
  {label: localTranslate("Kerakli", "Керакли", "Required")},
];

const adminAppealsBodyKey = [
  "id",
  "user.first_name",
  "user.address.region.name",
  "user.address.district.name",
  "_science.name",
  "status",
];

const adminMethodistHeader = [
  {label: "№"},
  {label: localTranslate("F.I.O", "Ф.И.О.", "F.I.O")},
  {label: localTranslate("Tuman / Shahar", "Туман / Шахар", "District")},
  {label: localTranslate("Fan", "Фан", "Science")},
  {label: localTranslate("Status", "Статус", "Status")},
  {label: localTranslate("Kerakli", "Керакли", "Required")},
];

const adminMethodistBodyKey = [
  "id",
  "user.first_name",
  "districts",
  "sciences",
  "is_active",
];

const adminReportsHeader = [
  { label: "№" },
  {label: localTranslate("F.I.O", "Ф.И.О.", "F.I.O")},
  {label: localTranslate("Viloyat", "Вилоят", "Region")},
  {label: localTranslate("Tuman / Shahar", "Туман / Шахар", "District")},
  {label: localTranslate("Fan", "Фан", "Science")},
  { label: "ID" },
  {label: localTranslate("Status", "Статус", "Status")},
  {label: localTranslate("Kerakli", "Керакли", "Required")},
];

const adminReportsBodyKey = [
  "id",
  "appeal.user.first_name",
  "appeal.user.address.region.name",
  "appeal.user.address.district.name",
  "appeal._science.name",
  "data_id",
  "status",
];

const adminChangeNameHeader = [
  {label: "№"},
  {label: localTranslate("F.I.O", "Ф.И.О.", "F.I.O")},
  {label: localTranslate("Matni", "Текст", "Text")},
  {label: "ID"},
  {label: localTranslate("Status", "Статус", "Status")},
  {label: localTranslate("Kerakli", "Керакли", "Required")},
];

const adminChangeNameBodyKey = [
  "id",
  "user.first_name",
  "reason",
  "user.id",
  "status",
];


export const tableOptions = {
  teacherAppealsHeader,
  teacherAppealsBodyKey,
  teacherReportsBodyKey,
  methodistAppealsHeader,
  methodistAppealsBodyKey,
  methodistReportsHeader,
  methodistReportsBodyKey,
  adminAppealsHeader,
  adminAppealsBodyKey,
  adminMethodistHeader,
  adminMethodistBodyKey,
  adminReportsHeader,
  adminReportsBodyKey,
  adminChangeNameHeader,
  adminChangeNameBodyKey,
}