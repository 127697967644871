export default (phoneNumber: string | number, type?: string) => {
  const cleaned = String(phoneNumber).replace(/\D/g, '');
  const regex = cleaned.match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);

  if (!regex) {
    return '';
  }

  // without plus and spaces formatter to 998943067661
  if(type === "toNumber"){
    return `998${regex[2]}${regex[3]}${regex[4]}${regex[5]}`;
  }

  const formatted = !regex[2]
    ? regex[1]
    : "+998" +
      ' ' +
      regex[2] +
      (regex[3] ? '-' + regex[3] : '') +
      (regex[4] ? '-' + regex[4] : '') +
      (regex[5] ? '-' + regex[5] : '');

  return formatted;
};