import Components from "app/components";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

export function Registration() {
  const auth = Components.section.auth.register
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route path="info" element={<auth.Info />} />
        <Route path="number-confirm" element={<auth.ConfirmNumber />} />
        <Route
          index
          element={<Navigate to={"/auth/register/number-confirm"} />}
        />
      </Route>
    </Routes>
  );
}
