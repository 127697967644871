import auth from "./auth";
import teacher from "./teacher";
import cabinet from "./to-all/cabinet";
import methodist from "./methodist";
import landing from "./landing";

const section = {
  auth,
  teacher,
  cabinet,
  methodist,
  landing
}

export default section