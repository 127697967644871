import React, {useEffect, useRef, useState} from 'react';
import {KTIcon, toAbsoluteUrl} from "../../../../../_metronic/helpers";
import {Link} from "react-router-dom";
import "./navbar.scss";
import {getLandingNavbars} from "../../../../api/_requests";
import {useQuery} from "react-query";
import {I18N_CONFIG_KEY, setLanguage} from "../../../../../_metronic/i18n/Metronici18n";
import {queryCacheOptions} from "../../../../mock/cacheOptions";
import {URL_REGISTER_INFO} from "../../../../mock/page_urls";
import isValidUrl from "../../../../helpers/isValidUrl";
import {localTranslate} from "../../../../helpers";
import {EDUCATION_SITE_URL} from "../../../../mock/constants";

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState('uz');
  const menuRef = useRef<HTMLUListElement | null>(null);
  const {data: navbars} = useQuery("navbars", getLandingNavbars, {...queryCacheOptions});

  useEffect(() => {
    const storedLang = localStorage.getItem(I18N_CONFIG_KEY);
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang && parsedLang.selectedLang) {
        setSelectedLang(parsedLang.selectedLang);
      }
    }
  }, []);

  const handleToggle = () => {
    setIsMenuOpen(prev => !prev);
  };

  const handleChangeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lang = e.target.value;
    setSelectedLang(lang);
    setLanguage(lang);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
      <div>
        <div className="container position-relative bg-light shadow-sm rounded-4 p-3 px-5">
          <div className="row align-items-center mobile__list">
            <div className="col-md-8 d-flex align-items-center gap-10">
              <div className={"d-flex align-items-center"}>
                <a href={EDUCATION_SITE_URL} target="_blank" rel="noopener noreferrer">
                  <img
                      className="img-fluid w-30px me-5"
                      src={toAbsoluteUrl("/media/logos/uzedu_logo.png")}
                      alt={localTranslate("Maktab va maktabgacha ta'lim vazirligi", "Министерство образования и науки", "Ministry of Public Education")}
                  />
                </a>
                <img
                    className="img-fluid w-30px"
                    src={toAbsoluteUrl("/media/logos/rtm_logo.png")}
                    alt={localTranslate("Respublika ta'lim markazi", "Республиканский центр образования", "Republican Center for Education")}
                />
              </div>

              <div className="desktop__menu">
                <ul className="navbar-nav d-flex fs-5 flex-row gap-5 align-items-center">
                  {
                    navbars?.results?.map((navbar, index) => (
                        <li className="nav-item" key={index}>
                          {
                            isValidUrl(navbar.slug) ?
                                <a href={navbar.slug} target={"_blank"}
                                   rel="noopener noreferrer"
                                   className={"nav-link d-flex align-items-center gap-2"}><span>{navbar.title}</span>
                                  <KTIcon
                                      iconName="arrow-up-right" iconType="solid" className="fs-1 text-dark"/></a> :
                                <Link className="nav-link"
                                      to={navbar.slug}>
                                  {navbar.title}
                                </Link>
                          }
                        </li>
                    ))
                  }
                </ul>
              </div>
            </div>

            {/* Mobile menu */}
            {isMenuOpen && (
                <ul
                    className={`mobile__menu position-absolute p-5 text-center flex-column top-100 w-100 navbar-nav bg-light rounded-4 shadow-sm d-flex fs-5 flex-row gap-3 align-items-center mt-3 ${isMenuOpen ? 'd-flex' : 'd-none'}`}
                    style={{left: "0px"}}
                    ref={menuRef}
                >
                  {
                    navbars?.results?.map((navbar, index) => (
                        <li className="nav-item" style={{width: '100%'}} key={index}>
                          {
                            isValidUrl(navbar.slug) ?
                                <a href={navbar.slug} target={"_blank"}
                                   rel="noopener noreferrer"
                                   className={"d-flex justify-content-center bg-light-primary text-primary align-items-center gap-2 pb-3"}
                                   style={{width: "100%"}}><span>{navbar.title}</span> <KTIcon
                                    iconName="arrow-up-right" iconType="solid" className="fs-1 text-primary"/></a> :
                                <Link className="nav-link bg-light-primary text-primary active" style={{width: '100%'}}
                                      to={navbar.slug}>
                                  {navbar.title}
                                </Link>
                          }
                        </li>
                    ))
                  }
                </ul>
            )}

            <div className="col-md-4">
              <div className="d-flex justify-content-end align-items-center">
                <select
                    value={selectedLang}
                    onChange={handleChangeLanguage}
                    name="language"
                    id="language"
                    className="form-select border-0"
                    style={{width: "80px"}}
                >
                  <option value="uz">O'Z</option>
                  <option value="ru">RUS</option>
                  <option value="en">EN</option>
                </select>

                <a
                    href={URL_REGISTER_INFO}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary desktop__menu d-flex align-items-center"
                >
  <span className={"pe-3"}>
    {localTranslate("Ro'yhatdan o'tish", "Регистрация", "Register")}
  </span>
                  <KTIcon iconName="user-square" className="fs-1 p-0" iconType="duotone"/>
                </a>

                <span className="mobile__menu" onClick={handleToggle}>
                <KTIcon iconName={isMenuOpen ? "cross" : "menu"} iconType="duotone" className="fs-3x p-0"/>
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
