import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useQuery} from 'react-query';
import {Empty, Filter, Loader, Pagination} from '../../components/common';
import {getLandingHeaders, getLandingPopularAppeals} from '../../api/_requests';
import {Header} from '../../components/layout/landing/header';
import {PopularWorksCard} from '../../components/section/landing/popularWorks/popularWorksCard';
import {queryCacheOptions} from '../../mock/cacheOptions';
import {IFilters} from '../../types';
import {useIntl} from 'react-intl';
import {useDebounce} from '../../hooks';
import {removeAuth} from "../../components/layout/auth";
import {ScrollTopComponent} from "../../../_metronic/assets/ts/components";

export const FilterManualsPage = () => {
  const [filters, setFilters] = useState<IFilters>({
    region: null,
    district: null,
    science: null,
    search: null,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPageChanging, setIsPageChanging] = useState(false);

  const intl = useIntl();
  const page = searchParams.get('page') || '1';
  const params = new URLSearchParams(searchParams.toString());

  const debouncedFilters = useDebounce(filters, 500);

  const {
    data: popularAppeals,
    refetch: refetchPopularAppeals,
    isLoading: popularAppealsIsLoading,
  } = useQuery(
      ['getLandingPopularAppeals', debouncedFilters],
      () => getLandingPopularAppeals(debouncedFilters, params.get('page')),
      {
        ...queryCacheOptions,
        enabled: !!debouncedFilters,
      }
  );

  useEffect(() => {
    setIsPageChanging(true);
    refetchPopularAppeals().finally(() => setIsPageChanging(false));
  }, [page, refetchPopularAppeals]);

  const handleApplyFilters = (filterState: IFilters) => {
    const adjustedFilters = {
      ...filterState,
      science: filterState.science === intl.formatMessage({id: 'Tanlash'}) ? null : filterState.science,
      region: filterState.region === intl.formatMessage({id: 'Tanlash'}) ? null : filterState.region,
      district: filterState.region === intl.formatMessage({id: 'Tanlash'}) || filterState.region === null ? null : filterState.district,
    };

    setFilters(adjustedFilters);
    setSearchParams({...Object.fromEntries(searchParams.entries()), page: '1'});
    refetchPopularAppeals();
  };

  const handleClearFilters = () => {
    setFilters({
      region: null,
      district: null,
      science: null,
      search: null,
    });
    refetchPopularAppeals();
  };

  const {data: headerData} = useQuery(
      ['getLandingHeaders', 'popular-works-page'],
      () => getLandingHeaders('popular-works-page'),
      {...queryCacheOptions}
  );


  useEffect(() => {
    removeAuth();
    ScrollTopComponent.goTop()
  }, []);

  return (
      <div>
        <Helmet>
          <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
          <title>
            Ommalashtirish.uz -{' '}
            {headerData?.results[0]?.title ||
                'Xalq ta’limi vazirligi huzuridagi Respublika ta’lim markazi yagona platformasi pedagoglarning ilg‘or ish - tajribalarni ommalashtirishga xizmat qiladi.'}
          </title>
          <meta charSet="UTF-8"/>
          <meta
              name="title"
              content={`Ommalashtirish.uz - ${
                  headerData?.results[0]?.title ||
                  'Xalq ta’limi vazirligi huzuridagi Respublika ta’lim markazi yagona platformasi pedagoglarning ilg‘or ish - tajribalarni ommalashtirishga xizmat qiladi.'
              }`}
          />
          <meta
              name="description"
              content={headerData?.results[0]?.description || 'Default description here'}
          />
          <meta
              name="keywords"
              content="Ommalashtirish, ommalashtirish ishlari, Xalq ta’limi ommalashtirish, Respublika ta’lim markazi ommalashtirish, ommalashtirishga ish jo'natish, ishlarni ommalashtirish, pedagoglar ishlarini ommalashtirish, Xalq ta’limi respublikada ommalashtirish, viloyatda ommalashtirish, ommalashtirish tumanda, pedagoglar ishlarini Respublikaда ommalashtirish, pedagoglarning ishlarini viloyatda ommalashtirish, pedagoglarning ishlarini tumanda ommalashtirish"
          />
          <meta name="author" content="Felix-ITS LLC"/>
          <meta name="yandex-verification" content="7f3e2df6132fbb50"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta
              property="og:title"
              content={`Ommalashtirish.uz - ${
                  headerData?.results[0]?.title ||
                  'Xalq ta’limi vazirligi huzuridagi Respublika ta’lim markazi yagona platformasi pedagoglarning ilg‘or ish - tajribalarni ommalashtirishga xizmat qiladi.'
              }`}
          />
          <meta property="og:type" content="website"/>
          <meta property="og:locale" content="uz_UZ"/>
          <meta property="og:url" content="https://ommalashtirish.uz/"/>
          <meta property="og:image" content="favicon.png"/>
          <meta property="og:image:type" content="image/png"/>
          <meta property="og:image:width" content="300"/>
          <meta property="og:image:height" content="300"/>
          <meta property="og:image:alt" content="Лого ommalashtirish.uz"/>
          <meta property="og:site_name" content="ommalashtirish.uz"/>
          <meta
              property="og:description"
              content="Xalq ta’limi vazirligi huzuridagi Respublika ta’lim markazi yagona platformasi pedagoglarning ilg‘or ish - tajribalarni ommalashtirishga xizmat qiladi."
          />
        </Helmet>

        <Header
            style={{height: '30vh'}}
            title={headerData?.results[0]?.title}
            description={headerData?.results[0]?.description}
        />
        <div className="container my-20">
          <Filter onApply={handleApplyFilters} onClear={handleClearFilters} filterType="landing"/>
          {isPageChanging || popularAppealsIsLoading ? (
              <Loader height={'30vh'}/>
          ) : popularAppeals?.results?.length > 0 ? (
              <div className="row mt-5 row-gap-5">
                {popularAppeals?.results?.map((appeal, index) => (
                    <div key={index} className="col-sm-6 col-md-6 col-lg-4">
                      <PopularWorksCard appeal={appeal}/>
                    </div>
                ))}
              </div>
          ) : (
              <Empty/>
          )}

          <Pagination fetchData={refetchPopularAppeals} data={popularAppeals}/>
        </div>
      </div>
  );
};
