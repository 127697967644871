import getNestedValue from "app/helpers/getNestedValue";
import clsx from "clsx";
import {FC, Fragment, ReactNode, useState} from "react";
import {useIntl} from "react-intl";
import "./styles.scss";
import {checkStatus, nameGenerator, timeSince} from "../../../helpers";
import formatDateDDMMYYY from "../../../helpers/formatDateDDMMYYY";
import {useSearchParams} from "react-router-dom";
import getDeviceType from "../../../helpers/getDeviceType";

interface ITableHeader {
  label: string | number;
}

interface IBodyItem {
  [key: string]: ReactNode | any;
}

interface IProps {
  title: string;
  header: ITableHeader[];
  body: IBodyItem[];
  bodyKey: string[];
  actions?: boolean;
  forPaginationData?: any;
  setUrl?: any;
  openUrl?: string;
  filterType?: string;
  removeClassNames?: boolean;
  onCheckboxChange?: (selectedRows: Set<number>) => void;
}

export const Table: FC<IProps> = ({
                                    title,
                                    header,
                                    body,
                                    bodyKey,
                                    forPaginationData,
                                    setUrl,
                                    actions = false,
                                    removeClassNames = false,
                                    onCheckboxChange
                                  }) => {
  const {formatMessage} = useIntl();
  const itemsPerPage = 10;
  const num = forPaginationData?.total_pages ? forPaginationData?.total_pages : 0;
  const [params, setParams] = useSearchParams();
  const {
    isMobile,
    isTablet,
    isDesktop
  } = getDeviceType()

  const handleNext = () => {
    if (forPaginationData?.links.next) {
      setUrl(forPaginationData?.links.next);
    }
  };

  const handlePrevious = () => {
    if (forPaginationData?.links.previous) {
      setUrl(forPaginationData?.links.previous);
    }
  };

  const changePageById = (pageId) => {
    const newParams = new URLSearchParams(params);
    newParams.set("page", pageId);
    setParams(newParams);
  };

  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  const handleRowClick = (index: number) => {
    handleCheckboxChange(index);
  };

  const handleCheckboxChange = (id: number) => {
    setSelectedRows(prevSelectedRows => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(id)) {
        newSelectedRows.delete(id);
      } else {
        newSelectedRows.add(id);
      }
      onCheckboxChange?.(newSelectedRows);
      return newSelectedRows;
    });
  };

  const textTruncate = ["message", "title", "reason", "device"];
  const removedHeaders = ["Kerakli"]

  const generatePaginationItems = (currentPage, totalPages) => {
    const delta = 2;
    const range: any[] = [];
    const rangeWithDots: any[] = [];
    let l;

    if (totalPages <= delta * 2 + 1) {
      for (let i = 1; i <= totalPages; i++) {
        range.push(i);
      }
    } else {
      range.push(1);
      for (let i = currentPage - delta; i <= currentPage + delta; i++) {
        if (i >= 2 && i < totalPages) {
          range.push(i);
        }
      }
      range.push(totalPages);
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  return (
      <>
        <div className={`${removeClassNames ? "card rounded-top-0" : "card rounded-4"}`}>
          {title.length > 1 && <div className={`${!removeClassNames && "py-7"} w-100 px-6 border-bottom`}>
              <h1 className={`fs-3`}>{formatMessage({id: title})}</h1>
          </div>}

          {isMobile ? (
              <div className="p-10">
                {body.map((item, index) => (
                    <div key={index} className="mb-3">
                      {bodyKey.map((subItem, subIndex) => (
                          <div key={subIndex} className="mb-2">
                            <div className="row justify-content-between align-items-center">
                              <div className="col d-flex justify-content-between align-items-center"
                                   style={{
                                     padding: '10px 0',
                                     borderBottom: subIndex === bodyKey.length - 1 && index === body.length - 1 ? 'none' : '2px dotted #eee'
                                   }}>
                                <h5 className="mb-0">
                                  <span
                                      className={`${!removedHeaders.includes(header[subIndex]?.label.toString()) && "pe-13"}`}>
                                    {!removedHeaders.includes(header[subIndex]?.label.toString()) &&
                                        header[subIndex]?.label?.toString()?.toUpperCase()}
                                  </span>
                                </h5>

                                <div className="text-end" style={{width: "100%"}}>
                                  {subItem === "actions" ? (
                                      <div className="d-flex justify-content-center" style={{width: "100%"}}>
                                        {item[subItem]}
                                      </div>
                                  ) : subItem === "status" ? (
                                      <span className={`badge badge-light-${checkStatus(item["status"])} p-3`}>
                    {checkStatus(item[subItem], "text").toUpperCase()}
                  </span>
                                  ) : subItem === "title" ? (
                                      <p className="truncate-2 mb-0" style={{width: "100%"}}>{item[subItem]}</p>
                                  ) : subItem === "report.text" ? (
                                      <p className="mb-0">{item[subItem]}</p>
                                  ) : subItem === "message" ? (
                                      <p className="truncate-2 mb-0" style={{width: "100%"}}>{item[subItem]}</p>
                                  ) : subItem === "user.first_name" ? (
                                      <p className="truncate-2 mb-0" style={{width: "100%"}}>
                                        {nameGenerator(getNestedValue(item, "user.first_name"), getNestedValue(item, "user.last_name"), getNestedValue(item, "user.family_name"), "fullname")}
                                      </p>
                                  ) : subItem === "reason" ? (
                                      <p className="truncate-2 mb-0" style={{width: "100%"}}>{item[subItem]}</p>
                                  ) : subItem === "is_active" ? (
                                      <p className="truncate-2 mb-0" style={{width: "100%"}}>
                                        {item[subItem] ? (
                                            <span
                                                className="badge badge-light-success">{formatMessage({id: "Bloklanmagan"})}</span>
                                        ) : (
                                            <span
                                                className="badge badge-light-danger">{formatMessage({id: "Bloklangan"})}</span>
                                        )}
                                      </p>
                                  ) : subItem === "sciences" ? (
                                      <p className="truncate-2 mb-0"
                                         style={{width: "100%"}}>{item[subItem].map(science => science.name).join(", ")}</p>
                                  ) : subItem === "districts" ? (
                                      <p className="truncate-2 mb-0"
                                         style={{width: "100%"}}>{item[subItem].map(district => district.name).join(", ")}</p>
                                  ) : subItem === "appeal.user.first_name" ? (
                                      <p className="truncate-2 mb-0" style={{width: "100%"}}>
                                        {nameGenerator(getNestedValue(item, "appeal.user.first_name"), getNestedValue(item, "appeal.user.last_name"), getNestedValue(item, "appeal.user.family_name"), "fullname")}
                                      </p>
                                  ) : subItem === "data_id" ? (
                                      <p className="truncate-2 mb-0" style={{width: "100%"}}>{item["id"]}</p>
                                  ) : subItem === "id" ? (
                                      <div className="d-block align-items-center gap-3 mb-0">
                                        {(forPaginationData?.current_page ? forPaginationData?.current_page - 1 : 0) * itemsPerPage + index + 1}
                                      </div>
                                  ) : subItem === "created_at" ? (
                                      <>{formatDateDDMMYYY(item[subItem])}</>
                                  ) : subItem === "last_activity" ? (
                                      <> {timeSince(item[subItem])}</>
                                  ) : subItem === "fake_status" ? (
                                      <span className="badge badge-light-success">{formatMessage({id: "Aktiv"})}</span>
                                  ) : (
                                      <p className="truncate-2">
                                        {getNestedValue(item, subItem) ? getNestedValue(item, subItem) : item[subItem]}
                                      </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                      ))}
                    </div>
                ))}
              </div>

          ) : <div className="table-responsive">
            <table className="table mb-0 table-row-bordered gy-7 gs-7">
              <thead>
              <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                {header.map(({label}) => (
                    <th key={label}>{label}</th>
                ))}
              </tr>
              </thead>
              <tbody>
              {body.map((item, index) => (
                  <tr className={`text-dark ${actions && "cursor-pointer"}`} key={index}
                      onClick={() => handleRowClick(item.id)}>
                    {bodyKey.map((subItem) => (
                        <td key={subItem} className={"text-break"}
                            style={textTruncate.includes(subItem) ? {width: "30%"} : {}}>
                          {subItem === "actions" ? (
                              <> {item[subItem]} </>
                          ) : subItem === "status" ? (
                              <span className={`badge badge-light-${checkStatus(item["status"])} p-3`}>
                              {checkStatus(item[subItem], "text").toUpperCase()}
                            </span>
                          ) : subItem === "title" ? (
                              <p className={"truncate-2"} style={{width: "100%"}}>{item[subItem]}</p>
                          ) : subItem === "report.text" ? (
                              <p>{item[subItem]}</p>
                          ) : subItem === "message" ? (
                              <p className={"truncate-2"} style={{width: "100%"}}>{item[subItem]}</p>
                          ) : subItem === "user.first_name" ? (
                              <p className={"truncate-2"}
                                 style={{width: "100%"}}>{nameGenerator(getNestedValue(item, "user.first_name"), getNestedValue(item, "user.last_name"), getNestedValue(item, "user.family_name"), "fullname")}</p>
                          ) : subItem === "reason" ? (
                              <p className={"truncate-2"} style={{width: "100%"}}>{item[subItem]}</p>
                          ) : subItem === "is_active" ? (
                              <p className={"truncate-2"} style={{width: "100%"}}>{item[subItem] ?
                                  <span
                                      className={"badge badge-light-success"}>{formatMessage({id: "Bloklanmagan"})}</span> :
                                  <span
                                      className={"badge badge-light-danger"}>{formatMessage({id: "Bloklangan"})}</span>}</p>
                          ) : subItem === "sciences" ? (
                              <p className={"truncate-2"}
                                 style={{width: "100%"}}>{item[subItem].map(item => item.name)}</p>
                          ) : subItem === "districts" ? (
                              <p className={"truncate-2"}
                                 style={{width: "100%"}}>{item[subItem].map(item => item.name)}</p>
                          ) : subItem === "appeal.user.first_name" ? (
                              <p className={"truncate-2"}
                                 style={{width: "100%"}}>{nameGenerator(getNestedValue(item, "appeal.user.first_name"), getNestedValue(item, "appeal.user.last_name"), getNestedValue(item, "appeal.user.family_name"), "fullname")}</p>
                          ) : subItem === "data_id" ? (
                                  <p className={"truncate-2"} style={{width: "100%"}}>{item["id"]}</p>
                              )
                              : subItem === "id" ? (
                                  <div className={"d-flex align-items-center gap-3"}>
                                    {actions &&
                                        <div className="form-check form-check-custom form-check-solid form-check-lg">
                                            <input
                                                className="form-check-input"
                                                value=""
                                                id={`flexCheckboxLg_${item[subItem]}`}
                                                type="checkbox"
                                                checked={selectedRows.has(item[subItem])}
                                                onChange={() => handleCheckboxChange(item[subItem])}
                                                onClick={(e) => e.stopPropagation()}
                                            />
                                        </div>}
                                    {(forPaginationData?.current_page ? forPaginationData?.current_page - 1 : 0) *
                                        itemsPerPage +
                                        index +
                                        1}
                                  </div>
                              ) : subItem === "created_at" ? (
                                  <>{formatDateDDMMYYY(item[subItem])}</>
                              ) : subItem === "last_activity" ? (
                                  <> {timeSince(item[subItem])}</>
                              ) : subItem === "fake_status" ? (
                                  <span className={"badge badge-light-success"}>{formatMessage({id: "Aktiv"})}</span>
                              ) : (
                                  <>
                                    {getNestedValue(item, subItem) ? getNestedValue(item, subItem)
                                        : item[subItem]}
                                  </>
                              )}
                        </td>
                    ))}
                  </tr>
              ))}
              </tbody>
            </table>
          </div>}

        </div>


        {forPaginationData && (
            <ul className="pagination mt-5">
              <li
                  className={clsx(
                      "page-item previous",
                      !forPaginationData?.links.previous && "disabled"
                  )}
                  onClick={handleNext}
              >
                <a href="#" className="page-link">
                  <i className="previous"></i>
                </a>
              </li>
              {generatePaginationItems(forPaginationData.current_page, forPaginationData.total_pages).map((p, index) => (
                  <li
                      key={index}
                      onClick={() => typeof p === "number" && changePageById(p)}
                      className={clsx(
                          "page-item cursor-pointer",
                          p === forPaginationData.current_page && "active",
                          p === "..." && "disabled"
                      )}
                  >
                    <a className="page-link">
                      {p}
                    </a>
                  </li>
              ))}
              <li
                  className={clsx(
                      `page-item next`,
                      !forPaginationData?.links.next && "disabled"
                  )}
                  onClick={handlePrevious}
              >
                <a href="#" className="page-link">
                  <i className="next"></i>
                </a>
              </li>
            </ul>
        )}
      </>
  );
};
