import React, {FC, useCallback, useEffect, useState} from 'react';
import {Question} from "../../../common";
import {useQuery} from "react-query";
import {getModeratorQuestions, getTeacherQuestions, postSubmitSolution} from "../../../../api/_requests";
import {BASE_URL_MEDIA} from "../../../../api/URLs";
import {useNotification} from "../../../../context/NotificationContext";
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../../layout/auth";
import {useIntl} from "react-intl";
import {KTIcon} from "../../../../../_metronic/helpers";

interface IProps {
  data: any;
}

const CheckMyselfSection: FC<IProps> = ({data}) => {
  const intl = useIntl();
  const {currentUser} = useAuth();
  const {id} = useParams<{ id: string }>();
  const fetchQuestions = currentUser?.data.role === "moderator" ? getModeratorQuestions : currentUser?.data.role === "super_admin" ? getModeratorQuestions : currentUser?.data.role === "user" ? getTeacherQuestions : getTeacherQuestions;
  const queryKey = ['questions', currentUser?.data.role];


  const {
    data: questionsData = [],
    isSuccess: questionsIsSuccess,
    isError: questionsIsError
  } = useQuery(queryKey, fetchQuestions,  {
    enabled: !!currentUser?.data.role
  });


  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedAnswers, setSelectedAnswers] = useState<{ [key: number]: number[] }>({});
  const [selectedRadioAnswer, setSelectedRadioAnswer] = useState<{ [key: number]: number }>({});
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    const allQuestionsAnswered = questionsData.every(question => {
      const selectedVariants = question.question[0].type === 'RADIO'
          ? [selectedRadioAnswer[question.id]]
          : selectedAnswers[question.id] || [];

      return selectedVariants.length > 0;
    });

    setErrorMessage(allQuestionsAnswered ? null : "Please answer all questions.");
  }, [selectedAnswers, selectedRadioAnswer, questionsData]);

  const handleInputChange = useCallback((questionId: number, answerId: number, isChecked: boolean) => {
    const question = questionsData
        .flatMap(data => data.question)
        .find(q => q.id === questionId);
    if (question?.type === 'RADIO') {
      setSelectedRadioAnswer(prevState => ({
        ...prevState,
        [questionId]: isChecked ? answerId : prevState[questionId]
      }));
    } else {
      setSelectedAnswers(prevState => {
        const updated = { ...prevState };
        if (!updated[questionId]) {
          updated[questionId] = [];
        }
        if (isChecked) {
          updated[questionId].push(answerId);
        } else {
          updated[questionId] = updated[questionId].filter(id => id !== answerId);
        }
        return updated;
      });
    }
  }, [questionsData]);

  const handleSubmit = useCallback(async (event: React.FormEvent) => {
    event.preventDefault();

    const formattedAnswers = questionsData.flatMap(question => {
        return question.question.map(q => {
          const selectedVariants = q.type === 'RADIO'
              ? [selectedRadioAnswer[q.id]]
              : selectedAnswers[q.id] || [];

          return {
            question: q.id,
            variant: selectedVariants,
          };
        }).filter(answer => answer.variant.length > 0)
    });


    try {
      const response =  await postSubmitSolution({ answers: formattedAnswers }, currentUser?.data?.role, id);
      if (response) {
        if (response.status === 200) {
          showNotification(response.data.message, "", "success");
          navigate("/main");
        }
      } else {
        showNotification(intl.formatMessage({id: "Xatolik mavjud"}), "", "warning");
      }
    } catch (error: any) {
      showNotification(intl.formatMessage({id: "Xatolik mavjud"}), "", "error");
    }
  }, [questionsData, selectedAnswers, selectedRadioAnswer, errorMessage]);

  return (
      <div className="row row-gap-5 align-items-start">
        <div className="col-md-4 card rounded-4 border border-gray-200 p-10 position-sticky start-0 h-100" style={{top: "80px"}}>
          <iframe
              style={{border: "none", overflow: "hidden"}}
              width="100%"
              height="500px"
              src={`${BASE_URL_MEDIA + data?.file}`}
              allowFullScreen={true}
              allowTransparency={true}
          >
          </iframe>
          <a href={`${BASE_URL_MEDIA + data?.file}`} target={"_blank"} className="btn btn-light-primary mt-5">{intl.formatMessage({id: "Alohida sahifada ochish"})} <KTIcon iconName={"arrow-up-right"} className={"fs-1"} iconType={"solid"}/></a>
        </div>
        <div className="col-md-8 overflow-auto">
          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-column gap-3">
              <div className="card rounded-4 border border-gray-200 p-10">
                <h4 className="text-gray-500">{questionsData[0]?.name}</h4>
                <div className="d-flex flex-column">
                  {questionsData[0]?.question.map((question, index) => (
                      <Question
                          key={question.id}
                          question={question}
                          index={index}
                          onInputChange={handleInputChange}
                          selectedAnswers={selectedAnswers}
                          selectedRadioAnswer={selectedRadioAnswer}
                      />
                  ))}
                </div>
              </div>

              <div className="card rounded-4 border border-gray-200 p-10">
                <h4 className="text-gray-500">{questionsData[1]?.name}</h4>
                <div className="d-flex flex-column">
                  {questionsData[1]?.question.map((question, index) => (
                      <Question
                          key={question.id}
                          question={question}
                          index={index}
                          onInputChange={handleInputChange}
                          selectedAnswers={selectedAnswers}
                          selectedRadioAnswer={selectedRadioAnswer}
                      />
                  ))}
                </div>
              </div>
              <div className="d-flex justify-content-end mt-5">
                <button type="submit" className="btn btn-primary">{intl.formatMessage({id: "Yakunlash"})}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
  );
};

export default CheckMyselfSection;
