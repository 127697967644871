import clsx from "clsx";
import { KTIcon, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  Search,
  ThemeModeSwitcher,
} from "../../../../../_metronic/partials";
import { useLayout } from "../../core";
import { Languages } from "../../../../../_metronic/partials/layout/header-menus/Languages";
import { LanguageSwitcher } from "../../../../../_metronic/partials/layout/header-menus/LanguageSwitcher";
import {BASE_URL} from "../../../../api/URLs";
import {useAuth} from "../../auth";
import {useQuery} from "react-query";
import {getNotification} from "../../../../api/_requests";
import {queryCacheOptions} from "../../../../mock/cacheOptions";

const itemClass = "ms-1 ms-md-4";
const btnClass =
  "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px";
const userAvatarClass = "symbol-35px";
const btnIconClass = "fs-2";

const Navbar = () => {
  const { config } = useLayout();
  const { currentUser, logout } = useAuth();
  const {data: notifications, refetch: refetchNotifications} = useQuery("notifications", getNotification, {...queryCacheOptions});
  const isExistNewNotifications = notifications?.results.filter((item) => !item.is_read);

  return (
    <div className="app-navbar flex-shrink-0">
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div> */}

   <div className={clsx('app-navbar-item', itemClass)}>
     <div
         data-kt-menu-trigger="{default: 'click'}"
         data-kt-menu-attach='parent'
         data-kt-menu-placement='bottom-end'
         className={"position-relative btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px"}
     >

       {isExistNewNotifications && isExistNewNotifications?.length > 0 && (
           <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute top-0 translate-middle start-50 animation-blink"></span>
       )}

         <KTIcon iconName='message-text-2' className={btnIconClass}/>

     </div>
     <HeaderNotificationsMenu notifications={notifications?.results || []} refetch={refetchNotifications}/>
   </div>

      <div className={clsx("app-navbar-item", itemClass)}>
        {/* <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} /> */}
        <LanguageSwitcher
          menuTrigger="click"
          toggleBtnClass={clsx("btn-active-light-primary btn-custom")}
        />
      </div>
      <div className={clsx("app-navbar-item", itemClass)}>
        <ThemeModeSwitcher
          menuTrigger="click"
          toggleBtnClass={clsx("btn-active-light-primary btn-custom")}
        />
      </div>

      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <img src={currentUser?.data.avatar ? BASE_URL+currentUser?.data.avatar : toAbsoluteUrl("/media/avatars/blank.png")} alt="" />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div
          className="app-navbar-item d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_header_menu_toggle"
          >
            <KTIcon iconName="text-align-left" className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  );
};

export { Navbar };
