/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from "react";
import {useIntl} from "react-intl";
import {PageLink, PageTitle, PageToolbar} from "../../components/layout/core";
import {URL_TEACHER_REPORTS_NEW} from "../../mock/page_urls";
import {useNavigate, useParams} from "react-router-dom";
import {Card, Loader} from "../../components/common";
import {useQuery} from "react-query";
import {getCertificate, getTeacherAppealId, pollCertificateStatus, postCertificate} from "../../api/_requests";
import {BASE_URL_MEDIA} from "../../api/URLs";
import checkStatus from "../../helpers/checkStatus";
import {useAuth} from "../../components/layout/auth";
import {nameGenerator, phoneFormat} from "../../helpers";
import {NOT_ENTER} from "../../mock/text";
import {useNotification} from "../../context/NotificationContext";
import {queryCacheOptions} from "../../mock/cacheOptions";
import getDeviceType from "../../helpers/getDeviceType";

const AppealViewPage: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {id} = useParams<{ id: string }>();
  const {showNotification} = useNotification();
  const {data: certificateData} = useQuery("getCertificate", () => getCertificate(id));
  const [isReadyCertificate, setIsReadyCertificate] = useState<boolean>(true);

  const {data: teacherAppealId, isSuccess, isLoading} = useQuery(
      ["teacher_appeal_id", id],
      () => getTeacherAppealId(id),
      {
        enabled: !!id,
        ...queryCacheOptions
      }
  );

  const {currentUser} = useAuth();
  const teacherData = teacherAppealId?.data?.results[0];

  const breadCrumbs: PageLink[] = [
    {
      title: `${intl.formatMessage({id: "Ariza"})} / ${teacherData?.name}`,
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];

  const toolbars: PageToolbar[] = [
    {
      title: intl.formatMessage({id: "Shikoyat yuborish"}),
      icon: "shield-cross",
      isActive: teacherData?.status !== "PENDING",
      additional: {
        onClick: () => navigate(URL_TEACHER_REPORTS_NEW),
      },
    },
    {
      title: certificateData?.results?.[0]?.file_url ? intl.formatMessage({id: "Sertifikatni yuklab olish"}) : intl.formatMessage({id: "Sertifikatga buyurtma berish"}),
      icon: "file-down",
      isActive: isReadyCertificate,
      additional: {
        onClick: async () => {
          if (certificateData?.results?.[0]?.file_url) {
            window.open(certificateData?.results?.[0]?.file_url, "_blank");
          } else {
            try {
              const resPostCertificate = await postCertificate(teacherData?.id);
              showNotification(resPostCertificate?.data?.message, intl.formatMessage({id: "Iltimos kuting"}), "success");
              if (resPostCertificate?.status === 200 && resPostCertificate?.data?.status === "success") {
                setIsReadyCertificate(false);
                await pollCertificateStatus(teacherData.id);
                showNotification(certificateData?.message, "", "success");
              }
            } catch (e: any) {
              setTimeout(
                  ()=>{
                    showNotification(e?.data?.data?.message, "", "error");
                  }, 1000
              )
            } finally {

              setIsReadyCertificate(true);
            }
          }
        },
      },
    },
  ];

  return (
      <>
        <PageTitle
            breadcrumbs={breadCrumbs}
            toolbar={toolbars}
            description={intl.formatMessage({id: "Ariza yuborish va uni ko’rish"})}
        />
        {isLoading ? <Loader height={"70vh"}/> : <div className={"px-6 px-md-10"}>
          <div className="card rounded-4 border border-gray-200 p-6 p-md-10">
            <p className="fs-3 lh-large text-gray-500 d-flex">
              <b className="text-dark">{intl.formatMessage({id: "Nomi"})}:</b>
              <span className={"ps-2"}>{teacherData?.name}</span>
            </p>
            <p className="fs-3 text-gray-500 lh-lg d-flex flex-wrap text-break d-flex">
              <b className="text-dark">{intl.formatMessage({id: "Tavsif"})}:</b>
              <span className={"ps-2"}>{teacherData?.description}</span>
            </p>

            <div className="row row-gap-2 row-gap-md-3">
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={teacherData?.science?.name}
                    description={intl.formatMessage({id: "Fan"})}
                    icon="teacher"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={teacherData?.method?.name}
                    description={intl.formatMessage({id: "Metodik ishlanma turi"})}
                    icon="note-2"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={intl.formatMessage({id: "Metodik ishlanma"})}
                    description={
                      `${intl.formatMessage({id: "Yuklangan ish"})} <a href=${BASE_URL_MEDIA}${teacherData?.file}  target="_blank" download>${intl.formatMessage({id: "(Yuklab olish)"})}</a>`
                    }
                    icon="tablet-ok"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={checkStatus(teacherData?.status, "text")}
                    description={intl.formatMessage({id: "Status"})}
                    icon="award"
                    iconSize="4x"
                    padding="p-10"
                    status={checkStatus(teacherData?.status)}
                />
              </div>
            </div>

            <h3 className="text-gray-500 my-5">{intl.formatMessage({id: "Maktab Pedagogik kengash haqida"})}</h3>
            <div className="row row-gap-2 row-gap-md-3">
              <div className="col-md-4">
                <Card
                    title={teacherData?.decision_date}
                    description={intl.formatMessage({id: "Maktab Pedagogik kengash qarori sanasi"})}
                    icon="profile-user"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-md-4">
                <Card
                    title={`№ ${teacherData?.decision_number}`}
                    description={intl.formatMessage({id: "Maktab Pedagogik kengash qarori raqami"})}
                    icon="pin"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-md-4">
                <Card
                    title={intl.formatMessage({id: "Maktab Pedagogik kengash qarori fayli"})}
                    description={
                      `${intl.formatMessage({id: "Maktab Pedagogik kengash qarori"})} <a href=${BASE_URL_MEDIA}${teacherData?.decision_file} target="_blank" download>${intl.formatMessage({id: "(Yuklab olish)"})}</a>`
                    }
                    icon="verify"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
            </div>

            <h3 className="text-gray-500 my-5">{intl.formatMessage({id: "Qoshimcha malumotlar"})}</h3>
            <div className="row row-gap-2 row-gap-md-3">
              <div className="col-md-4">
                <Card
                    title={
                        currentUser && nameGenerator(
                            currentUser.data.first_name,
                            currentUser.data.last_name,
                            currentUser.data.family_name
                        )
                    }
                    description={intl.formatMessage({id: "F.I.O"})}
                    icon="user"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-md-4">
                <Card
                    title={currentUser?.data?.address?.region.name || NOT_ENTER}
                    description={intl.formatMessage({id: "Hudud"})}
                    icon="map"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-md-4">
                <Card
                    title={currentUser?.data?.address?.district.name || NOT_ENTER}
                    description={intl.formatMessage({id: "Tuman / Shahar"})}
                    icon="geolocation"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-md-4">
                <Card
                    title={currentUser?.data?.address?.organization.name || NOT_ENTER}
                    description={intl.formatMessage({id: "Maktab"})}
                    icon="book-open"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-md-4">
                <Card
                    title={currentUser && phoneFormat(currentUser?.data?.phone) || NOT_ENTER}
                    description={intl.formatMessage({id: "Telefon raqam"})}
                    icon="phone"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
            </div>
          </div>
        </div>
        }

      </>
  )
      ;
};

export {AppealViewPage};
