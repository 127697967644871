import React, {FC} from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {useIntl} from "react-intl";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels);

interface IProps {
  labels: string[];
  values: number[];
}

export const StatsChart:FC<IProps> = ({labels, values}) => {
  const intl = useIntl();
  const darkMode = localStorage.getItem('kt_theme_mode_menu') === 'dark';

  const data = {
    labels: labels,
    datasets: [
      {
        label: intl.formatMessage({id: "Arizalar soni"}),
        data: values,
        backgroundColor: '#3E97FF',
        borderRadius: 4,
        barThickness: 24,
      },
    ],
  };

  const options: any= {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return context.dataset.label + ': ' + context.raw;
          },
        },
      },
      datalabels: {
        display: true,
        anchor: 'end' as const,
        align: 'top' as const,
        formatter: (value: number) => value.toString(),
        color: `${darkMode ? "#fff" : "#000"}`,
        font: {
          weight: 'bold',
          size: 12,
        },
        offset: 4,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          drawOnChartArea: true,
          drawTicks: false,
          borderColor: 'rgba(0, 0, 0, 0.1)',
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        stepSize: 20,
        ticks: {
          callback: function(value) {
            if (value % 20 === 0) {
              return value;
            }
            return '';
          }
        },
        grid: {
          drawBorder: false,
          drawOnChartArea: true,
          drawTicks: false,
          color: "rgba(0, 0, 0, 0.1)",
          lineWidth: 1,
        },
        suggestedMax: Math.max(...values) + 1,
      },
    },
  };

  return (
      <div className="pt-10">
        <div style={{width: '100%', height: '50vh'}}>
          <Bar data={data} options={options}/>
        </div>
      </div>
  );
};
