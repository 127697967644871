import React from 'react'
import {useQuery} from "react-query";
import {getLandingMethodologicalProducts} from "../../../../api/_requests";
import {localTranslate} from "../../../../helpers";
import {BASE_URL} from "../../../../api/URLs";
import {queryCacheOptions} from "../../../../mock/cacheOptions";

export const MethodProductsSection = () => {
  const {data: methodProductsData} = useQuery("getLandingMethodologicalProducts", getLandingMethodologicalProducts, {...queryCacheOptions})


  return (
      <div style={{padding: "100px 0"}} id={"methodological-products"}>
        <div className="container p-10 p-md-0">
          <div className={"text-center w-100 w-sm-100 w-md-75 w-lg-50 mx-auto mb-10"}>
            <h1 className={"display-6 my-5"}>{localTranslate("Metodik mahsulotlar", "Методические продукты", "Methodical products")}</h1>
            <p className={"fs-5"}>{localTranslate("Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto, aspernatur dolor\n" +
                "              eius eveniet\n" +
                "              impedit iste molestiae nostrum officia quia quod?", "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto, aspernatur dolor\n" +
                "              eius eveniet\n" +
                "              impedit iste molestiae nostrum officia quia quod? ru", "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto, aspernatur dolor\n" +
                "              eius eveniet\n" +
                "              impedit iste molestiae nostrum officia quia quod? en")}</p>
          </div>

            {methodProductsData?.results?.map((product, index) => (
                index % 2 === 0 ? <div key={index} className="d-flex flex-column-reverse flex-md-row align-items-center column-gap-10">
                  <div className="col-md-6 pt-5 pt-md-0">
                    <h1>{product?.title}</h1>
                    <p className={"fs-5"}>{product?.description}</p>
                  </div>
                  <div className="col-md-6">
                    <img src={BASE_URL+product?.image} className={"w-100 img-fluid rounded-4"}
                         alt={product?.title}/>
                  </div>
                </div> : <div key={index} className="d-flex flex-column flex-md-row align-items-center mt-10 column-gap-10">
                  <div className="col-md-6">
                    <img src={BASE_URL+product?.image} className={"w-100 img-fluid rounded-4"}
                         alt={product?.title}/>
                  </div>
                  <div className="col-md-6 pt-5 pt-md-0">
                    <h1>{product?.title}</h1>
                    <p className={"fs-5"}>{product?.description}</p>
                  </div>
                </div>
            ))}
        </div>
      </div>
  )
}
