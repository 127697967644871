export const NOT_ENTER = 'Kiritilmagan';
export const NOT_FOUND = 'Topilmadi';
export const NOT_SELECTED = 'Tanlanmagan';
export const NOT_SPECIFIED = 'Belgilanmagan';
export const NOT_AVAILABLE = 'Mavjud emas';
export const NOT_PROVIDED = 'Berilmagan';
export const NOT_APPLICABLE = 'Muhim emas';
export const NOT_COMPLETED = 'Tugallanmagan';
export const NOT_CONFIRMED = 'Tasdiqlanmagan';
export const NOT_VERIFIED = 'Tekshirilmagan';
export const NOT_ALLOWED = 'Ruxsat berilmagan';
export const NOT_PERMITTED = 'Ruxsat berilmagan';
export const NOT_SUPPORTED = 'Qo\'llab-quvvatlanmagan';
