import React from 'react'
import {Footer} from "./footer";
import {Outlet} from "react-router-dom";
import {Content} from "../components/content";
import {ScrollTop} from "../components/scroll-top";
import {PageDataProvider} from "../core";


export const LandingLayout = () => {
  return (
      <PageDataProvider>
        <Content>
          <Outlet />
        </Content>
        <ScrollTop/>
        <Footer/>
      </PageDataProvider>
  )
}
