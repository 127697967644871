/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import * as Yup from "yup";
import clsx from "clsx";
import {Link} from "react-router-dom";
import {useFormik} from "formik";
import {getUserByToken, login,} from "../../components/layout/auth/core/_requests";
import {useIntl} from "react-intl";
import phoneFormat from "../../helpers/phoneFormat";
import onlyNumber from "../../helpers/onlyNumber";
import {setHeaderToken} from "../../api";
import {PasswordInput} from "../../components/common";
import {Helmet} from "react-helmet";
import {useAuth} from "../../components/layout/auth";


// const initialValues = {
//   phone: "+998 94-306-76-61",
//   password: "12345678Aa",
// };

const initialValues = {
  phone: "+998",
  password: "",
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const intl = useIntl();

  const loginSchema = Yup.object().shape({
    phone: Yup.string()
        .length(17, intl.formatMessage({id: "Bu telefon raqam emas"}))
        .required(intl.formatMessage({id: "Telefon raqam majburiy"})),
    password: Yup.string()
        .min(8, intl.formatMessage({id: "Minimal 8 ta belgi"}))
        // .matches(/[0-9]/, intl.formatMessage({id: "Kamida bitta raqam bo‘lishi majburiy"}))
        // .matches(/[a-z]/, intl.formatMessage({id: "Kamida bitta kichkina harf bo‘lishi majburiy"}))
        .required(intl.formatMessage({id: "Parol majburiy"})),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await login(
          onlyNumber(values.phone),
          values.password
        );

        saveAuth(auth);
        setHeaderToken(auth.access);
        const { data: user } = await getUserByToken();
        setCurrentUser(user);
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus(intl.formatMessage({id: "Telefon raqam yoki parol xato"}));
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
      <>
        <Helmet>
          <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
          <title>
            {intl.formatMessage({id: "Kirish | Ommalashtirish.uz - Oʻqituvchilarning ilgʻor tajribasi va faoliyati davomida qoʻllab kelayotgan zamonaviy metodikalarini ommalashtirish platformasi"})}
          </title>
          <meta charSet="UTF-8"/>
          <meta name="title"
                content="Ommalashtirish.uz - Oʻqituvchilarning ilgʻor tajribasi va faoliyati davomida qoʻllab kelayotgan zamonaviy metodikalarini ommalashtirish platformasi"/>
          <meta name="description"
                content="Maktabgacha va maktab taʼlimi tizimidagi oʻqituvchilarning ilgʻor ish tajribasi hamda faoliyati davomida qoʻllab kelayotgan zamonaviy metodikalarini ommalashtirish ishlarini maqbullashtirishga xizmat qiladi"/>
          <meta name="keywords" content="Ommalashtirish, ommalashtirish ishlari, Xalq ta’limi ommalashtirish, Respublika ta’lim markazi ommalashtirish, ommalashtirishga ish jo'natish, ishlarni ommalashtirish, pedagoglar ishlarini ommalashtirish, Xalq ta’limi respublikada ommalashtirish, viloyatda ommalashtirish, ommalashtirish tumanda, pedagoglar ishlarini Respublikada ommalashtirish, pedagoglarning ishlarini viloyatda ommalashtirish, pedagoglarning ishlarini tumanda ommalashtirish, Ommalashtirish , Omma, Omma ish, Ommalash ishi, ommalashtirish, omma, ommalash ishi , omma ish
  , Oммалаш, Оммалаштириш , Омма иши, Оммалаштириш, оммалаштириш ишлари, Халқ таълими оммалаштириш, Республика таълим маркази оммалаштириш, оммалаштиришга иш жўнатиш, ишларни оммалаштириш, педагоглар ишларини оммалаштириш, Халқ таълими республикада оммалаштириш, вилоятда оммалаштириш, оммалаштириш туманда, педагоглар ишларини Республикада оммалаштириш, педагогларнинг ишларини вилоятда оммалаштириш, педагогларнинг ишларини туманда оммалаштириш "/>
          <meta name="author" content="Felix-ITS LLC"/>
          <meta name="yandex-verification" content="7f3e2df6132fbb50"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta property="og:title"
                content="Ommalashtirish.uz - O'qituvchilar ishlarini ommalashtirish platformasi "/>
          <meta property="og:type" content="website"/>
          <meta property="og:locale" content="ru_RU"/>
          <meta property="og:url" content="https://ommalashtirish.uz/"/>
          <meta property="og:image" content="images/landing/logo2.svg"/>
          <meta property="og:image:type" content="image/jpeg"/>
          <meta property="og:image:width" content="300"/>
          <meta property="og:image:height" content="300"/>
          <meta property="og:image:alt" content="Лого ommalashtirish.uz"/>
          <meta property="og:site_name”" content="ommalashtirish.uz"/>
          <meta property="og:description”"
                content="Xalq ta’limi vazirligi huzuridagi Respublika ta’lim markazi yagona platformasi pedagoglarning ilg‘or ish - tajribalarni ommalashtirishga xizmat qiladi."/>
        </Helmet>
        <form
            className="form w-100"
            onSubmit={formik.handleSubmit}
            noValidate
            id="kt_login_signin_form"
        >
          <div className="text-center mb-11">
            <h1 className="text-dark fw-bolder mb-3">
              {intl.formatMessage({id: "Kirish"})}
            </h1>
            <div className="text-gray-500 fw-semibold fs-6">
              {intl.formatMessage({
                id: "Akauntga kirish uchun malumotlarni toldiring",
              })}
            </div>
          </div>

          <div className="fv-row mb-8">
            <label className="form-label fs-6 fw-bolder text-dark">
              {intl.formatMessage({id: "Telefon raqam"})}
            </label>
            <input
                placeholder={intl.formatMessage({id: "Telefon raqamingiz"})}
                {...formik.getFieldProps("phone")}
                onChange={(e) =>
                    formik.setFieldValue("phone", phoneFormat(e.target.value))
                }
                className={clsx(
                    "form-control bg-transparent",
                    {"is-invalid": formik.touched.phone && formik.errors.phone},
                    {
                      "is-valid": formik.touched.phone && !formik.errors.phone,
                    }
                )}
                type="text"
                name="phone"
                autoComplete="off"
            />
            {formik.touched.phone && formik.errors.phone && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.phone}</span>
                  </div>
                </div>
            )}
          </div>

          <div className="fv-row mb-3">
            <div className="mb-1">
              <label className="form-label fw-bolder text-dark fs-6 mb-0">
                {intl.formatMessage({id: "Parol"})}
              </label>
              <PasswordInput
                  placeholder={intl.formatMessage(({id: "Parolingizni kiriting"}))}
                  type="password"
                  autoComplete="off"
                  {...formik.getFieldProps("password")}
                  className={clsx(
                      {
                        "is-invalid": formik.touched.password && formik.errors.password,
                      },
                      {
                        "is-valid": formik.touched.password && !formik.errors.password,
                      }
                  )}
              />
              {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.password}</span>
                    </div>
                  </div>
              ) : (
                  <div className="text-muted mt-1">
                    {intl.formatMessage({
                      id: "8 yoki undan ko'p belgilar bilan foydalaning, harflar, raqamlar va belgilar aralashmasi bilan.",
                    })}
                  </div>
              )}
            </div>
          </div>

          {formik.status && (
              <div className="text-danger text-center fw-semibold fs-6">
                {intl.formatMessage({id: formik.status})}
              </div>
          )}


          <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
            <div/>

            <Link to={`/auth/reset-password/phone-number`} className="link-primary">
              {intl.formatMessage({id: "Parolni qayta tiklash"})}
            </Link>
          </div>

          <div className="d-grid mb-10">
            <button
                type="submit"
                id="kt_sign_in_submit"
                className="btn btn-primary"
                disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                  <span className="indicator-label">
              {intl.formatMessage({id: "Kirish"})}
            </span>
              )}
              {loading && (
                  <span className="indicator-progress" style={{display: "block"}}>
              {intl.formatMessage({id: "Iltimos kuting..."})}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
              )}
            </button>
          </div>

          <div className="text-gray-500 text-center fw-semibold fs-6">
            {intl.formatMessage({id: "Akkauntingiz yo'qmi?"})}{" "}
            <Link to={`/auth/register/info`} className="link-primary">
              {intl.formatMessage({id: "Ro'yhatdan o'tish"})}
            </Link>
          </div>
        </form>
      </>
  );
}
