export const faq = [
  {
    id: 1,
    question: 'Ommalashtirish vaqti qachon boshlanadi?',
    answer: <>
      <h3>Assalomu alaykum!</h3>
      <p>Ommalashtirish vaqti 2025 yil mart oyidan boshlanadi.</p>
    </>
  },
  {
    id: 2,
    question: 'Platformadan qanday foydalaniladi?',
    answer: <>
      <h3>Assalomu alaykum!</h3>
      <div className={"row"}>
        <div className="col-md-6">
          <iframe width={"100%"} height={"300px"} src="https://www.youtube.com/embed/FXpLPn78fRM?si=-kFqnGFH1pVgRN1J"
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>

        <div className="col-md-6">
          <iframe width={"100%"} height={"300px"} src="https://www.youtube.com/embed/A6KKkMWANe4?si=f4RNm3_uibzoJ5qo"
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
        <div className={"col-md-6"}>
          <iframe width={"100%"} height={"300px"} src="https://www.youtube.com/embed/Q-jtZuriyvs?si=t93EjfIEWI8hDv7v"
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

          <p className={"text-center"}>Ommalashtirish.uz dan ro'yhatdan o'tish va telegram botda kod olish</p>
        </div>

        <div className={"col-md-6"}>
          <iframe width={"100%"} height={"300px"} src="https://www.youtube.com/embed/ixzVxSWJ3TY?si=FTk87Z6DXyPR38-1"
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          <p className={"text-center"}>Ommalashtirish bo'yicha telefonda foydalanish qo'llanmasi</p>
        </div>
      </div>
    </>
  }
]
