import {OverviewSection} from "./Overview";
import {SettingsSection} from "./Settings";
import {ProfileSection} from "./Profile";
import {SecuritySection} from "./Security";

const cabinet = {
  OverviewSection,
  SettingsSection,
  ProfileSection,
  SecuritySection
}

export default cabinet