import React, {FC, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {PageLink, PageTitle} from "../../components/layout/core";
import {Empty, Filter, Loader} from "../../components/common";
import {checkStatus, downloadFile} from "../../helpers";
import {Link, useSearchParams} from "react-router-dom";
import {getAcademicYears, getModeratorAppeals, getTaskID, postExportSelected} from "../../api/_requests";
import {useQuery} from "react-query";
import Components from "../../components";
import {KTIcon} from "../../../_metronic/helpers";
import {mock} from "../../mock";
import {useNotification} from "../../context/NotificationContext";
import {IFilters} from "../../types";
import {queryCacheOptions} from "../../mock/cacheOptions";

const AppealsPage: FC = () => {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';

  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const {data: academicYearsData} = useQuery("getAcademicYears", getAcademicYears, {...queryCacheOptions});
  const {showNotification} = useNotification();

  const appealBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: "Barcha arizalar"}),
      path: "/",
      isSeparator: false,
      isActive: false,
    },
  ];


  const [filters, setFilters] = useState<IFilters>({
    ball_min: null,
    ball_max: null,
    jshir: null,
    status: null,
    year: null,
    region: null,
    district: null,
    search: null
  });

  const {data: appealsData, refetch: refetchAppeals, isLoading} = useQuery(
      ['appeals', filters],
      () => getModeratorAppeals(filters, page),
      {
        refetchOnWindowFocus: false,
        enabled: !!filters,
      }
  );

  const handleCheckboxChange = (selectedRows: Set<number>) => {
    setSelectedRows(selectedRows);
  };

  const handleApplyFilters = (filterState: IFilters) => {
    const selectedYear = academicYearsData?.data.find((year) => year.name === filterState.year);

    const adjustedFilters = {
      ...filterState,
      status: filterState.status === intl.formatMessage({id: 'Tanlash'}) ? null : filterState.status,
      year: filterState.year === intl.formatMessage({id: 'Tanlash'}) ? null : selectedYear?.id,
      region: filterState.region === intl.formatMessage({id: 'Tanlash'}) ? null : filterState.region,
      district: filterState.district === intl.formatMessage({id: 'Tanlash'}) ? null : filterState.district,
    };

    setFilters(adjustedFilters);
    refetchAppeals();
  };

  const handleClearFilters = () => {
    setFilters({
      ball_min: null,
      ball_max: null,
      jshir: null,
      status: null,
      year: null,
      region: null,
      district: null,
      search: null,
    });
    refetchAppeals();
  };

  const exportAppeals = async () => {
    const selectedIds = Array.from(selectedRows);
    try {
      const response = await getTaskID(selectedIds);
      const downloadCertificate = await postExportSelected(response?.data?.data?.task_id)

      if (downloadCertificate?.data?.results && downloadCertificate?.data?.status === "SUCCESS") {
        downloadFile(downloadCertificate?.data?.results)
      } else {
        showNotification(checkStatus(downloadCertificate?.data?.status, "text"), "", "warning")
      }
    } catch (error) {
      showNotification(intl.formatMessage({id: "Export qilishda xatolik"}), "", "warning")
    }
  };


  useEffect(() => {
    refetchAppeals();
  }, [page, refetchAppeals]);

  return (
      <>
        <PageTitle
            description="Sizga tegishli fan va viloyat bo’yicha barcha arizalar"
            breadcrumbs={appealBreadCrumbs}
        />
        <div className="px-10">

          <div className={"mt-5"}>
            <div
                className="card rounded-top-4 rounded-bottom-0 pb-5 border border-gray-200 d-flex w-100 justify-content-between align-items-end">
              <div className={"ps-7 pt-7 w-100"}>
                <h1 className="fs-3">{intl.formatMessage({id: "Yuborilgan arizalar"})}</h1>
                <Filter
                    onApply={handleApplyFilters}
                    onClear={handleClearFilters}
                    filterType="appeals"
                />
              </div>
              {
                  selectedRows.size > 0 && <div className={"pe-10 pt-10 d-flex align-items-end"}>
                      <button onClick={exportAppeals} className={"btn btn-primary"}>
                          <KTIcon iconName="cloud-download" className="fs-1"/>
                        {intl.formatMessage({id: "Yuklab olish"})}
                      </button>
                  </div>
              }
            </div>
            {isLoading ? (
               <Loader height={"70vh"}/>
            ) : appealsData?.results.length > 0 ? (
                <Components.Table
                    actions={true}
                    header={mock.tableOptions.adminAppealsHeader}
                    removeClassNames={true}
                    forPaginationData={appealsData}
                    title=" "
                    body={appealsData?.results.map((d) => ({
                      ...d,
                      actions: (
                          <div className="d-flex flex-row gap-2">
                            <Link to={`/appeals/${d.id}`} className="btn btn-icon btn-secondary">
                              <KTIcon iconName="eye" className="fs-1"/>
                            </Link>
                          </div>
                      ),
                    }))}
                    bodyKey={[...mock.tableOptions.adminAppealsBodyKey, "actions"]}
                    onCheckboxChange={handleCheckboxChange}
                />
            ) : <Empty title={intl.formatMessage({id: "Arizalar mavjud emas!"})}/>}
          </div>
        </div>
      </>
  );
};

export {AppealsPage};
