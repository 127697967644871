import {NOT_ENTER} from "../mock/text";

type Mode = 'letter' | 'fullname' | 'shortname';

export default (firstName: string, lastName: string, familyName?: string, mode?: Mode) => {
  if (!firstName || !lastName) {
    return NOT_ENTER;
  }

  if (mode === 'letter') {
    const firstInitial = firstName[0]?.toUpperCase();
    const lastInitial = lastName[0]?.toUpperCase();
    return `${firstInitial}${lastInitial}`;
  } else if (mode === 'fullname') {

    if (!familyName) {
      return `${firstName} ${lastName}`;
    } else {
      return `${firstName} ${lastName} ${familyName}`;
    }
  } else if (mode === 'shortname') {

    const lastInitial = lastName[0].toUpperCase();
    return `${firstName} ${lastInitial}.`;
  } else {

    if (!familyName) {
      return `${firstName} ${lastName}`;
    } else {
      return `${firstName} ${lastName} ${familyName}`;
    }
  }
};
