import React, { useState, useEffect, useRef } from 'react';
import { getAllUsers } from "../../../api/_requests";

interface User {
  id: number;
  first_name: string;
  last_name: string;
  family_name: string;
  jshir: string | null;
}

interface SearchableDropdownProps {
  initialOptions: User[];
  placeholder?: string;
  onSelect?: (option: User) => void;
}

export const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
                                                                        initialOptions,
                                                                        placeholder = 'Select an option',
                                                                        onSelect,
                                                                      }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState<User[]>(initialOptions);
  const [selectedOption, setSelectedOption] = useState<User | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchOptions = async () => {
      if (searchTerm.trim() === '') {
        setOptions(initialOptions);
        return;
      }

      setIsLoading(true);
      try {
        const response = await getAllUsers(searchTerm);
        setOptions(response.data);
        setIsError(false);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOptions();
  }, [searchTerm, initialOptions]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isMatch = (option: User) => {
    const term = searchTerm.toLowerCase();
    return (
        (option.first_name && option.first_name.toLowerCase().includes(term)) ||
        (option.last_name && option.last_name.toLowerCase().includes(term)) ||
        (option.family_name && option.family_name.toLowerCase().includes(term)) ||
        (option.jshir && option.jshir.toLowerCase().includes(term))
    );
  };

  const filteredOptions = options.filter(isMatch);

  const handleOptionClick = (option: User) => {
    setSelectedOption(option);
    setSearchTerm('');
    setIsOpen(false);
    if (onSelect) onSelect(option);
  };

  return (
      <div className="dropdown w-full" ref={dropdownRef}>
        <button
            className="btn btn-transparent border border-gray-300 dropdown-toggle w-100 d-flex justify-content-between align-items-center"
            type="button"
            id="dropdownMenuButton"
            aria-expanded={isOpen}
            onClick={() => setIsOpen(!isOpen)}
        >
          {selectedOption
              ? `${selectedOption.first_name} ${selectedOption.last_name} (${selectedOption.family_name})`
              : placeholder}
        </button>
        <ul
            className={`dropdown-menu w-100 ${isOpen ? 'show' : ''}`}
            aria-labelledby="dropdownMenuButton"
            style={{ maxHeight: '200px', overflowY: 'auto' }}
        >
          <li className="mt-2 p-2">
            <input
                type="text"
                className="form-control"
                placeholder="Qidiruv..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
          </li>
          {isLoading && <li className="dropdown-item">Yuklanmoqda...</li>}
          {isError && <li className="dropdown-item">Ma'lumot yuklashda xatolik</li>}
          {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                  <li
                      key={option.id}
                      className="dropdown-item"
                      onClick={() => handleOptionClick(option)}
                  >
                    {`${option.first_name} ${option.last_name} (${option.family_name})`}
                  </li>
              ))
          ) : (
              <li className="dropdown-item disabled">Ma'lumot topilmadi</li>
          )}
        </ul>
      </div>
  );
};
