import {FC} from 'react';
import {IntlProvider} from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/uz';
import '@formatjs/intl-relativetimeformat/locale-data/ru';
import '@formatjs/intl-relativetimeformat/locale-data/en';

import uzMessages from './messages/uz.json';
import ruMessages from './messages/ru.json';
import enMessages from './messages/en.json';

import {WithChildren} from '../helpers';
import {useLang} from './Metronici18n';

const allMessages = {
  uz: uzMessages,
  ru: ruMessages,
  en: enMessages
};

const onError = (err) => {
  if (err.code === 'MISSING_TRANSLATION') {
    return
  } else {
    console.error(err);
  }
};

const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang();
  const messages = allMessages[locale] || {};

  return (
      <IntlProvider locale={locale} messages={messages} onError={onError}>
        {children}
      </IntlProvider>
  );
};

export {I18nProvider};
