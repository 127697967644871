import {AppealsPage} from "./Appeals";
import {AppealViewPage} from "./AppealView";
import {ReportsPage} from "./Reports";
import {ReportViewPage} from "./ReportView";
import {DVEvaluationPage} from "./DVEvaluation";
import {StatisticsPage} from "./Statistics";

const methodist = {
  AppealsPage,
  AppealViewPage,
  DVEvaluationPage,
  ReportsPage,
  ReportViewPage,
  StatisticsPage
}

export default methodist