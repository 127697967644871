import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { setHeaderToken } from "app/api";
import { toAbsoluteUrl } from "_metronic/helpers";
import { URL_AUTH_REGISTER_INFO } from "app/mock/page_urls";
import clsx from "clsx";
import {
  getUserByToken,
  reuqestErpConfirmPhone,
} from "app/components/layout/auth/core/_requests";
import { useAuth } from "app/components/layout/auth";
import { TELEGRAM_BOT_URL } from "../../../../api/URLs";

const initialValues = {
  otp: "",
};

export function ConfirmNumber() {
  const length = 6;
  const navigate = useNavigate();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const confirmNumber = sessionStorage.getItem("flx_register_phone_confirm");
  const { saveAuth, setCurrentUser } = useAuth();

  const forgotPasswordSchema = Yup.object().shape({
    otp: Yup.string().length(6, intl.formatMessage({ id: "6 xonali kod kiritilmadi" })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        const { data: confirm } = await reuqestErpConfirmPhone(
          values.otp,
          confirmNumber
        );

        saveAuth(confirm.token);
        setHeaderToken(confirm.token.access);
        setSubmitting(true);
        const { data: user } = await getUserByToken();
        setCurrentUser(user);
        setLoading(false);
      } catch (error: any) {
        setSubmitting(false);
        setLoading(false);
        setStatus(error?.data?.data?.message);
      }
    },
  });

  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef<any>([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) {
      formik.setFieldValue("otp", combinedOtp);
    }

    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text");
    if (isNaN(paste) || paste.length !== length) return;

    const newOtp = paste.split("");
    setOtp(newOtp);
    formik.setFieldValue("otp", paste);
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1].focus();
    }
  };

  useEffect(() => {
    if (!confirmNumber) {
      navigate(`/auth${URL_AUTH_REGISTER_INFO}`);
    }
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework d-flex flex-column gap-2"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10 d-flex flex-column align-items-center gap-2">
        <img
          className="w-85px"
          src={toAbsoluteUrl("/media/svg/misc/smartphone-2.svg")}
        />
        <h1 className="text-dark fw-bolder mb-3">
          {intl.formatMessage({ id: "Telefon raqamni tasdiqlash" })}
        </h1>
        <div className="text-gray-500 fw-semibold fs-6">
          <span className="fw-bold text-gray-900">
            ********{confirmNumber?.slice(-4)}
          </span>{" "}
          {intl.formatMessage({ id: "Raqamiga" })}{" "}
          <a target={"_blank"} href={TELEGRAM_BOT_URL} className="link-primary">
            {intl.formatMessage({ id: "Telegram bot" })}{" "}
          </a>
          {intl.formatMessage({ id: "orqali yuborilgan" })}{" "}
          <span className="fw-bold text-gray-900">{intl.formatMessage({ id: "6 xonali" })}</span>{" "}
          {intl.formatMessage({ id: "tasdiqlash kodini kitiring" })}
        </div>
      </div>

      <div className="fv-row mb-8 d-flex flex-row gap-6 align-items-center justify-content-center">
        <div className={"d-flex gap-3 flex-wrap justify-content-center w-100"}>
          {otp.map((value, index) => {
            return (
              <input
                key={index}
                type="text"
                ref={(input) => (inputRefs.current[index] = input)}
                {...formik.getFieldProps("otp")}
                value={value}
                onChange={(e) => handleChange(index, e)}
                onClick={() => handleClick(index)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                onPaste={handlePaste}
                className={clsx(
                  "border-2 fs-2x border rounded-2 border-gray-300 w-60px h-60px text-center",
                  {
                    "is-invalid": formik.touched.otp && formik.errors.otp,
                  },
                  {
                    "is-valid": formik.touched.otp && !formik.errors.otp,
                  }
                )}
              />
            );
          })}
        </div>
        {formik.touched.otp && formik.errors.otp && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.otp}</span>
            </div>
          </div>
        )}
      </div>

      {formik.status && (
        <div className="text-danger text-center fw-semibold mb-1 fs-6">
          {intl.formatMessage({ id: formik.status })}
        </div>
      )}

      <div className="d-flex justify-content-center pb-lg-0 gap-2">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary w-100"
        >
          <span className="indicator-label">
            {intl.formatMessage({ id: "Tasdiqlash" })}
          </span>
          {loading && (
            <span className="indicator-progress">
              {intl.formatMessage({ id: "Iltimos kuting..." })}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link
          to={`/auth${URL_AUTH_REGISTER_INFO}`}
          onClick={() => localStorage.removeItem("flx_register_phone_confirm")}
          className="w-100"
        >
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light w-100"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {intl.formatMessage({ id: "Ortga" })}
          </button>
        </Link>{" "}
      </div>
    </form>
  );
}
