import React, {useEffect, useState} from 'react'
import {BASE_URL} from "../../api/URLs";
import { nameGenerator, phoneFormat} from "../../helpers";
import {KTIcon} from "../../../_metronic/helpers";
import {useNavigate, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {getModeratorViewId, updateModeratorViewId} from "../../api/_requests";
import {PageLink, PageTitle, PageToolbar} from "../../components/layout/core";
import {NOT_ENTER} from "../../mock/text";
import {mock} from "../../mock";
import {useIntl} from "react-intl";
import {URL_ADMIN_METHODISTS} from "../../mock/page_urls";

export const MethodistViewPage = () => {
  const intl = useIntl();
  const {id} = useParams<{ id: string }>();
  const [isBlocked, setIsBlocked] = useState<boolean>(true);
  const navigate = useNavigate();

  const {data: moderatorViewId, isSuccess, refetch} = useQuery(
      ["moderator_view_id", id],
      () => getModeratorViewId(id),
      {
        enabled: !!id,
        ...mock.queryCacheOptions
      }
  );

  const moderatorBreadcrumbs: Array<PageLink> = [
    {
      title: `${intl.formatMessage({id: "Barcha metodistlar"})} / ${nameGenerator(
          moderatorViewId?.user.first_name || "",
          moderatorViewId?.user.last_name || "",
          moderatorViewId?.user.family_name
      )}`,
      path: "/",
      isSeparator: false,
      isActive: false,
    },
  ];

  const moderatorToolbars: Array<PageToolbar> = [
    {
      title: `${moderatorViewId?.is_active ? intl.formatMessage({id: "Bloklash"}) : intl.formatMessage({id: "Blokdan chiqarish"})}`,
      icon: `${moderatorViewId?.is_active ? "shield-tick" : "shield-cross"}`,
      isActive: true,
      additional: {
        onClick: async () => {
          await updateModeratorViewId(id, !moderatorViewId?.is_active)
          refetch();
        },
      },
    },
  ];

  useEffect(() => {
    setIsBlocked(moderatorViewId?.is_active || true)
  }, []);

  useEffect(() => {
    if(!isSuccess){
      navigate(URL_ADMIN_METHODISTS)
    }
  }, []);


  return (
      <>
        <PageTitle
            description={intl.formatMessage({id: "Metodist haqida ma’lumotlar"})}
            breadcrumbs={moderatorBreadcrumbs}
            toolbar={moderatorToolbars}
        />
        <div className="px-10">
          <div className={"card rounded-4 border border-gray-200"}>
            <div className="d-flex p-10 gap-10">
              <div>
                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                  {
                    moderatorViewId?.user.avatar ? (
                        <img
                            className="img-fluid object-fit-cover"
                            src={BASE_URL + moderatorViewId?.user.avatar}
                            alt={"profile avatar"}
                        />
                    ) : (
                        <span
                            className="symbol-label fs-2x fw-semibold text-info card-info fw-bolder">{nameGenerator(
                            moderatorViewId?.user.first_name || "",
                            moderatorViewId?.user.last_name || "",
                            "",
                            "letter"
                        )}</span>
                    )
                  }
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div>
                  <h3>{nameGenerator(
                      moderatorViewId?.user.first_name || "",
                      moderatorViewId?.user.last_name || "",
                      moderatorViewId?.user.family_name
                  )}
                  </h3>

                  <div className="d-flex flex-wrap gap-7">
                    <div className="d-flex align-items-center">
                      <KTIcon iconName="profile-circle" className="fs-1 me-2"/>
                      <span
                          className="fs-4 text-gray-500">{moderatorViewId?.user.role === "user" ? intl.formatMessage({id: "O'qituvchi"}) : moderatorViewId?.user.role === "moderator" ? intl.formatMessage({id: "Metodist"}) : moderatorViewId?.user.role === "super_admin" ? intl.formatMessage({id: "Super admin"}) : intl.formatMessage({id: "Aniqlanmagan"})}</span>
                    </div>

                    {
                        moderatorViewId?.user.address && <div className="d-flex align-items-center">
                            <KTIcon iconName="geolocation" className="fs-1 me-2"/>
                            <span
                                className="fs-4 text-gray-500">{nameGenerator(moderatorViewId?.user?.address.region?.name, moderatorViewId?.user?.address.district?.name)}</span>
                        </div>
                    }

                    {
                        moderatorViewId?.user.phone &&
                        <div className="d-flex align-items-center">
                            <KTIcon iconName="sms" className="fs-1 me-2"/>
                            <span className="fs-4 text-gray-500">{
                              phoneFormat(moderatorViewId?.user.phone)
                            }</span>
                        </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
              isSuccess && <div className={"card rounded-4 border border-gray-200 mt-10"}>
                  <div>
                      <div className={"p-10 pb-3"}>
                          <h1>{intl.formatMessage({id: "Umumiy ma’lumotlar"})}</h1>
                      </div>
                      <hr className={"text-gray-500"}/>

                      <div className={"p-10 pt-5"}>
                          <div className="row fw-bold fs-3">
                              <div className="col-md-4 text-gray-500">
                                  <p>{intl.formatMessage({id: "Ism, Familiya, Otasining ismi"})}</p>
                              </div>
                              <div className="col-md-8 text-gray-800">
                                  <p>{nameGenerator(
                                      moderatorViewId?.user.first_name || "",
                                      moderatorViewId?.user.last_name || "",
                                      moderatorViewId?.user.family_name
                                  )}</p>
                              </div>
                          </div>

                          <div className="row fw-bold fs-3">
                              <div className="col-md-4 text-gray-500">
                                  <p>{intl.formatMessage({id: "Viloyat"})}</p>
                              </div>
                              <div className="col-md-8 text-gray-800">
                                  <p>{moderatorViewId?.user.address && moderatorViewId?.user.address.region.name ? moderatorViewId?.user.address.region.name : NOT_ENTER}</p>
                              </div>
                          </div>

                          <div className="row fw-bold fs-3">
                              <div className="col-md-4 text-gray-500">
                                  <p>{intl.formatMessage({id: "Maktab"})}</p>
                              </div>
                              <div className="col-md-8 text-gray-800">
                                  <p>{moderatorViewId?.user.address && moderatorViewId?.user.address.organization.name ? moderatorViewId?.user.address.organization.name : NOT_ENTER}</p>
                              </div>
                          </div>

                          <div className="row fw-bold fs-3">
                              <div className="col-md-4 text-gray-500">
                                  <p>{intl.formatMessage({id: "Telefon raqam"})}</p>
                              </div>
                              <div className="col-md-8 text-gray-800">
                                  <a href={`tel:${moderatorViewId?.user.phone}`}>{moderatorViewId?.user.phone ? phoneFormat(moderatorViewId?.user.phone) : NOT_ENTER}</a>
                              </div>
                          </div>

                      </div>
                  </div>
              </div>
          }
        </div>
      </>
  )
}
