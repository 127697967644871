/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {PageLink, PageTitle} from "../../components/layout/core";
import {Empty, Filter, Loader} from "../../components/common";
import {KTIcon} from "../../../_metronic/helpers";
import {getModeratorAppeals, getModeratorReports, getTaskID, postExportSelected} from "../../api/_requests";
import {checkStatus, downloadFile} from "../../helpers";
import {useQuery} from "react-query";
import Components from "../../components";
import {mock} from "../../mock";
import {useNotification} from "../../context/NotificationContext";
import {IFilters} from "../../types";


const ReportsPage: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';

  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const {showNotification} = useNotification();
  const [filters, setFilters] = useState<IFilters>({
    ball_min: null,
    ball_max: null,
    jshir: null,
    status: null,
    year: null,
    region: null,
    district: null,
    search: null
  });

  const reportsBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: "Barcha shikoyatlar"}),
      path: "/",
      isSeparator: false,
      isActive: false,
    },
  ];


  const {data: reportsData, refetch:refetchReports, isLoading} = useQuery(
      ['reports', filters],
      () => getModeratorReports(filters, page),
      {
        refetchOnWindowFocus: false,
        enabled: !!filters,
      }
  );

  const handleCheckboxChange = (selectedRows: Set<number>) => {
    setSelectedRows(selectedRows);
  };

  const handleApplyFilters = (filterState: IFilters) => {
    // const selectedRegion = academicYearsData?.data.find((year) => year.name === filterState.year);

    const adjustedFilters = {
      ...filterState,
      status: filterState.status === intl.formatMessage({id: 'Tanlash'}) ? null : filterState.status,
      region: filterState.region === intl.formatMessage({id: 'Tanlash'}) ? null : filterState.region,
      district: filterState.district === intl.formatMessage({id: 'Tanlash'}) ? null : filterState.district,
    };

    setFilters(adjustedFilters);
    refetchReports();
  };

  const handleClearFilters = () => {
    setFilters({
      ball_min: null,
      ball_max: null,
      jshir: null,
      status: null,
      year: null,
      region: null,
      district: null,
      search: null,
    });
    refetchReports();
  };

  const exportAppeals = async () => {
    const selectedIds = Array.from(selectedRows);
    try {
      const response = await getTaskID(selectedIds);
      const downloadCertificate = await postExportSelected(response?.data?.data?.task_id)
      if (downloadCertificate?.data?.results && downloadCertificate?.data?.status === "SUCCESS") {
        downloadFile(downloadCertificate?.data?.results)
      } else {
        showNotification(checkStatus(downloadCertificate?.data?.status, "text"), "", "warning")
      }
    } catch (error) {
      showNotification(intl.formatMessage({id: "Export qilishda xatolik"}), "", "warning")
    }
  };

  useEffect(() => {
    refetchReports();
  }, [page, refetchReports]);

  return (
      <>
        <PageTitle
            description={intl.formatMessage({id: "Kelib tushgan barcha shikoyatlar ro‘yxati"})}
            breadcrumbs={reportsBreadCrumbs}
        />
        <div className="px-10">
          <div
              className="card rounded-bottom-0 rounded-top-4 pb-5 border border-gray-200  d-flex w-100 justify-content-between align-items-end">
            <div className={"ps-7 pt-7 w-100"}>
              <h1 className="fs-3">{intl.formatMessage({id: "Shikoyatlar"})}</h1>
              <Filter
                  onApply={handleApplyFilters}
                  onClear={handleClearFilters}
                  filterType="reports"
              />
            </div>
            {
                selectedRows.size > 0 && <div className={"pe-10 pt-10 d-flex align-items-end"}>
                    <button onClick={exportAppeals} className={"btn btn-primary"}>
                        <KTIcon iconName="cloud-download" className="fs-1"/>
                      {intl.formatMessage({id: "Yuklab olish"})}
                    </button>
                </div>
            }
          </div>
          {isLoading ? (
              <Loader height={"50vh"}/>
          ) : reportsData?.results.length > 0 ? (
              <Components.Table
                  actions={true}
                  header={mock.tableOptions.methodistReportsHeader}
                  removeClassNames={true}
                  forPaginationData={reportsData}
                  title=" "
                  body={reportsData?.results.map((d) => ({
                    ...d,
                    actions: (
                        <div className="d-flex flex-row gap-2">
                          <Link to={`/reports/${d.id}`} className="btn btn-icon btn-secondary">
                            <KTIcon iconName="eye" className="fs-1"/>
                          </Link>
                        </div>
                    ),
                  }))}
                  bodyKey={[...mock.tableOptions.methodistReportsBodyKey, "actions"]}
                  onCheckboxChange={handleCheckboxChange}
              />
          ) : <Empty title={intl.formatMessage({id: "Shikoyatlar mavjud emas!"})}/>
          }
        </div>
      </>
  );
};

export {ReportsPage};
