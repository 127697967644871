import {useEffect} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {DrawerComponent} from '../../../../../_metronic/assets/ts/components'
import {WithChildren} from '../../../../../_metronic/helpers'

const Content = ({children}: WithChildren) => {
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  return (
    <div>
      {children}
    </div>
  )
}

export {Content}
