import { BASE_URL_MEDIA} from "../api/URLs";

const downloadFile = (fileUrl: string) => {
  const link = document.createElement("a");
  link.href = BASE_URL_MEDIA+fileUrl;
  link.download = fileUrl.split('/').pop() || "download.xlsx";
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadFile