import { FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../components/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import {URL_ADMIN_MAIN} from "../mock/page_urls";
import {adminPages} from "../mock/pages";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Appeals after success login/registartion */}
        <Route path="auth/*" element={<Navigate to={URL_ADMIN_MAIN} />} />
        {adminPages.map((page) => (
          <Route
            key={page.path}
            path={page.path}
            element={
              <SuspensedView>
                <page.component />
              </SuspensedView>
            }
          />
        ))}
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { AdminRoutes };
