import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLayout } from "../../../core";
import { usePageData } from "../../../core/PageData";
import { useIntl } from "react-intl";

const PageTitle = () => {
  const { pageDescription, pageBreadcrumbs } = usePageData();
  const { config, classes } = useLayout();
  const appPageTitleDirection = config.app?.pageTitle?.direction;
  const intl = useIntl();

  return (
    <div
      id="kt_page_title"
      data-kt-swapper="true"
      data-kt-swapper-mode="prepend"
      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
      className={clsx(
        "page-title d-flex flex-wrap me-3",
        classes.pageTitle.join(" "),
        config.app?.pageTitle?.class,
        {
          "flex-column justify-content-center gap-2":
            appPageTitleDirection === "column",
          "align-items-center": appPageTitleDirection !== "column",
        }
      )}
    >
      {pageBreadcrumbs && pageBreadcrumbs.length == 1 ? (
        <>
          {config.app?.pageTitle?.direction === "row" && (
            <span className="h-20px border-gray-300 border-start mx-4"></span>
          )}
          <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-1 my-0">
            {Array.from(pageBreadcrumbs).map((item, index) => (
              <li
                className={"breadcrumb-item text-dark"}
                key={`${item.path}${index}`}
              >
                {intl.formatMessage({ id: item.title })}
              </li>
            ))}
          </ul>
        </>
      ) : (
        <>
          {pageBreadcrumbs &&
            pageBreadcrumbs.length > 1 &&
            config.app?.pageTitle &&
            config.app?.pageTitle?.breadCrumb && (
              <>
                {config.app?.pageTitle?.direction === "row" && (
                  <span className="h-20px border-gray-300 border-start mx-4"></span>
                )}
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-1 my-0">
                  {Array.from(pageBreadcrumbs).map((item, index) => (
                    <li
                      className={clsx("breadcrumb-item", {
                        "text-dark": !item.isSeparator && item.isActive,
                        "text-muted": !item.isSeparator && !item.isActive,
                      })}
                      key={`${item.path}${index}`}
                    >
                      {!item.isSeparator && !item.isActive ? (
                        <Link
                          className="text-muted text-hover-primary"
                          to={item.path}
                        >
                          {intl.formatMessage({ id: item.title })}
                        </Link>
                      ) : !item.isSeparator && item.isActive ? (
                        <React.Fragment>
                          {intl.formatMessage({ id: item.title })}
                        </React.Fragment>
                      ) : (
                        <span className="text-muted">/</span>
                      )}
                    </li>
                  ))}
                </ul>
              </>
            )}
        </>
      )}

      {/* begin::Description */}
      {config.app?.pageTitle?.display && pageDescription && (
        <h1
          className={clsx("page-heading d-flex text-muted fw-bold fs-5 my-0", {
            "flex-column justify-content-center": appPageTitleDirection,
            "align-items-center": !appPageTitleDirection,
          })}
        >
          {intl.formatMessage({ id: pageDescription })}
          {pageDescription &&
            config.app?.pageTitle &&
            config.app?.pageTitle?.description && (
              <span
                className={clsx("page-desc text-muted fs-5 fw-semibold", {
                  "pt-2": appPageTitleDirection === "column",
                })}
              >
                {config.app?.pageTitle?.direction === "row" && (
                  <span className="h-20px border-1 border-gray-300 border-start ms-3 mx-2"></span>
                )}
                {intl.formatMessage({ id: pageDescription })}{" "}
              </span>
            )}
        </h1>
      )}
      {/* end::Description */}
    </div>
  );
};

export { PageTitle };
