import React from "react";
import {Dropzone} from "../../../../common";
import {KTIcon} from "../../../../../../_metronic/helpers";
import {Link, useNavigate, useOutletContext} from "react-router-dom";
import {getMethods, getSciences} from "../../../../../api/_requests";
import {useQuery} from "react-query";
import {useIntl} from "react-intl";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {
  URL_TEACHER_MAIN_NEW_ABOUT,
  URL_TEACHER_MAIN_NEW_DATA,
  URL_TEACHER_MAIN_NEW_PED
} from "../../../../../mock/page_urls";
import {queryCacheOptions} from "../../../../../mock/cacheOptions";

export function AppealAboutSection() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { aboutForm, setAboutForm } = useOutletContext<any>();

  const validationSchema = Yup.object({
    name: Yup.string().required(intl.formatMessage({id: "Nomi majburiy"})).max(400, intl.formatMessage({id: "Maksimal 400 belgi"})),
    science: Yup.string().required(intl.formatMessage({id: "Fan tanlash majburiy"})),
    description: Yup.string().required(intl.formatMessage({id: "Qisqa tavsif majburiy"})).max(4000, intl.formatMessage({id: "Maksimal 4 000 belgi"})),
    methodFile: Yup.mixed().required(intl.formatMessage({id: "Ishlanma faylini yuklash majburiy"})),
    type: Yup.string().required(intl.formatMessage({id: "Turi majburiy"})),
  });

  const { data: allSciences, isSuccess: allSciencesIsSuccess } = useQuery("getSciences", getSciences, {...queryCacheOptions});
  const { data: allMethods, isSuccess: allMethodsIsSuccess } = useQuery("getMethods", getMethods, {...queryCacheOptions});

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setAboutForm(prev => ({ ...prev, [name]: value }));
  };

  return (
      <Formik
          initialValues={{
            name: aboutForm?.name || "",
            science: aboutForm?.science || "",
            description: aboutForm?.description || "",
            methodFile: aboutForm?.methodFile || null,
            type: aboutForm?.type || "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setAboutForm(values);
          }}
      >
        {({ setFieldValue, validateForm, values, setTouched }) => (
            <Form>
              <div className="card rounded-4 border border-gray-200 p-5 p-md-10 p-lg-20 pb-0 pt-1">
                <div className="p-5 p-md-10 p-lg-10">
                  <div className="d-flex flex-wrap gap-5 gap-md-10 gap-lg-20 fs-3">
                    <Link to={URL_TEACHER_MAIN_NEW_ABOUT} className="fw-bolder text-primary border-primary border-bottom pb-0 pb-md-3 pb-lg-5">
                      {intl.formatMessage({id: "Malumot"})}
                    </Link>
                    <Link to={URL_TEACHER_MAIN_NEW_PED} className="text-dark pb-0 pb-md-3 pb-lg-5">{intl.formatMessage({id: "Ped kengash qarori"})}</Link>
                    <Link to={URL_TEACHER_MAIN_NEW_DATA} className="text-dark pb-0 pb-md-3 pb-lg-5">{intl.formatMessage({id: "Mutahasislik ma’lumotlari"})}</Link>
                  </div>

                  <div className="my-20">
                    <h1 className="fw-bold">{intl.formatMessage({id: "Malumotlarni kiriting"})}</h1>
                    <p className="text-gray-500 fw-bold">{intl.formatMessage({id: "Ariza uchun boshlang’ich ma’lumotlarni kiriting"})}</p>
                  </div>

                  <div className="mb-5">
                    <label className="form-label">
                      {intl.formatMessage({id: "Nomi"})} <span className="text-danger fs-3">*</span>
                    </label>
                    <Field
                        type="text"
                        className="form-control"
                        placeholder={intl.formatMessage({id: "Ishlanma nomini kiriting"})}
                        name="name"
                    />
                    <ErrorMessage name="name" component="div" className="text-danger mt-2"/>
                  </div>

                  <div className="mb-5">
                    <label className="form-label">
                      {intl.formatMessage({id: "Fan"})} <span className="text-danger fs-3">*</span>
                    </label>
                    <Field as="select" className="form-select" name="science">
                      <option disabled value="">
                        {intl.formatMessage({id: "Fan tanlang"})}
                      </option>
                      {allSciences?.map((item: any) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                      ))}
                    </Field>
                    <ErrorMessage name="science" component="div" className="text-danger mt-2"/>
                  </div>

                  <div className="mb-5">
                    <label className="form-label">
                      {intl.formatMessage({id: "Qisqa tavsif"})} <span className="text-danger fs-3">*</span>
                    </label>
                    <Field
                        as="textarea"
                        className="form-control"
                        placeholder={intl.formatMessage({id: "Qisqa tavsif"})}
                        rows={3}
                        name="description"
                    />
                    <ErrorMessage name="description" component="div" className="text-danger mt-2"/>
                  </div>

                  <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({id: "Turi"})} <span
                        className="text-danger fs-3">*</span></label>
                    <div className="d-flex">
                      {allMethods?.results[0].map((item: any) => (
                          <div key={item.id} className="form-check form-check-custom form-check-solid me-10">
                            <Field
                                className="form-check-input h-30px w-30px"
                                type="radio"
                                value={item.id}
                                id={item.id}
                                name="type"
                                onChange={()=>setFieldValue("type", item.id)}
                            />
                            <label className="form-check-label text-gray-700" htmlFor={item.id}>
                              {item.name}
                            </label>
                          </div>
                      ))}
                    </div>
                    <ErrorMessage name="type" component="div" className="text-danger mt-2"/>
                  </div>


                  <div className="mb-5">
                    <label className="form-label">
                      {intl.formatMessage({id: "Ishlanma faylini yuklang"})} <span className="text-danger fs-3">*</span>
                    </label>
                    <Dropzone
                        title={intl.formatMessage({id: "Metodik ishingiz faylini yuklang (pdf)"})}
                        fileDrop={values.methodFile}
                        setFileDrop={(methodFile) => setFieldValue("methodFile", methodFile)}
                    />
                    <ErrorMessage name="methodFile" component="div" className="text-danger mt-2"/>
                  </div>

                  <div className="d-flex">
                    <KTIcon iconName="information-2" className="fs-1 me-3"/>
                    <span className="fs-4 text-gray-500">
                  {intl.formatMessage({
                    id: "Metodik ishingiz pdf faylda bolishi va worddan togridan togri pdfga otkazilgan bolishi talab etiladi. Agar shunday holatda yuborilmasa bu ballarni tushishiga asos boladi.",
                  })}
                </span>
                  </div>

                  <div className="d-flex justify-content-end mt-10">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={async () => {
                          setTouched({
                            name: true,
                            science: true,
                            description: true,
                            methodFile: true,
                            type: true,
                          });
                          const errors = await validateForm();
                          if (Object.keys(errors).length === 0) {
                            setAboutForm(values);
                            navigate("/main/new/ped");
                          }
                        }}
                    >
                      {intl.formatMessage({id: "Davom etish"})} <KTIcon iconName="arrow-right" className="fs-1"/>
                    </button>
                  </div>
                </div>
              </div>
            </Form>
        )}
      </Formik>
  );
}
