import methodist from "./methodist";
import teacher from "./teacher";
import toAll from "./to-all";
import auth from "./auth";
import admin from "./admin";
import landing from "./landing";

export default {
  methodist,
  teacher,
  admin,
  toAll,
  auth,
  landing
}