import {Dropzone, Empty, Filter, Notification, StatsChart, Table} from "./common";
import section from "./section";

const Components = {
  section,
  StatsChart,
  Table,
  Empty,
  Dropzone,
  Filter,
  Notification
}

export default Components
