import React, {FC, useEffect} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {PageLink, PageTitle} from '../../components/layout/core';
import {AdditionalsSection, ByRegionSection, ReportsSection} from '../../components/section/admin';
import {useIntl} from "react-intl";



const ReportsPage: FC = () => {
  const intl = useIntl()
  const [searchParams] = useSearchParams();
  const section = searchParams.get('section') || 'all';
  const navigate = useNavigate();
  const validSections = ['all', 'additional'];

  const reportsBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: "Barcha shikoyatlar"}),
      path: '/',
      isSeparator: false,
      isActive: false,
    },
  ];

  useEffect(() => {
    if (!validSections.includes(section)) {
      navigate('/404');
    }
  }, [section, navigate]);

  const renderContent = () => {
    switch (section) {
      case 'all':
        return <ReportsSection/>;
      case 'additional':
        return <AdditionalsSection/>;
      default:
        return null;
    }
  };

  const currentPath = (pathname: string) => {
    return validSections.find((path) => pathname.includes(path)) || '';
  };

  return (
      <>
        <PageTitle
            description={intl.formatMessage({id: "Kelib tushgan barcha shikoyatlar ro‘yxati"})}
            breadcrumbs={reportsBreadCrumbs}
        />
        <div className="px-10">
          <div
              className="d-flex flex-wrap gap-5 gap-md-10 gap-lg-10 fs-3 ps-5 gap-10 bg-light border border-gray-200 border-bottom-0 pt-5 rounded-4 rounded-bottom-0">
            <Link
                to={`/reports/?section=all`}
                className={`fw-bold text-${currentPath(section) === 'all' ? 'primary' : 'gray-500'} ${currentPath(
                    section
                ) === 'all' ? 'border-primary' : 'gray-500'} ${currentPath(section) === 'all' && 'border-bottom'} pb-0 pb-md-1 pb-lg-5 mb-1`}
            >
              {intl.formatMessage({id: "Barcha shikoyatlar"})}
            </Link>

            <Link
                to={`/reports/?section=additional`}
                className={`fw-bold text-${currentPath(section) === 'additional' ? 'primary' : 'gray-500'} ${currentPath(
                    section
                ) === 'additional' ? 'border-primary' : 'gray-500'} ${currentPath(section) === 'additional' && 'border-bottom'} pb-0 pb-md-1 pb-lg-5 mb-1`}
            >
              {intl.formatMessage({id: "Qo’shimcha"})}
            </Link>
          </div>
          {renderContent()}
        </div>
      </>
  );
};

export {ReportsPage};
