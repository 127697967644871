/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from "react";
import {PageLink, PageTitle, PageToolbar} from "../../components/layout/core";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {URL_TEACHER_MAIN, URL_TEACHER_MAIN_NEW_ABOUT} from "app/mock/page_urls";
import {Empty, Loader} from "app/components/common";
import {useQuery} from "react-query";
import {getControlSettings, getTeacherAppeal} from "app/api/_requests";
import Components from "app/components";
import {KTIcon} from "_metronic/helpers";
import {downloadFile} from "../../helpers";
import {mock} from "../../mock";
import {useIntl} from "react-intl";
import getDeviceType from "../../helpers/getDeviceType";

const AppealsPage: FC = () => {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';
  const navigate = useNavigate();
  const {
    isMobile,
    isTablet,
    isDesktop
  } = getDeviceType()

  const {data: teacherAppeal, refetch: refetchAppeal, isSuccess, isLoading} = useQuery(
      ["teacher_appeal", page],
      () => getTeacherAppeal(page)
  );

  const {data: controlSettingsData} = useQuery("getControlSettings", getControlSettings)

  const appealsBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: "Mening arizam"}),
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];
  const appealsToolbars: Array<PageToolbar> = [
    {
      title: intl.formatMessage({id: "Ariza yuborish"}),
      icon: "notepad-edit",
      isActive: controlSettingsData && controlSettingsData[12]?.value,
      additional: {
        onClick: () => navigate(URL_TEACHER_MAIN_NEW_ABOUT),
      },
    },
  ];

  const teacherAppealsHeader = [
    {label: "№"},
    {label: intl.formatMessage({id: "Turi"})},
    {label: intl.formatMessage({id: "Nomi"})},
    {label: intl.formatMessage({id: "Fan"})},
    {label: intl.formatMessage({id: "Ball"})},
    {label: intl.formatMessage({id: "O'quv yili"})},
    {label: intl.formatMessage({id: "Status"})},
    {label: intl.formatMessage({id: "Kerakli"})},
  ];

  useEffect(() => {
    refetchAppeal();
  }, [page, refetchAppeal]);

  return (
      <>
      <PageTitle
          breadcrumbs={appealsBreadCrumbs}
          toolbar={appealsToolbars}
          description={intl.formatMessage({id: "Ariza yuborish va uni ko’rish"})}
      />
      <div className={"px-6 px-md-10"}>
        {isLoading ? <Loader height={"70vh"}/> :
            teacherAppeal?.data.results.length > 0 ? (
                <Components.Table
                    header={teacherAppealsHeader}
                    forPaginationData={teacherAppeal?.data}
                    title={intl.formatMessage({id: "Yuborilgan arizalar"})}
                    body={teacherAppeal?.data.results.map((d) => ({
                      ...d,
                      actions: (
                          <div className="d-flex flex-row gap-2">
                            <a onClick={()=>downloadFile(d.file)} download target="_blank"
                                  className={`btn ${(isDesktop || isTablet) && "btn-icon"} btn-lg btn-primary ${!d.file && "opacity-25"} `}>
                              <KTIcon iconName="file-down" className="fs-1"/>{isMobile && intl.formatMessage({id: "Yuklab olish"})}
                            </a>
                            <Link to={`${URL_TEACHER_MAIN}/${d.id}`} className={`btn ${(isDesktop || isTablet) && "btn-icon"} btn-lg btn-secondary`}>
                              <KTIcon iconName="eye" className="fs-1"/>{isMobile && intl.formatMessage({id: "Ko'rish"})}
                            </Link>
                          </div>
                      ),
                    }))}
                    bodyKey={[...mock.tableOptions.teacherAppealsBodyKey, "actions"]}
                />
          ) : (
          <Empty title={intl.formatMessage({id: "Arizalar mavjud emas"})}/>
      )}
      </div>
</>
)
  ;
};

export {AppealsPage};
