import {useEffect, useState} from "react";
import * as Yup from "yup";
import clsx from "clsx";
import {Link, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {useIntl} from "react-intl";
import {URL_AUTH_LOGIN, URL_AUTH_RESET_PASSWORD_PHONE_NUMBER} from "../../../../mock/page_urls";
import {requestRestorePAsswordNew} from "app/api/_requests";
import {PasswordInput} from "../../../common";

const initialValues = {
  password: "",
  password2: "",
};


export function NewPassword() {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const sSPhone = sessionStorage.getItem("flex_restore_password_phone");
  const sSCode = sessionStorage.getItem("flx_restore_password_confirmed_code");

  const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, intl.formatMessage({id: "Minimal 8 ta belgi"}))
        // .matches(/[0-9]/, intl.formatMessage({id: "Kamida bitta raqam bo‘lishi majburiy"}))
        // .matches(/[a-z]/, intl.formatMessage({id: "Kamida bitta kichkina harf bo‘lishi majburiy"}))
        .required(intl.formatMessage({id: "Parol majburiy"})),
    password2: Yup.string()
        .min(3, intl.formatMessage({id: "Minimal 3 ta belgi"}))
        .required(intl.formatMessage({id: "Parolni Tasdiqlash majburiy"}))
        .oneOf([Yup.ref("password")], intl.formatMessage({id: "Parol yuqoridagi bilan mos emas"})),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      try {
        const res = await requestRestorePAsswordNew(
          values.password,
          values.password2,
          String(sSPhone),
          Number(sSCode)
        );

        if(res?.status === 200){
          setHasErrors(false);
          navigate(`/auth${URL_AUTH_LOGIN}`);
        }

        setLoading(false);
        sessionStorage.removeItem("flx_restore_password_confirmed_code")
        sessionStorage.removeItem("flex_restore_password_phone")
      } catch (error: any) {
        setLoading(false);
        if ((error.response as any) && error.response.data) {
          setErrors(error.response.data);
          setSubmitting(false);
        }
      }
    },
  });

  useEffect(() => {
    if (!sSCode || !sSPhone) {
      navigate(`/auth${URL_AUTH_RESET_PASSWORD_PHONE_NUMBER}`);
    }
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">
          {intl.formatMessage({ id: "Yangi parol" })}
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-500 fw-semibold fs-6">
          {intl.formatMessage({
            id: "Yangi parol o’ylab toping va kiriting",
          })}
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className="mb-10 bg-light-info p-8 rounded">
          <div className="text-info">
            Sent password reset. Please check your email
          </div>
        </div>
      )}
      {/* end::Title */}

      <div className="fv-row mb-8" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({ id: "Parol" })}
          </label>
          <div className="position-relative mb-3">
            <PasswordInput
              type="password"
              placeholder={intl.formatMessage({ id: "Parol o'ylab toping" })}
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="text-muted">
          {intl.formatMessage({
            id: "8 yoki undan ko'p belgilar bilan foydalaning, harflar, raqamlar va belgilar aralashmasi bilan.",
          })}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className="fv-row mb-5">
        <label className="form-label fw-bolder text-dark fs-6">
          {intl.formatMessage({ id: "Parolni qayta kiriting" })}
        </label>
        <PasswordInput
          type="password"
          placeholder={intl.formatMessage({ id: "Parolni qayta kiriting" })}
          autoComplete="off"
          {...formik.getFieldProps("password2")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid":
                formik.touched.password2 && formik.errors.password2,
            },
            {
              "is-valid":
                formik.touched.password2 && !formik.errors.password2,
            }
          )}
        />
        {formik.touched.password2 && formik.errors.password2 && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password2}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group */}
      <div className="d-flex justify-content-center pb-lg-0 gap-2">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary w-100"
        >
          <span className="indicator-label">
            {intl.formatMessage({ id: "Saqlash" })}
          </span>
          {loading && (
            <span className="indicator-progress">
              {intl.formatMessage({ id: "Iltimos kuting..." })}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to={`/auth${URL_AUTH_LOGIN}`} className="w-100">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light w-100"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {intl.formatMessage({ id: "Bekor qilish" })}
          </button>
        </Link>{" "}
      </div>
      {/* end::Form group */}
    </form>
  );
}
