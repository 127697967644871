import { FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { landingPages} from "../mock/pages";
import {LandingLayout} from "../components/layout/landing";

const LandingRoutes = () => {
  return (
      <Routes>
        <Route element={<LandingLayout/>}>
          {landingPages.map((page) => (
              <Route
                  key={page.path}
                  path={page.path}
                  element={
                    <SuspensedView>
                      <page.component />
                    </SuspensedView>
                  }
              />
          ))}
          <Route path="*" element={<Navigate to="/error/404" />} />
        </Route>
      </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { LandingRoutes };
