import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { KTIcon } from '../../../../../_metronic/helpers';
import { useAuth } from "../../../layout/auth";
import { phoneFormat, nameGenerator } from "../../../../helpers";
import { PROFILE_OVERVIEW, PROFILE_SECURITY, PROFILE_SETTINGS } from "../../../../mock/page_urls";
import { BASE_URL } from "../../../../api/URLs";
import { useIntl } from "react-intl";

export const ProfileSection = () => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const { currentUser, logout } = useAuth();

  const currentPath = (pathname) => {
    const paths = ['settings', 'overview', "security"];
    return paths.find((path) => pathname.includes(path)) || '';
  };

  return (
    <>
      {currentUser &&
        <div className="card rounded-4 border border-gray-200">
          <div className="d-flex flex-column flex-md-row p-10 gap-10">
            <div className="d-flex justify-content-center">
              <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                {
                  currentUser?.data.avatar ? (
                    <img
                      className="img-fluid object-fit-cover"
                      src={BASE_URL + currentUser?.data.avatar}
                      alt={"profile avatar"}
                    />
                  ) : (
                    <span
                      className="symbol-label fs-2x fw-semibold text-info bg-light-info fw-bolder">{nameGenerator(
                        currentUser?.data.first_name,
                        currentUser?.data.last_name,
                        "",
                        "letter"
                      )}</span>
                  )
                }
              </div>
            </div>
            <div className="d-flex align-items-center flex-column flex-md-row">
              <div>
                <h3>{nameGenerator(
                  currentUser?.data.first_name,
                  currentUser?.data.last_name,
                  currentUser?.data.family_name
                )}
                </h3>

                <div className="d-flex flex-wrap gap-7">
                  <div className="d-flex align-items-center">
                    <KTIcon iconName="profile-circle" className="fs-1 me-2" />
                    <span
                      className="fs-4 text-gray-500">{currentUser?.data.role === "user" ? intl.formatMessage({ id: "O'qituvchi" }) : currentUser?.data.role === "moderator" ? intl.formatMessage({ id: "Metodist" }) : currentUser?.data.role === "super_admin" ? intl.formatMessage({ id: "Super admin" }) : intl.formatMessage({ id: "Aniqlanmagan" })}</span>
                  </div>

                  {
                    currentUser?.data.address && <div className="d-flex align-items-center">
                      <KTIcon iconName="geolocation" className="fs-1 me-2" />
                      <span className="fs-4 text-gray-500">{nameGenerator(currentUser?.data?.address.region?.name, currentUser?.data?.address.district?.name)}</span>
                    </div>
                  }

                  {
                    currentUser?.data.phone &&
                    <div className="d-flex align-items-center">
                      <KTIcon iconName="sms" className="fs-1 me-2" />
                      <span className="fs-4 text-gray-500">{
                        phoneFormat(currentUser?.data.phone)
                      }</span>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap gap-5 gap-md-10 gap-lg-10 fs-3 ps-10 gap-10">
            <Link
              to={PROFILE_OVERVIEW}
              className={`fw-bold text-${currentPath(pathname) === 'overview' ? 'primary' : 'gray-500'} ${currentPath(
                pathname
              ) === 'overview' ? 'border-primary' : 'gray-500'} ${currentPath(pathname) === 'overview' && 'border-bottom'} pb-0 pb-md-1 pb-lg-5 mb-1`}
            >
              {intl.formatMessage({ id: "Umumiy ma’lumotlar" })}
            </Link>

            <Link
              to={PROFILE_SETTINGS}
              className={`fw-bold text-${currentPath(pathname) === 'settings' ? 'primary' : 'gray-500'} ${currentPath(
                pathname
              ) === 'settings' ? 'border-primary' : 'gray-500'} ${currentPath(pathname) === 'settings' && 'border-bottom'} pb-0 pb-md-1 pb-lg-5 mb-1`}
            >
              {intl.formatMessage({ id: "Sozlamalar" })}
            </Link>

            <Link
              to={PROFILE_SECURITY}
              className={`fw-bold text-${currentPath(pathname) === 'security' ? 'primary' : 'gray-500'} ${currentPath(
                pathname
              ) === 'security' ? 'border-primary' : 'gray-500'} ${currentPath(pathname) === 'security' && 'border-bottom'} pb-0 pb-md-1 pb-lg-5 mb-1`}
            >
              {intl.formatMessage({ id: "Xavfsizlik" })}
            </Link>
          </div>
        </div>}
    </>
  );
};
