/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import React, { useState } from "react";
import { KTIcon } from "../../../../../../_metronic/helpers";
import {
  CreateAppModal,
  Dropdown1,
} from "../../../../../../_metronic/partials";
import { useLayout, usePageData } from "../../../core";
import { useIntl } from "react-intl";

const ToolbarClassic = () => {
  const { config } = useLayout();
  const { pageToolbar } = usePageData();
  const intl = useIntl();

  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop
    ? "btn-light"
    : "bg-body btn-color-gray-700 btn-active-color-primary";

  return (
    <div className="d-flex align-items-center gap-2 gap-lg-3">
      {/* START: PAGE TOOLBAR */}
      {pageToolbar?.map((tool, ind) => (
        <React.Fragment key={ind}>
          {tool.isActive ? (
            <button
              key={ind}
              {...tool.additional}
              className="btn btn-md fw-bold btn-primary"
            >
              {tool.icon && (
                <KTIcon iconName={tool.icon} className="fs-1 text-white me-1" />
              )}
              {intl.formatMessage({ id: tool.title })}
            </button>
          ) : (
            <button
              key={ind}
              className={"btn btn-secondary btn-md btn-flex fw-bold"}
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
            >
              {tool.icon && (
                <KTIcon iconName={tool.icon} className="fs-1 text-muted me-1" />
              )}
              {intl.formatMessage({ id: tool.title })}
            </button>
          )}
        </React.Fragment>
      ))}
      {/* END: PAGE TOOLBAR */}

      {config.app?.toolbar?.filterButton && (
        <div className="m-0">
          <a
            href="#"
            className={clsx(
              "btn btn-sm btn-flex fw-bold",
              daterangepickerButtonClass
            )}
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            <KTIcon iconName="filter" className="fs-6 text-muted me-1" />
            Filter
          </a>
          <Dropdown1 />
        </div>
      )}

      {config.app?.toolbar?.daterangepickerButton && (
        <div
          data-kt-daterangepicker="true"
          data-kt-daterangepicker-opens="left"
          className={clsx(
            "btn btn-sm fw-bold  d-flex align-items-center px-4",
            daterangepickerButtonClass
          )}
        >
          <div className="text-gray-600 fw-bold">Loading date range...</div>
          <KTIcon iconName="calendar-8" className="fs-1 ms-2 me-0" />
        </div>
      )}

      {config.app?.toolbar?.secondaryButton && (
        <a href="#" className="btn btn-sm btn-flex btn-light fw-bold">
          Filter
        </a>
      )}

      {config.app?.toolbar?.primaryButton && (
        <a
          href="#"
          onClick={() => setShowCreateAppModal(true)}
          className="btn btn-sm fw-bold btn-primary"
        >
          Create
        </a>
      )}
      <CreateAppModal
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
      />
    </div>
  );
};

export { ToolbarClassic };
