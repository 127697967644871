/* eslint-disable jsx-a11y/anchor-is-valid */

import {ChangeEvent, FC, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Link, useNavigate, useParams} from "react-router-dom";
import {PageLink, PageTitle, PageToolbar} from "../../components/layout/core";
import {URL_METHODIST_REPORTS, URL_TEACHER_MAIN_NEW_ABOUT, URL_TEACHER_REPORTS} from "../../mock/page_urls";
import {KTIcon} from "../../../_metronic/helpers";
import Components from "../../components";
import {useQuery} from "react-query";
import {getModeratorAppealId, getModeratorReportId, updateModeratorReport} from "../../api/_requests";
import {Card} from "../../components/common";
import {BASE_URL} from "../../api/URLs";
import {checkStatus, nameGenerator, phoneFormat} from "../../helpers";
import {NOT_ENTER} from "../../mock/text";
import {useNotification} from "../../context/NotificationContext";


const ReportViewPage: FC = () => {

  const intl = useIntl();
  const navigate = useNavigate();
  const router = useParams()
  const {id} = useParams<{ id: string }>();
  const {data: moderatorReportId, isSuccess} = useQuery(
      ["moderator_report_id", id],
      () => getModeratorReportId(id),
      {
        enabled: !!id,
      }
  );

  const [rejectComment, setRejectComment] = useState<string>(moderatorReportId?.status === "CANCELLED" ? moderatorReportId?.response : "");
  const [fileDropReject, setFileDropReject] = useState<File | null>(null);
  const [evaluationComment, setEvaluationComment] = useState<string>(moderatorReportId?.status === "CONFIRMED" ? moderatorReportId?.response : "");
  const [fileDropEvaluation, setFileDropEvaluation] = useState<File | null>(null);
  const [ball, setBall] = useState<number | string>(moderatorReportId?.ball || "");
  const {showNotification} = useNotification();


  useEffect(() => {
    if (moderatorReportId) {
      if (moderatorReportId.status === "CANCELLED") {
        setRejectComment(moderatorReportId.response);
      }
      if (moderatorReportId.status === "CONFIRMED") {
        setEvaluationComment(moderatorReportId.response);
      }
      setBall(moderatorReportId.ball || "");
    }
  }, [moderatorReportId]);

  const applicationViewBreadCrumbs: Array<PageLink> = [
    {
      title: `${intl.formatMessage({id: "Barcha shikoyatlar"})} / ${nameGenerator(moderatorReportId?.appeal.user.first_name || "", moderatorReportId?.appeal.user.last_name || "", moderatorReportId?.appeal.user.family_name, "fullname") || "-"}`,
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];
  const applicationViewToolbars: Array<PageToolbar> = [
    {
      title: intl.formatMessage({id: "Yuklangan ish"}),
      icon: "exit-right-corner",
      isActive: true,
      additional: {
        onClick: () => window.open(`${BASE_URL}${moderatorReportId?.appeal.file}`),
      },
    },
  ];

  const handleRejectSubmit = async (e) => {
    e.preventDefault()
    const res = await updateModeratorReport(id, "CANCELLED", rejectComment, fileDropReject);
    if(res.status === 200){
      showNotification(intl.formatMessage({id: "Shikoyat rad etildi"}),"", "success")
      navigate(URL_METHODIST_REPORTS);
    }
  }


  const handleEvaluationSubmit = async (e) => {
    e.preventDefault()
    const res = await updateModeratorReport(id, "CONFIRMED", evaluationComment, fileDropEvaluation, ball);
    if(res.status === 200){
      showNotification(intl.formatMessage({id: "Shikoyatga javob berildi"}),"", "success")
      navigate(URL_METHODIST_REPORTS);
    }
  }


  return (
      <>
        <PageTitle
            breadcrumbs={applicationViewBreadCrumbs}
            toolbar={applicationViewToolbars}
        />
        <div className="px-10">
          <div className="bg-light rounded-4 border border-gray-200 p-10">
            <p className={"text-gray-700"}>{intl.formatMessage({id: "Shikoyat haqida"})}</p>
            <p className={"fs-3 lh-large text-gray-500"}><b
                className={"text-black"}>{intl.formatMessage({id: "Nomi"})}: </b> {moderatorReportId?.title}
            </p>
            <p className={"fs-3 text-gray-500 lh-lg"}><b
                className={"text-black"}>{intl.formatMessage({id: "Tavsif"})}: </b>{moderatorReportId?.message}
            </p>


            <div className="row row-gap-3">
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={moderatorReportId?.appeal?._science?.name}
                    icon={"teacher"}
                    description={intl.formatMessage({id: "Fan"})}
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={moderatorReportId?.appeal?._method?.name}
                    icon={"note-2"}
                    description={intl.formatMessage({id: "Metodik ishlanma turi"})}
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={moderatorReportId?.appeal?._method?.name}
                    icon={"tablet-ok"}
                    description={
                      `${intl.formatMessage({id: "Yuklangan ish"})} <a href=${BASE_URL}${moderatorReportId?.appeal.file} target="_blank" download>${intl.formatMessage({id: "(Yuklab olish)"})}</a>`
                    }
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={moderatorReportId?.appeal.ball}
                    icon={"like"}
                    description={intl.formatMessage({id: "Ball"})}
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
            </div>

            <h3 className={"text-gray-500 my-5"}>{intl.formatMessage({id: "Shikoyat yuboruvchi"})}</h3>
            <div className="row row-gap-7">
              <div className="col-md-4">
                <Card
                    title={nameGenerator(moderatorReportId?.appeal.user.first_name || "", moderatorReportId?.appeal.user.last_name || "", moderatorReportId?.appeal.user.family_name, "fullname") || "-"}
                    icon={"user"}
                    description={intl.formatMessage({id: "F.I.O"})}
                    iconSize="4x"
                    padding="p-10"
                />
              </div>

              <div className="col-md-4">
                <Card
                    title={moderatorReportId?.appeal.user.address.region.name || NOT_ENTER}
                    icon={"map"}
                    description={intl.formatMessage({id: "Viloyat"})}
                    iconSize="4x"
                    padding="p-10"
                />
              </div>

              <div className="col-md-4">
                <Card
                    title={moderatorReportId?.appeal.user.address.district.name || NOT_ENTER}
                    icon={"map"}
                    description={intl.formatMessage({id: "Tuman / Shahar"})}
                    iconSize="4x"
                    padding="p-10"
                />
              </div>

              <div className="col-md-4">
                <Card
                    title={moderatorReportId?.appeal.user.address.organization.name || NOT_ENTER}
                    icon={"book-open"}
                    description={intl.formatMessage({id: "Maktab"})}
                    iconSize="4x"
                    padding="p-10"
                />
              </div>

              <div className="col-md-4">
                <Card
                    title={phoneFormat(moderatorReportId?.appeal.user.phone || "") || NOT_ENTER}
                    icon={"phone"}
                    description={intl.formatMessage({id: "Telefon raqam"})}
                    iconSize="4x"
                    padding="p-10"
                />
              </div>

              <div className="col-md-4">
                <Card
                    title={phoneFormat(moderatorReportId?.appeal.user.contact_phone || "") || NOT_ENTER}
                    icon={"phone"}
                    description={intl.formatMessage({id: "Qo’shimcha telefon raqam"})}
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
            </div>

            <h3 className={"text-gray-500 my-5"}>{intl.formatMessage({id: "Qo’shimcha ma’lumot"})}</h3>
            <div className="row row-gap-7">
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={checkStatus(moderatorReportId?.status || "", "text")}
                    icon={"award"}
                    description={intl.formatMessage({id: "Status"})}
                    iconSize="4x"
                    padding="p-10"
                    status={checkStatus(moderatorReportId?.status || "")}
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={intl.formatMessage({id: "Shikoyat rasm ko’rinishida"})}
                    icon={"paper-clip"}
                    description={
                      `${intl.formatMessage({id: "Shikoyatning oq qog’ozdagi rasmi"})} <a href=${BASE_URL}${moderatorReportId?.file} target="_blank" download>${intl.formatMessage({id: "(Yuklab olish)"})}</a>`
                    }
                    iconSize="4x"
                    padding="p-10"
                />
              </div>

              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={moderatorReportId?.title}
                    icon={"pin"}
                    description={intl.formatMessage({id: "Shikoyat mavzusi"})}
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
            </div>
          </div>

          <div className="bg-light rounded-4 border border-gray-200 mt-5">

            <ul className="nav nav-tabs nav-line-tabs fs-3  px-10 pt-7 nav-line-tabs-2x">
              <li className="nav-item">
                <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_1"
                >
                  {intl.formatMessage({id: "Rad etish"})}
                </a>
              </li>
              <li className="nav-item">
                <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_2"
                >
                  {intl.formatMessage({id: "Baholash"})}
                </a>
              </li>

            </ul>
            <div className="tab-content" id="myTabContent">

              <div
                  className="tab-pane fade active show p-10 pt-5"
                  id="kt_tab_pane_1"
                  role="tabpanel"
              >
                <form onSubmit={handleRejectSubmit}>
                  <label className="form-label">{intl.formatMessage({id: "Javob fayli"})} <span className={"text-danger"}>*</span></label>
                  <Components.Dropzone title={intl.formatMessage({id: "Javob faylini yuklang"})} fileDrop={fileDropReject}
                                       setFileDrop={setFileDropReject}/>
                  <span className={"text-gray-500"}>{intl.formatMessage({id: "Bo'sh bo'lishi mumkin emas"})}</span>
                  <div className={"pt-5"}>
                    <label className="form-label">{intl.formatMessage({id: "Izoh"})}</label>
                    <textarea
                        value={rejectComment}
                        className="form-control"
                        placeholder={intl.formatMessage({id: "Matnini kiriting"})}
                        rows={5}
                        onChange={(e) => setRejectComment(e.target.value)}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-10">
                    <div className="d-flex gap-5">
                      <Link to={URL_METHODIST_REPORTS} className={"btn btn-secondary"}>{intl.formatMessage({id: "Bekor qilish"})}</Link>
                      <button
                          type={"submit"}
                          className={"btn btn-primary"}
                          disabled={!fileDropReject}
                      >
                        {intl.formatMessage({id: "Saqlash"})}
                      </button>
                    </div>
                  </div>

                </form>
              </div>

              <div className="tab-pane fade p-10 pt-5" id="kt_tab_pane_2" role="tabpanel">
                <form onSubmit={handleEvaluationSubmit}>
                  <div className="mb-5 col-md-2">
                    <label className="form-label">{intl.formatMessage({id: "Ball"})} <span className={"text-danger"}>*</span></label>
                    <input
                        type="number"
                        value={ball}
                        max={80}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setBall(Number(e.target.value))}
                        className="form-control"
                        placeholder={intl.formatMessage({id: "Yangi ballni kiriting"})}
                    />
                  </div>

                  <label className="form-label">{intl.formatMessage({id: "Javob fayli"})} <span className={"text-danger"}>*</span></label>
                  <Components.Dropzone title={intl.formatMessage({id: "Javob faylini yuklang"})} fileDrop={fileDropEvaluation}
                                       setFileDrop={setFileDropEvaluation}/>
                  <div className={"pt-5"}>
                    <label className="form-label">{intl.formatMessage({id: "Izoh"})}</label>
                    <textarea
                        value={evaluationComment}
                        className="form-control"
                        placeholder={intl.formatMessage({id: "Matnini kiriting"})}
                        rows={5}
                        onChange={(e) => setEvaluationComment(e.target.value)}
                    />
                  </div>

                  <div className="d-flex justify-content-end mt-10">
                    <div className="d-flex gap-5">
                      <Link to={URL_METHODIST_REPORTS} className={"btn btn-secondary"}>{intl.formatMessage({id: "Bekor qilish"})}</Link>
                      <button
                          type={"submit"}
                          className={"btn btn-primary"}
                          disabled={!fileDropEvaluation || !ball}
                      >
                        {intl.formatMessage({id: "Saqlash"})}
                      </button>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};


export {ReportViewPage}