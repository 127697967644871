import React, { useState } from 'react';
import { PageLink, PageTitle } from "../../components/layout/core";
import {Loader, SearchableDropdown} from "../../components/common";
import {
  getAcademicYears,
  getAllUsers,
  getDistrict,
  getModeratorScience,
  getRegions,
  postModerator
} from "../../api/_requests";
import { useQuery } from "react-query";
import { useAuth } from "../../components/layout/auth";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {useNotification} from "../../context/NotificationContext";
import {URL_ADMIN_METHODISTS} from "../../mock/page_urls";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import {queryCacheOptions} from "../../mock/cacheOptions";
import {DistrictProps} from "../../types/district";

export const MethodistNewPage = () => {
  const intl = useIntl();
  const { data: allUsers, isLoading, isError } = useQuery('getAllUsers', () => getAllUsers(), {...queryCacheOptions});
  const { currentUser } = useAuth();
  const { data: regionData } = useQuery("regions", getRegions, {...queryCacheOptions});
  const { data: allAcademicYearData } = useQuery("academic_years", getAcademicYears, {...queryCacheOptions});
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedUserRegionId, setSelectedUserRegionId] = useState<number | null>(null);
  const [districtData, setDistrictData] = useState<DistrictProps[]>([]);
  const {showNotification} = useNotification();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    user: Yup.string().required(intl.formatMessage({id: "Foydalanuvchi tanlash majburiy"})),
    region: Yup.string().required(intl.formatMessage({id: "Viloyat tanlash majburiy"})),
    district: Yup.number().required(intl.formatMessage({id: "Tuman/Shahar tanlash majburiy"})),
    sciences: Yup.number().required(intl.formatMessage({id: "Fan tanlash majburiy"})),
    academic_year: Yup.number().required(intl.formatMessage({id: "Akademik yil tanlash majburiy"})),
  });


  const methodistsBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: "Metodist yaratish"}),
      path: "/",
      isSeparator: false,
      isActive: false,
    },
  ];

  const { refetch: fetchDistricts } = useQuery(
      ['districts', selectedUserRegionId],
      () => selectedUserRegionId ? getDistrict(selectedUserRegionId) : Promise.resolve([]),
      {
        enabled: !!selectedUserRegionId,
        onSuccess: (data) => setDistrictData(data),
      }
  );

  const { data: allScienceData, isLoading: allScienceLoading } = useQuery("moderator_filter_science", getModeratorScience)

  const handleSelect = (option: any) => {
    setSelectedUser(option);
    if (option.address?.region?.id) {
      setSelectedUserRegionId(option.address.region.id);
      fetchDistricts();
    }
  };

  return (
      <>
        <PageTitle
            description={intl.formatMessage({id: "Metodist ma'lumotlarini kiriting"})}
            breadcrumbs={methodistsBreadCrumbs}
        />
        {isLoading ? <Loader height={"70vh"}/> : <div className="px-10">
          <div className={"card rounded-4 border border-gray-200 p-5 p-md-10 p-lg-10 pb-0 pt-1"}>
            <div>
              <h1 className="fw-bold">{intl.formatMessage({id: "Ma’lumotlarni kiriting"})}</h1>
              <p className="text-gray-500 fw-bold">{intl.formatMessage({id: "Ariza ushun boshlang’ich ma’lumotlarni kiriting"})}</p>
            </div>

            <Formik
                initialValues={{
                  user: '',
                  region: selectedUser?.address?.region?.id,
                  district: selectedUser?.address?.district?.id,
                  science: selectedUser?.science,
                  academic_year: ''
                }}
                // validationSchema={validationSchema}
                onSubmit={async (values) => {
                  const data = {
                    user: selectedUser?.id,
                    districts: [Number(values.district) || selectedUser?.address?.district?.id],
                    sciences: [Number(values.science) || selectedUser?.science],
                    academic_year: Number(values.academic_year)
                  };

                  try {
                    const res = await postModerator(data)
                    if (res.status === 201) {
                      showNotification(res?.data?.message, "", "success");
                      navigate(URL_ADMIN_METHODISTS)
                    } else {
                      showNotification(res?.data?.message, "", "error");
                    }
                  } catch (error: any) {
                    showNotification("Tanlanmagan", error.data.data.academic_year[0] || error.data.data.sciences[0] || error.data.data.academic_year[0], "error");
                  }
                }}
            >

              {({setFieldValue, errors, touched, isSubmitting, values}) => (
                  <Form className={"pt-10"}>
                    <div className="">
                      <label className="form-label">{intl.formatMessage({id: "Foydalanuvchini tanlash"})} <span
                          className="text-danger fs-3">*</span></label>
                      <SearchableDropdown
                          initialOptions={allUsers?.data || []}
                          placeholder={intl.formatMessage({id: "Foydalanuvchini tanlash"})}
                          onSelect={(option) => {
                            handleSelect(option);
                            setFieldValue('user', option.id);
                          }}
                      />
                      <span
                          className={"text-gray-500"}>{currentUser?.data?.address?.region?.name} {intl.formatMessage({id: "viloyati bo’yicha"})}</span>

                      {errors.user && touched.user ? (
                          <div className="text-danger">{errors.user}</div>
                      ) : null}
                    </div>

                    <div className={"row mt-5"}>
                      <div className="col-md-6">
                        <label className="form-label">{intl.formatMessage({id: "Viloyat"})} <span
                            className="text-danger fs-3">*</span></label>
                        <Field as="select" name="region" className="form-select" disabled>
                          <option disabled selected>{intl.formatMessage({id: "Tanlash"})}</option>
                          {
                            regionData?.map((region: any) => (
                                <option
                                    key={region.id}
                                    value={region.id}
                                    selected={region.id === selectedUser?.address?.region?.id}
                                >
                                  {region.name}
                                </option>
                            ))
                          }
                        </Field>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">{intl.formatMessage({id: "Tuman / Shahar"})} <span
                            className="text-danger fs-3">*</span></label>
                        <Field as="select" name="district" className="form-select"
                               onChange={(e) => setFieldValue('district', e.target.value)}>
                          <option value="" disabled>{intl.formatMessage({id: "Tanlash"})}</option>
                          {
                            districtData.length > 0 ? (
                                districtData.map((district: DistrictProps) => (
                                    <option selected={selectedUser?.address?.district?.id === district?.id}
                                            key={district.id} value={district.id}>{district.name}</option>
                                ))
                            ) : (
                                <option disabled>{intl.formatMessage({id: "Tuman / Shaharlar topilmadi"})}</option>
                            )
                          }
                        </Field>
                      </div>
                    </div>

                    <div className="mt-5">
                      <label className="form-label">{intl.formatMessage({id: "Fanni tanlash"})} <span
                          className="text-danger fs-3">*</span></label>
                      <Field as="select" name="science" className="form-select">
                        <option disabled selected value="">{intl.formatMessage({id: "Tanlash"})}</option>
                        {
                          allScienceData?.data.map((science: any) => {
                            return <option selected={selectedUser?.science === science?.id} key={science.id}
                                           value={science.id}>{science.name}</option>
                          })
                        }
                      </Field>
                      {errors.science && touched.science ? (
                          <div className="text-danger">{String(errors.science)}</div>
                      ) : null}
                    </div>

                    <div className="mt-5">
                      <label className="form-label">{intl.formatMessage({id: "Akademik yilni tanlash"})} <span
                          className="text-danger fs-3">*</span></label>
                      <Field as="select" name="academic_year" className="form-select">
                        <option disabled value="" selected>{intl.formatMessage({id: "Tanlash"})}</option>
                        {
                          allAcademicYearData?.data.map((year: any) => (
                              <option key={year.id} value={year.id}>{year.name}</option>
                          ))
                        }
                      </Field>
                      {errors.academic_year && touched.academic_year ? (
                          <div className="text-danger">{errors.academic_year}</div>
                      ) : null}
                    </div>


                    <div className={"d-flex justify-content-end gap-3 mt-10"}>
                      <button type="button"
                              className="btn btn-secondary">{intl.formatMessage({id: "Bekor qilish"})}</button>
                      <button type="submit" className="btn btn-primary"
                              disabled={isSubmitting}>{isSubmitting ? intl.formatMessage({id: "Saqlanmoqda..."}) : intl.formatMessage({id: "Saqlash"})}
                      </button>
                    </div>
                  </Form>
              )}
            </Formik>
          </div>
        </div>}
      </>
  );
};
