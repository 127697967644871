import React, {FC, useRef, useState, useEffect} from 'react';
import {Dropdown} from "../table/dropdown";
import {KTIcon} from "../../../../_metronic/helpers";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useQuery} from 'react-query';
import {
  getAcademicYears,
  getAppealStatus,
  getDistrict,
  getRegions,
  getReportStatus,
  getSciences
} from "../../../api/_requests";
import {checkStatus, onlyNumber} from "../../../helpers";
import {mock} from "../../../mock";
import {IFilters} from "../../../types";
import {useIntl} from "react-intl";
import Tag from "./Tag";


interface IFilterProps {
  onApply: (filters: IFilters) => void;
  onClear: () => void;
  filterType: 'appeals' | 'reports' | "landing";
}

export const Filter: FC<IFilterProps> = ({onApply, onClear, filterType}) => {
  const intl = useIntl();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectedRegion, setSelectedRegion] = useState<string | number | null>(null);
  const [selectedDistrict, setSelectedDistrict] = useState<string | number | null>(null);
  const [selectedScience, setSelectedScience] = useState<string | number | null>(null);
  const filterRef = useRef<HTMLDivElement | null>(null);
  const {data: academicYearsData} = useQuery("getAcademicYears", getAcademicYears, {...mock.queryCacheOptions});
  const {data: regionData} = useQuery("regions", getRegions, {...mock.queryCacheOptions});
  const {data: statusAppealData} = useQuery("appeal_status", getAppealStatus, {...mock.queryCacheOptions});
  const {data: statusReportData} = useQuery("report_status", getReportStatus, {...mock.queryCacheOptions});
  const {data: sciencesData} = useQuery("sciences", getSciences, {...mock.queryCacheOptions});

  const FilterSchema = Yup.object().shape({
    ball_min: Yup.number().min(0, intl.formatMessage({id: '0 dan past bo`lishi mumkin emas'})).nullable(),
    ball_max: Yup.number().min(Yup.ref('ball_min'), intl.formatMessage({id: "Minimal baldan kichik bo`lishi mumkin emas"})).nullable(),
    jshir: Yup.string().matches(/^\d{14}$/, intl.formatMessage({id: "JSHSHIR 14ta raqam bo`lishi kerak"})).nullable(),
    search: Yup.string().nullable(),
  });

  const {data: districtData} = useQuery(
      ['districts', selectedRegion],
      () => selectedRegion ? getDistrict(findByName("region")?.id || 2) : Promise.resolve({data: []}),
      {
        enabled: !!selectedRegion,
        ...mock.queryCacheOptions
      }
  );

  const findByName = (type: "region" | "district" | "science") => {
    if (type === "region") {
      return regionData?.find(region => selectedRegion === region.name)
    } else if (type === "district") {
      return districtData?.find(district => selectedDistrict === district.name)
    } else if (type === "science") {
      return sciencesData?.find(science => selectedScience === science.name)
    }
  }


  const regionDataFormatted = (regionData || []).map((region: any) => ({
    id: region.id,
    label: region.name,
    type: 'region',
  }));

  const districtDataFormatted = (districtData || []).map((district: any) => ({
    id: district.id,
    label: district.name,
    type: 'district',
  }));

  const statusAppealDataFormatted = (statusAppealData || []).map((status: any) => ({
    id: status.key,
    label: status.key,
    type: 'status',
  }));

  const sciencesDataFormatted = (sciencesData || []).map((science: any) => ({
    id: science.id,
    label: science.name,
    type: 'science',
  }));

  const statusReportDataFormatted = (statusReportData || []).map((status: any) => ({
    id: status.key,
    label: status.key,
    type: 'status',
  }));

  const formik = useFormik({
    initialValues: {
      ball_min: null,
      ball_max: null,
      jshir: '',
      status: intl.formatMessage({id: "Tanlash"}),
      year: intl.formatMessage({id: "Tanlash"}),
      region: intl.formatMessage({id: "Tanlash"}),
      district: intl.formatMessage({id: "Tanlash"}),
      science: intl.formatMessage({id: "Tanlash"}),
      search: ''
    },
    validationSchema: FilterSchema,
    onSubmit: (values) => {
      const filters = {
        ball_min: values.ball_min,
        ball_max: values.ball_max,
        jshir: values.jshir || null,
        status: values.status === intl.formatMessage({id: "Tanlash"}) ? null : checkStatus(values.status, "reverseText"),
        year: values.year === intl.formatMessage({id: "Tanlash"}) ? null : values.year,
        region: values.region === intl.formatMessage({id: "Tanlash"}) ? null : findByName("region")?.id,
        district: values.district === intl.formatMessage({id: "Tanlash"}) ? null : findByName("district")?.id,
        science: values.science === intl.formatMessage({id: "Tanlash"}) ? null : findByName("science")?.id,
        search: values.search || null,
      };

      onApply(filters);
      setShowFilter(false);
    }
  });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;

    const filters = {
      ball_min: formik.values.ball_min,
      ball_max: formik.values.ball_max,
      jshir: formik.values.jshir || null,
      status: formik.values.status === intl.formatMessage({ id: "Tanlash" }) ? null : checkStatus(formik.values.status, "reverseText"),
      year: formik.values.year === intl.formatMessage({ id: "Tanlash" }) ? null : formik.values.year,
      region: formik.values.region === intl.formatMessage({ id: "Tanlash" }) ? null : findByName("region")?.id,
      district: formik.values.district === intl.formatMessage({ id: "Tanlash" }) ? null : findByName("district")?.id,
      science: formik.values.science === intl.formatMessage({ id: "Tanlash" }) ? null : findByName("science")?.id,
      search: searchValue.trim() || null,
    };

    formik.setFieldValue('search', searchValue);
    onApply(filters);
  };

  const handleFilterClear = () => {
    formik.resetForm();
    onClear();
    setShowFilter(false);
  };

  const handleRemoveTag = (field: string, fieldType: 'dropdown' | 'input') => {
    const defaultLabel = fieldType === 'dropdown' ? intl.formatMessage({id: "Tanlash"}) : '';
    formik.setFieldValue(field, defaultLabel);
    onApply({
      ...formik.values,
      [field]: defaultLabel,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (filterRef.current && !filterRef.current.contains(event.target as Node)) {
        setShowFilter(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
      <div className="position-relative d-flex justify-content-between w-100" ref={filterRef}>
        <div
            className="d-flex align-items-center justify-content-between cursor-pointer rounded border border-gray-300"
            onClick={() => setShowFilter(!showFilter)}
        >
          <KTIcon iconName="setting-4" className="p-3"/>
          <div
              onClick={() => setShowFilter(!showFilter)}
              className="cursor-pointer bg-transparent form-control"
              aria-label="Filter"
              aria-describedby="basic-addon1"
          >
            <div className="d-flex gap-2 flex-wrap" style={{maxWidth: "100%"}}>
            {intl.formatMessage({id: "Filter"})}
              {formik.values.ball_min && (
                  <Tag label={`Min Ball: ${formik.values.ball_min}`} onRemove={() => handleRemoveTag("ball_min", "input")}/>
              )}
              {formik.values.ball_max && (
                  <Tag label={`Max Ball: ${formik.values.ball_max}`} onRemove={() => handleRemoveTag("ball_max", "input")}/>
              )}
              {formik.values.jshir && (
                  <Tag label={`${intl.formatMessage({id: "JSHSHIR"})}: ${formik.values.jshir}`} onRemove={() => handleRemoveTag("jshir", "input")}/>
              )}
              {formik.values.status && formik.values.status !== intl.formatMessage({id: "Tanlash"}) && (
                  <Tag label={`${intl.formatMessage({id: "Status"})}: ${formik.values.status}`} onRemove={() => handleRemoveTag("status", "dropdown")}/>
              )}
              {formik.values.year && formik.values.year !== intl.formatMessage({id: "Tanlash"}) && (
                  <Tag label={`${intl.formatMessage({id: "Yil"})}: ${formik.values.year}`} onRemove={() => handleRemoveTag("year", "dropdown")}/>
              )}
              {formik.values.region && formik.values.region !== intl.formatMessage({id: "Tanlash"}) && (
                  <Tag label={`${intl.formatMessage({id: "Viloyat"})}: ${formik.values.region}`} onRemove={() => handleRemoveTag("region", "dropdown")}/>
              )}
              {formik.values.district && formik.values.district !== intl.formatMessage({id: "Tanlash"}) && (
                  <Tag label={`${intl.formatMessage({id: "Tuman / Shahar"})}: ${formik.values.district}`} onRemove={() => handleRemoveTag("district", "dropdown")}/>
              )}
              {formik.values.science && formik.values.science !== intl.formatMessage({id: "Tanlash"}) && (
                  <Tag label={`${intl.formatMessage({id: "Fan"})}: ${formik.values.science}`} onRemove={() => handleRemoveTag("science", "dropdown")}/>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center position-relative pe-7">
          <KTIcon iconName="magnifier" className="fs-1 position-absolute ms-4"/>
          <input
              type="text"
              data-kt-docs-table-filter="search"
              className="form-control w-250px ps-15"
              placeholder={intl.formatMessage({id: "Qidiruv..."})}
              name="search"
              value={formik.values.search}
              onChange={handleSearchChange}
              onBlur={formik.handleBlur}
          />
        </div>

        {showFilter && (
            <div style={{zIndex: 4}}
                 className="fs-4 card position-absolute bg-light border top-100 rounded-3 mt-2 w-100 w-md-75 w-lg-50 shadow-sm">
              <div className="p-5 pb-0 text-gray-600">{intl.formatMessage({id: "Ma’lumotlarni filter qilish"})}</div>
              <hr/>
              <div className="card-content text-gray-600">
                <form onSubmit={formik.handleSubmit}>
                  {filterType === "appeals" && (
                      <div className="p-5">
                        <div className="row align-items-center mt-3">
                          <div className="col-md-2">{intl.formatMessage({id: "Ball"})}</div>
                          <div className="col-md-10">
                            <div className="d-flex gap-3 align-items-start">
                              <div className="w-100">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder={intl.formatMessage({id: "Minimal ball"})}
                                    min={0}
                                    name="ball_min"
                                    value={formik.values.ball_min || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.errors.ball_min && formik.touched.ball_min ? (
                                    <span className="text-danger fs-6">{formik.errors.ball_min}</span>
                                ) : null}
                              </div>
                              <div className="w-100">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder={intl.formatMessage({id: "Maksimal ball"})}
                                    name="ball_max"
                                    max={100}
                                    value={formik.values.ball_max || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.errors.ball_max && formik.touched.ball_max ? (
                                    <span className="text-danger fs-6">{formik.errors.ball_max}</span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row align-items-center mt-3">
                          <div className="col-md-2">{intl.formatMessage({id: "JSHSHIR"})}</div>
                          <div className="col-md-10">
                            <input
                                type="text"
                                placeholder={intl.formatMessage({id: "JSHSHIR raqam"})}
                                className="form-control"
                                name="jshir"
                                onInput={(e:any) => onlyNumber(e.target.value)}
                                maxLength={14}
                                value={formik.values.jshir}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.errors.jshir && formik.touched.jshir ? (
                                <span className="text-danger fs-6">{formik.errors.jshir}</span>
                            ) : null}
                          </div>
                        </div>

                        <div className="row align-items-center mt-3">
                          <div className="col-md-2">{intl.formatMessage({id: "Holat"})}</div>
                          <div className="col-md-10">
                            <Dropdown
                                options={statusAppealDataFormatted}
                                defaultLabel={intl.formatMessage({id: "Tanlash"})}
                                selectedLabel={formik.values.status}
                                onSelect={(value) => formik.setFieldValue('status', value === intl.formatMessage({id: "Tanlash"}) ? null : value)}
                            />
                          </div>
                        </div>

                        <div className="row align-items-center mt-3">
                          <div className="col-md-2">{intl.formatMessage({id: "Viloyat"})}</div>
                          <div className="col-md-10">
                            <Dropdown
                                options={regionDataFormatted}
                                defaultLabel={intl.formatMessage({id: "Tanlash"})}
                                selectedLabel={formik.values.region}
                                onSelect={(value) => {
                                  formik.setFieldValue('region', value);
                                  setSelectedRegion(value);
                                }}
                            />
                          </div>
                        </div>

                        <div className="row align-items-center mt-3">
                          <div className="col-md-2">{intl.formatMessage({id: "Tuman/Shahar"})}</div>
                          <div className="col-md-10">
                            <Dropdown
                                options={districtDataFormatted}
                                defaultLabel={intl.formatMessage({id: "Tanlash"})}
                                selectedLabel={formik.values.district}
                                onSelect={(value) => {
                                  formik.setFieldValue('district', value);
                                  setSelectedDistrict(value);
                                }}
                            />
                          </div>
                        </div>
                      </div>
                  )}

                  {filterType === "reports" && (
                      <div className={"p-5"}>
                        <div className="row align-items-center mt-3">
                          <div className="col-md-2">{intl.formatMessage({id: "Holat"})}</div>
                          <div className="col-md-10">
                            <Dropdown
                                options={statusReportDataFormatted}
                                defaultLabel={intl.formatMessage({id: "Tanlash"})}
                                selectedLabel={formik.values.status}
                                onSelect={(value) => formik.setFieldValue('status', value === intl.formatMessage({id: "Tanlash"}) ? null : value)}
                            />
                          </div>
                        </div>

                        <div className="row align-items-center mt-3">
                          <div className="col-md-2">{intl.formatMessage({id: "Viloyat"})}</div>
                          <div className="col-md-10">
                            <Dropdown
                                options={regionDataFormatted}
                                defaultLabel={intl.formatMessage({id: "Tanlash"})}
                                selectedLabel={formik.values.region}
                                onSelect={(value) => {
                                  formik.setFieldValue('region', value);
                                  setSelectedRegion(value);
                                }}
                            />
                          </div>
                        </div>

                        <div className="row align-items-center mt-3">
                          <div className="col-md-2">{intl.formatMessage({id: "Tuman/Shahar"})}</div>
                          <div className="col-md-10">
                            <Dropdown
                                options={districtDataFormatted}
                                defaultLabel={intl.formatMessage({id: "Tanlash"})}
                                selectedLabel={formik.values.district}
                                onSelect={(value) => {
                                  formik.setFieldValue('district', value)
                                  setSelectedDistrict(value)
                                }}
                            />
                          </div>
                        </div>
                      </div>
                  )}

                  {filterType === "landing" && (
                      <div className={"p-5"}>
                        <div className="row align-items-center mt-3">
                          <div className="col-md-2">{intl.formatMessage({id: "Viloyat"})}</div>
                          <div className="col-md-10">
                            <Dropdown
                                options={regionDataFormatted}
                                defaultLabel={intl.formatMessage({id: "Tanlash"})}
                                selectedLabel={formik.values.region}
                                onSelect={(value) => {
                                  formik.setFieldValue('region', value);
                                  setSelectedRegion(value);
                                }}
                            />
                          </div>
                        </div>

                        <div className="row align-items-center mt-3">
                          <div className="col-md-2">{intl.formatMessage({id: "Tuman/Shahar"})}</div>
                          <div className="col-md-10">
                            <Dropdown
                                options={districtDataFormatted}
                                defaultLabel={intl.formatMessage({id: "Tanlash"})}
                                selectedLabel={formik.values.district}
                                onSelect={(value) => {
                                  formik.setFieldValue('district', value)
                                  setSelectedDistrict(value)
                                }}
                            />
                          </div>
                        </div>

                        <div className="row align-items-center mt-3">
                          <div className="col-md-2">{intl.formatMessage({id: "Fan"})}</div>
                          <div className="col-md-10">
                            <Dropdown
                                options={sciencesDataFormatted}
                                defaultLabel={intl.formatMessage({id: "Tanlash"})}
                                selectedLabel={formik.values.science}
                                onSelect={(value) => {
                                  formik.setFieldValue('science', value)
                                  setSelectedScience(value)
                                }}
                            />
                          </div>
                        </div>
                      </div>
                  )}

                  <hr/>
                  <div className="d-flex justify-content-between p-5 pt-0">
                    <button type="button" className="btn btn-light me-3" onClick={handleFilterClear}>
                      {intl.formatMessage({id: "Tozalash"})}
                    </button>
                    <button type="submit" className="btn btn-primary">
                      {intl.formatMessage({id: "Qo`llash"})}
                    </button>
                  </div>
                </form>
              </div>
            </div>
        )}
      </div>
  );
};
