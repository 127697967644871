import React from 'react'
import {useAuth} from "../../../layout/auth";
import {Link} from "react-router-dom";
import {useQuery} from "react-query";
import {getSessions} from "../../../../api/_requests";
import Components from "../../../index";
import {CHANGE_PASSWORD} from "../../../../mock/page_urls";
import {useIntl} from "react-intl";
import {queryCacheOptions} from "../../../../mock/cacheOptions";


const bodyKey = [
  "location",
  "fake_status",
  "device",
  "ip_address",
  "last_activity",
];

export const SecuritySection = () => {
  const {currentUser} = useAuth();
  const intl = useIntl();
  const {data: sessions, isSuccess} = useQuery("getSessions", getSessions, {...queryCacheOptions})


  const header = [
    {label: intl.formatMessage({id: "Manzil"})},
    {label: intl.formatMessage({id: "Holati"})},
    {label: intl.formatMessage({id: "Qurilma"})},
    {label: intl.formatMessage({id: "IP manzil"})},
    {label: intl.formatMessage({id: "So'nggi aktiv holati"})},
  ];

  return (
      <>
        {
            currentUser && <div>
                <div
                    className={"d-flex align-items-center py-5 px-10 justify-content-between bg-light rounded-4 border border-gray-200 my-10"}>
                    <div className={"d-grid align-items-stretch"}>
                        <h3>{intl.formatMessage({id: "Parol"})}</h3>
                        <span className={"fs-4 text-gray-500 p-0"}>*********</span>
                    </div>
                    <div className={""}>
                        <Link to={CHANGE_PASSWORD}
                              className={"btn btn-primary"}>{intl.formatMessage({id: "Parolni o'zgartirish"})}</Link>
                    </div>
                </div>
              {
                  isSuccess && <Components.Table
                      header={header}
                      title={intl.formatMessage({id: "Aktiv qurilmalar"})}
                      body={sessions?.data?.results?.map((d) => ({
                        ...d,
                      }))}
                      bodyKey={bodyKey}
                  />
              }
            </div>
        }
      </>
  )
}
