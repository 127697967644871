import React, {FC, useEffect, useState} from "react";
import {useQuery} from "react-query";
import {Link, useSearchParams} from "react-router-dom";
import {KTIcon} from "../../../../../_metronic/helpers";
import {getModeratorReports, getTaskID, postExportSelected} from "../../../../api/_requests";
import {checkStatus, downloadFile} from "../../../../helpers";
import {Empty, Filter, Loader} from "../../../common";
import Components from "../../../index";
import {mock} from "../../../../mock";
import {useNotification} from "../../../../context/NotificationContext";
import {IFilters} from "../../../../types";
import {useIntl} from "react-intl";


const ReportsSection: FC = () => {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';

  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [filters, setFilters] = useState<IFilters>({
    ball_min: null,
    ball_max: null,
    jshir: null,
    status: null,
    year: null,
    region: null,
    district: null,
    search: null,
  });
  const {showNotification} = useNotification();
  const {data: reportsData, refetch: refetchReports, isLoading} = useQuery(
      ['reports', filters],
      () => getModeratorReports(filters, page),
      {
        enabled: !!filters,
        refetchOnWindowFocus: false,
      }
  );

  const handleCheckboxChange = (selectedRows: Set<number>) => {
    setSelectedRows(selectedRows);
  };

  const handleApplyFilters = (filterState: IFilters) => {
    const adjustedFilters = {
      ...filterState,
      status: filterState.status === intl.formatMessage({id: 'Tanlash'}) ? null : filterState.status,
      region: filterState.region === intl.formatMessage({id: 'Tanlash'}) ? null : filterState.region,
      district: filterState.district === intl.formatMessage({id: 'Tanlash'}) ? null : filterState.district,
    };

    setFilters(adjustedFilters);
    refetchReports();
  };

  const handleClearFilters = () => {
    setFilters({
      ball_min: null,
      ball_max: null,
      jshir: null,
      status: null,
      year: null,
      region: null,
      district: null,
      search: null,
    });
    refetchReports();
  };

  const exportAppeals = async () => {
    const selectedIds = Array.from(selectedRows);
    try {
      const response = await getTaskID(selectedIds);
      const downloadCertificate = await postExportSelected(response?.data?.data?.task_id)
      if (downloadCertificate?.data?.results && downloadCertificate?.data?.status === "SUCCESS") {
        downloadFile(downloadCertificate?.data?.results)
      } else {
        showNotification(checkStatus(downloadCertificate?.data?.status, "text"), "", "warning")
      }
    } catch (error) {
      showNotification(intl.formatMessage({id: "Export qilishda xatolik"}), "", "warning")
    }
  };

  useEffect(() => {
    refetchReports();
  }, [page, refetchReports]);


  return (
      <div>
        <div
            className={"bg-light w-100 d-flex justify-content-between align-items-center p-5 border border-top-0 border-gray-200"}>
          <Filter onApply={handleApplyFilters} onClear={handleClearFilters} filterType="reports"/>
          {selectedRows.size > 0 && <button onClick={exportAppeals} className={"btn btn-primary"}>
              <KTIcon iconName="cloud-download" className="fs-1"/>
            {intl.formatMessage({id: "Yuklab olish"})}
          </button>}
        </div>

        {isLoading ? (
            <Loader height={"70vh"}/>
        ) : reportsData?.results.length > 0 ? (
            <Components.Table
                actions={true}
                header={mock.tableOptions.adminReportsHeader}
                removeClassNames={true}
                forPaginationData={reportsData}
                title=" "
                body={reportsData?.results.map((d) => ({
                  ...d,
                  actions: (
                      <div className="d-flex flex-row gap-2">
                        <Link to={`/reports/${d.id}`} className="btn btn-icon btn-secondary">
                          <KTIcon iconName="eye" className="fs-1"/>
                        </Link>
                      </div>
                  ),
                }))}
                bodyKey={[...mock.tableOptions.adminReportsBodyKey, "actions"]}
                onCheckboxChange={handleCheckboxChange}
            />
        ) : <Empty title={intl.formatMessage({id: "Shikoyatlar mavjud emas!"})}/>}
      </div>
  );
};

export default ReportsSection;
