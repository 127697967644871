import React, {ChangeEvent, useState} from 'react'
import {Card} from "../../../common";
import {nameGenerator, phoneFormat} from "../../../../helpers";
import {useAuth} from "../../../layout/auth";
import {NOT_ENTER} from "../../../../mock/text";
import {Field, Form, Formik} from "formik";
import {ThemeModeComponent} from "../../../../../_metronic/assets/ts/layout";
import {setLanguage, useLang} from "../../../../../_metronic/i18n/Metronici18n";
import {systemMode, ThemeModeType, useThemeMode} from "../../../../../_metronic/partials";
import {updateProfileAvatar} from "../../../../api/_requests";
import {BASE_URL} from "../../../../api/URLs";
import {PROFILE_SENDMESSAGE} from "../../../../mock/page_urls";
import {useIntl} from "react-intl";

export const SettingsSection = () => {
  const { currentUser, logout } = useAuth();
  const intl = useIntl()
  const [profileImageFile, setProfileImageFile] = useState<File | null>(null);
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(currentUser?.data.avatar && BASE_URL + currentUser?.data.avatar || null);

  const handleProfileImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const validTypes = ['image/png', 'image/jpeg'];
      if (!validTypes.includes(file.type)) {
        alert('Invalid file type. Please select a PNG, JPG, or JPEG image.');
        return;
      }

      if (file.size > 10 * 1024 * 1024) {
        alert('Maksimal fayl hajmi 10MB');
        return;
      }

      setProfileImageFile(file);
      await updateProfileAvatar(file);

      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImageUrl(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  // clear profile image
  const handleClearProfileImage = async () => {
    setProfileImageFile(null);
    setProfileImageUrl(null);
    await updateProfileAvatar(profileImageFile)
  };

  // get current language
  const getLanguage = () => {
    const i18nConfig = localStorage.getItem("i18nConfig");
    if (i18nConfig) {
      const lang = JSON.parse(i18nConfig)?.selectedLang;
      if (lang) {
        return lang;
      } else {
        console.error("No selectedLang found in i18nConfig");
        return null;
      }
    } else {
      console.error("i18nConfig not found in localStorage");
      return null;
    }
  };

  // initial values for formik
  const initialValues = {
    language: getLanguage() || "uz",
    darkMode: ThemeModeComponent.getMode() !== "light",
  };

  // theme
  const { mode, menuMode, updateMode, updateMenuMode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;
  const switchMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode);
    updateMode(_mode);
  };

  // form on submit
  const handleSubmit = async (values) => {
    try {
      switchMode(values.darkMode ? "dark" : "light")
      setLanguage(values.language);
    } catch (error) {
      console.error("Error updating profile avatar:", error);
    }
  };

  return (
      <>
        {currentUser && (
            <div className={"card rounded-4 border border-gray-200 mt-10"}>
              <div>
                <div className="p-10 pb-3">
                  <div className={"d-flex align-items-center justify-content-between mt-5"}>
                    <h2>{intl.formatMessage({id: "Umumiy sozlamalar"})}</h2>
                  </div>
                </div>
                <hr className={"text-gray-500"} />
                <div className={"p-10 pt-5"}>
                  <div className="row fw-bold fs-3 mt-5">
                    <div className="col-md-4 text-gray-700">
                      <p>{intl.formatMessage({id: "Avatar"})}</p>
                    </div>
                    <div className="col-md-8 text-gray-800">
                      <div>
                        <input
                            type="file"
                            onChange={handleProfileImage}
                            id="upload-profile-image"
                            hidden
                            accept="image/png, image/jpeg, image/jpg"
                        />
                        <div
                            className="position-relative symbol symbol-100px symbol-lg-100px symbol-fixed position-relative">
                          {profileImageUrl ? (
                              <img
                                  className="img-fluid object-fit-cover"
                                  src={profileImageUrl}
                                  alt="Profile avatar"
                              />
                          ) : (
                              <span
                                  className="symbol-label fs-2x fw-semibold text-info bg-light-info fw-bolder">
                          {nameGenerator(currentUser?.data.first_name, currentUser?.data.last_name, "", "letter")}
                        </span>
                          )}
                          <div style={{bottom: '-10px', right: '5px'}} className={"position-absolute d-flex gap-2"}>
                            <label onClick={handleClearProfileImage}
                                   className="cursor-pointer bg-light text-gray-500 p-2 w-30px rounded shadow-sm fas fa-xmark d-flex justify-content-center"></label>
                            <label htmlFor={"upload-profile-image"}
                                   className="cursor-pointer bg-light text-gray-500 p-2 w-30px rounded shadow-sm fas fa-pen d-flex justify-content-center"></label>
                          </div>
                        </div>
                        <p className={"my-4 text-gray-500 h6"}>{intl.formatMessage({id: "Ruxsat berilgan fayl turlari (png, jpg, jpeg)"})}</p>
                      </div>
                    </div>
                  </div>

                  <div className="row fw-bold fs-3 mt-5 align-items-center">
                            <div className="col-md-4 text-gray-700">
                                <p>{intl.formatMessage({id: "Ism, Familiya, Otasining ismi"})} <span className={"text-danger"}>*</span></p>
                            </div>
                            <div className="col-md-8 text-gray-800">
                                <div className="row row-gap-3">
                                    <div className="col-md-4">
                                        <input
                                            type="text"
                                            className="form-control form-control-solid"
                                            placeholder={currentUser?.data.first_name}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            type="text"
                                            className="form-control form-control-solid"
                                            placeholder={currentUser?.data.last_name}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            type="text"
                                            className="form-control form-control-solid"
                                            placeholder={currentUser?.data.family_name}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row fw-bold fs-3 mt-5 align-items-center">
                            <div className="col-md-4 text-gray-700">
                                <p>{intl.formatMessage({id: "Viloyat"})} <span className={"text-danger"}>*</span></p>
                            </div>
                            <div className="col-md-8 text-gray-800">
                                <select disabled className="form-select form-select-solid" aria-label="Select example">
                                    <option>{currentUser?.data.address && currentUser?.data.address?.region?.name ? currentUser?.data.address?.region?.name : NOT_ENTER}</option>
                                </select>
                            </div>
                        </div>

                        <div className="row fw-bold fs-3 mt-5 align-items-center">
                            <div className="col-md-4 text-gray-700">
                                <p>{intl.formatMessage({id: "Tuman / Shahar"})} <span className={"text-danger"}>*</span></p>
                            </div>
                            <div className="col-md-8 text-gray-800">
                                <select disabled className="form-select form-select-solid" aria-label="Select example">
                                    <option>{currentUser?.data?.address && currentUser?.data?.address?.district?.name ? currentUser?.data?.address?.district?.name : NOT_ENTER}</option>
                                </select>
                            </div>
                        </div>

                        <div className="row fw-bold fs-3 mt-5 align-items-center">
                        <div className="col-md-4 text-gray-700">
                                <p>{intl.formatMessage({id: "Maktab"})} <span className={"text-danger"}>*</span></p>
                            </div>
                            <div className="col-md-8 text-gray-800">
                                <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    placeholder={currentUser?.data?.address && currentUser?.data.address?.organization?.name ? currentUser?.data?.address?.organization?.name : NOT_ENTER}
                                    disabled
                                />
                            </div>
                        </div>

                        <div className="row fw-bold fs-3 mt-5 align-items-center">
                            <div className="col-md-4 text-gray-700">
                                <p>{intl.formatMessage({id: "Telefon raqam"})} <span className={"text-danger"}>*</span></p>
                            </div>
                            <div className="col-md-8 text-gray-800">
                                <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    placeholder={currentUser?.data?.phone ? phoneFormat(currentUser?.data?.phone) : NOT_ENTER}
                                    disabled
                                />
                            </div>
                        </div>

                    </div>

                {currentUser?.data.role !== "super_admin" &&
                    <div className={"px-10"}>
                        <Card
                            icon={"shield-cross"}
                            status={"warning"}
                            title={intl.formatMessage({id: "Shaxsiy ma’lumotlarda xatolik bormi?"})}
                            description={`${intl.formatMessage({id: "Shaxsiy ma’lumotlaringizda xatolik bo’lsa"})} <a href="${PROFILE_SENDMESSAGE}" target="_blank" rel="noopener noreferrer">${intl.formatMessage({id: "Administratorga xabar yo’llang"})}</a>`}
                        />
                    </div>
                }


                <hr className={"text-gray-500 my-10"}/>

                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                  {({setFieldValue, values, resetForm}) => (
                      <Form>
                        <div className={"px-10"}>
                          <div className="row fw-bold fs-3 mt-5 align-items-center">
                          <div className="col-md-4 text-gray-700">
                                  <p>{intl.formatMessage({id: "Til"})}</p>
                                </div>
                                <div className="col-md-8 text-gray-800">
                                  <Field as="select" name="language" className="form-select form-select-solid"
                                         aria-label="Select example">
                                    <option value="uz">{intl.formatMessage({id: "O'zbekcha"})}</option>
                                    <option value="ru">{intl.formatMessage({id: "Rus tili"})}</option>
                                    <option value="en">{intl.formatMessage({id: "Ingliz tili"})}</option>
                                  </Field>
                                </div>
                          </div>
                        </div>

                            <div className={"px-10"}>
                              <div className="row fw-bold fs-3 mt-5 align-items-center">
                                <div className="col-md-4 text-gray-700">
                                  <p>{intl.formatMessage({id: "Tungi rejim"})}</p>
                                </div>
                                <div className="col-md-8 text-gray-800">
                                  <div className="form-check form-switch form-check-custom form-check-solid me-10">
                                    <Field
                                        type="checkbox"
                                        name="darkMode"
                                        className="form-check-input h-30px w-50px"
                                        onChange={(e) => setFieldValue('darkMode', e.target.checked)}
                                        checked={values.darkMode === true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <hr className={"text-gray-500 my-10"} />

                            <div className="d-flex justify-content-end p-10 pt-0 gap-5">
                              <button type="reset" className={"btn btn-secondary"}  onClick={() => resetForm()}>{intl.formatMessage({id: "Bekor qilish"})}</button>
                              <button type="submit" className={"btn btn-primary"}>{intl.formatMessage({id: "Saqlash"})}</button>
                            </div>
                          </Form>
                      )}
                    </Formik>
                </div>
            </div>
                )
        }
      </>
  )
}
