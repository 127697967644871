import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { MethodistRoutes } from "./MethodistRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Logout, AuthPage, useAuth } from "../components/layout/auth";
import { App } from "../App";
import {URL_ADMIN_MAIN, URL_METHODIST_MAIN, URL_TEACHER_MAIN} from "../mock/page_urls";
import { Roles } from "../types/role";
import { TeacherRoutes } from "./TeacherRoutes";
import {AdminRoutes} from "./AdminRoutes";
import {LandingRoutes} from "./LandingRoutes";

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<Navigate to={"/"} />} />
          <Route path="logout" element={<Logout />} />
          {currentUser?.data.role === Roles.METHODIST ? (
            <>
              <Route path="/*" element={<MethodistRoutes />} />
              <Route index element={<Navigate to={URL_METHODIST_MAIN} />} />
            </>
          ) : currentUser?.data.role === Roles.TEACHER ? (
            <>
              <Route path="/*" element={<TeacherRoutes />} />
              <Route index element={<Navigate to={URL_TEACHER_MAIN} />} />
            </>
          ): currentUser?.data.role === Roles.SUPERADMIN ? (
              <>
                <Route path="/*" element={<AdminRoutes />} />
                <Route index element={<Navigate to={URL_ADMIN_MAIN} />} />
              </>
          ) : (
            <>
              <Route path="/*" element={<LandingRoutes />} />
              <Route path="auth/*" element={<AuthPage />} />
              {/*<Route path="/*" element={<AdminRoutes />} />*/}
              {/*<Route path="*" element={<Navigate to="/auth/login" />} />*/}
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
