import React from 'react'
import {localTranslate} from "../../../../helpers";
import {mock} from "../../../../mock";
import {KTSVG} from "../../../../../_metronic/helpers";

export const FaqSection = () => {
  return (
      <div className={"py-10"}>
        <div className="container w-100 p-10 p-md-0">
          <div className={"text-center w-100 w-sm-100 w-md-75 w-lg-50 mx-auto mb-10"}>
            <h1 className={"display-6 my-5"}>{localTranslate("Tez-tez so'raladigan savol", "Часто задаваемые вопросы", "Frequently asked question\n")}</h1>
            <p className={"fs-5"}>{localTranslate("Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto, aspernatur dolor\n" +
                "              eius eveniet\n" +
                "              impedit iste molestiae nostrum officia quia quod?", "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto, aspernatur dolor\n" +
                "              eius eveniet\n" +
                "              impedit iste molestiae nostrum officia quia quod? ru", "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto, aspernatur dolor\n" +
                "              eius eveniet\n" +
                "              impedit iste molestiae nostrum officia quia quod? en")}</p>
          </div>
          <div className="accordion accordion-icon-toggle" id="kt_accordion_2">
            {
              mock.faq.map((item, index) => (
                  <div className="mb-5" key={index}>
                    <div
                        className="accordion-header py-3 d-flex"
                        data-bs-toggle="collapse"
                        data-bs-target={`#kt_accordion_2_item_${index}`}
                    >
                  <span className="accordion-icon">
                    <KTSVG
                        className="svg-icon svg-icon-4"
                        path="media/icons/duotune/arrows/arr064.svg"
                    />
                  </span>
                      <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                        {item.question}
                      </h3>
                    </div>
                    <div
                        id={`kt_accordion_2_item_${index}`}
                        className={`fs-6 collapse ${index === 0 ? 'show' : ''} ps-10`}
                        data-bs-parent="#kt_accordion_2"
                    >
                      <div className={"p-5 alert alert-primary"}>
                        {item.answer}
                      </div>
                    </div>
                  </div>
              ))
            }
          </div>
        </div>
      </div>
  )
}
