import React, {FC} from 'react'
import {KTIcon} from "../../../../_metronic/helpers";
import "./styles.scss"

interface IProps {
  status?: any, //"success" | "warning" | "danger",
  title?: string | number,
  description?: HTMLElement | any,
  icon?: string,
  iconSize?: string,
  direction?: "reverse",
  padding?: string
}

export const Card:FC<IProps> = ({status,padding, title, iconSize= "3x", icon, description, direction}) => {
  return (
      <div className={"hover-gray-100"}>
        <div className={`${padding} alert alert-${status === "success" ? "success" : status === "warning" ? "warning" : status === "danger" ? "danger" : "gray-300"} border-${status === "success" ? "success" : status === "warning" ? "warning" : status === "danger" ? "danger" : "gray-300"} border-dashed d-flex align-items-center p-5`}>
          <KTIcon iconName={`${icon}`} className={`me-4 fs-${iconSize} text-${status === "success" ? "success" : status === "warning" ? "warning" : status === "danger" ? "danger" : ""}`}/>
          <div className="d-flex flex-column">
            <h2 className={`mb-1 text-${status === "success" ? "success" : status === "warning" ? "warning" : status === "danger" ? "danger" : "gray-700"}`}>{title}</h2>
            <span className={"text-gray-500 fs-6"} dangerouslySetInnerHTML={{__html: description}}></span>
          </div>
        </div>
      </div>
  )
}
