export const URL_AUTH_LOGIN = "/login"
export const URL_AUTH_RESET_PASSWORD = "/reset-password/*"
export const URL_AUTH_RESET_PASSWORD_PHONE_NUMBER = "/reset-password/phone-number"
export const URL_AUTH_RESET_PASSWORD_CONFIRM = "/reset-password/number-confirm"
export const URL_AUTH_RESET_PASSWORD_NEW = "/reset-password/new-password"
export const URL_AUTH_REGISTER = "/register/*"
export const URL_AUTH_REGISTER_INFO = "/register/info"
export const URL_AUTH_REGISTER_CONFIRM = "/register/number-confirm"
export const URL_AUTH_JSHIR_REGISTER = "/jshir-register/*"
export const URL_AUTH_JSHIR_REGISTER_INFO = "/jshir-register/info"
export const URL_AUTH_JSHIR_REGISTER_CONFIRM = "/jshir-register/number-confirm"

export const URL_METHODIST_MAIN = "/main"
export const URL_METHODIST_MAIN_VIEW = "/main/:id"
export const URL_METHODIST_MAIN_EVALUATION = "/main/evaluation/:id"
export const URL_METHODIST_REPORTS = "/reports"
export const URL_METHODIST_REPORTS_VIEW = "/reports/:id"
export const URL_METHODIST_APPEALS = "/appeals"

export const URL_TEACHER_MAIN = "/main"
export const URL_TEACHER_MAIN_VIEW = "/main/:id"
export const URL_TEACHER_MAIN_NEW = "/main/new/*"
export const URL_TEACHER_MAIN_NEW_ABOUT = "/main/new/about"
export const URL_TEACHER_MAIN_NEW_DATA = "/main/new/data"
export const URL_TEACHER_MAIN_NEW_PED = "/main/new/ped"
export const URL_TEACHER_CHECK_MYSELF = "/check-myself"
export const URL_TEACHER_REPORTS = "/reports"
export const URL_TEACHER_REPORTS_VIEW = "/reports/:id"
export const URL_TEACHER_REPORTS_NEW = "/reports/new"

export const URL_ADMIN_MAIN = "/main"
export const URL_ADMIN_APPEALS = "/appeals"
export const URL_ADMIN_APPEALS_VIEW = "/appeals/:id"
export const URL_ADMIN_MAIN_VIEW = "/main/:id"
export const URL_ADMIN_MAIN_NEW = "/main/new/*"
export const URL_ADMIN_MAIN_NEW_ABOUT = "/main/new/about"
export const URL_ADMIN_MAIN_NEW_DATA = "/main/new/data"
export const URL_ADMIN_MAIN_NEW_PED = "/main/new/ped"
export const URL_ADMIN_MAIN_EVALUATION = "/appeals/evaluation/:id"
export const URL_ADMIN_CHECK_MYSELF = "/check-myself"
export const URL_ADMIN_REPORTS = "/reports/"
export const URL_ADMIN_REPORTS_ALL = "/reports/all"
export const URL_ADMIN_REPORTS_BYREGION = "/reports/by-region"
export const URL_ADMIN_REPORTS_ADDITIONAL = "/reports/additional"
export const URL_ADMIN_REPORTS_VIEW = "/reports/:id"
export const URL_ADMIN_REPORTS_NEW = "/reports/new"
export const URL_ADMIN_METHODISTS = "/methodists"
export const URL_ADMIN_METHODISTS_VIEW = "/methodists/:id"
export const URL_ADMIN_METHODISTS_NEW = "/methodists/new"
export const URL_ADMIN_REPORTS_CHANGE_NAME = "/reports/change-name/:id"
export const PROFILE = "/profile/*"
export const PROFILE_OVERVIEW = "/profile/overview"
export const PROFILE_SETTINGS = "/profile/settings"
export const PROFILE_SECURITY = "/profile/security"
export const PROFILE_SENDMESSAGE = "/send-message"
export const CHANGE_PASSWORD = "/profile/security/change-password"
export const ERROR_404 = "/404"

// landing routes
export const URL_LANDING_HOME = "/"
export const URL_FILTER_MANUALS = "/popular-methodological-works"
export const URL_REGISTER_INFO = "auth/register/info"
