import React, {FC} from 'react'
import {KTIcon} from "../../../../../../_metronic/helpers";
import {ILimitedAppeals} from "../../../../../types/limitedAppeals";
import {localTranslate, nameGenerator} from "../../../../../helpers";
import {BASE_URL} from "../../../../../api/URLs";

export const PopularWorksCard:FC<{appeal?: ILimitedAppeals}> = (appeal) => {
  return (
      <div className={"border bg-transparent border-secondary rounded-4"} style={{ backgroundColor: "rgba(249, 249, 249, 1)",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundBlendMode: "darken",
        backgroundImage: "url(/media/patterns/vector-gray.png)"}}>
        <div className="p-6 p-lg-8 fs-5">
          <h3 className={"fs-1"}>
            {nameGenerator(appeal?.appeal?.user?.first_name|| "", appeal?.appeal?.user?.last_name || "", appeal?.appeal?.user?.family_name || "")}
          </h3>
          <hr/>
          <p><b>{localTranslate("Hudud", "Территория", "Location")}:</b> {nameGenerator(appeal?.appeal?.user?.address?.region?.name || "", appeal?.appeal?.user?.address?.district?.name || "")}</p>
          <p><b>{localTranslate("Maktab", "Школа", "School")}:</b> {appeal?.appeal?.user?.address?.organization?.name}</p>
          <p><b>{localTranslate("Fan nomi", "Имя субъекта", "Subject name")} :</b> {appeal?.appeal?.science}</p>
          <p><b>{localTranslate("Metodik ish turi", "Методический вид работы", "Methodical type of work")}:</b> {appeal?.appeal?.method}</p>
          <a href={BASE_URL+appeal?.appeal?.file} target={"_blank"} className={"btn btn-sm gap-1 btn-primary d-flex align-items-center justify-content-center"}>{localTranslate("Metodik ish fayli", "Методический рабочий файл", "Methodical work file")}<KTIcon iconName={"arrow-up-right"} className={"text-white fs-1"} iconType={"solid"}/></a>
        </div>
      </div>
  )
}
