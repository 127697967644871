const urlCache = new Map();

export default (url: string) => {
  if (urlCache.has(url)) {
    return urlCache.get(url);
  }

  try {
    new URL(url);
    urlCache.set(url, true);
    return true;
  } catch (_) {
    urlCache.set(url, false);
    return false;
  }
};
