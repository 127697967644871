import { AuthModel, UserModel } from "./_models";
import {api} from "../../../../api"
import { BASE_URL, CONFIRM, ERP_REGISTER, GET_ME, RESET_PASSWORD, TOKEN } from "app/api/URLs";
import axios from "axios";
import { JshirRegisterRequestConfirmProps } from "app/types/jshirregister";
import {removeAuth} from "./AuthHelpers";
import {useAuth} from "./Auth";


async function getIpAndLocation() {
  const ipResponse = await fetch('https://api.ipify.org?format=json');
  const ipData = await ipResponse.json();
  const ip = ipData.ip;

  const locationResponse = await fetch('https://ipapi.co/json/');
  const locationData = await locationResponse.json();
  const location = `${locationData.city}, ${locationData.region}, ${locationData.country_name}`;

  return { ip, location };
}


export async function login(phone: string, password: string) {
  try {

    const deviceName = navigator.userAgent;

    const { ip, location } = await getIpAndLocation();
    const response = await api.post(TOKEN, {
      phone,
      password,
      device: deviceName,
      ip_address: ip,
      location: location,
    });

    return response;
  } catch (error) {
    console.error('Error in login:', error);
    throw error;
  }
}


export function register(
  jshir: any,
  phone: string,
  password: string,
  password_confirmation: string
) {
  const data = api.post(`${BASE_URL}${ERP_REGISTER}`, {
    phone,
    jshir,
    password,
    password2: password_confirmation,
  });
  return data
}


export async function reuqestErpConfirmPhone(otp: any, phone: any) {
  try {

    const deviceName = navigator.userAgent;

    const ipResponse = await fetch('https://api.ipify.org?format=json');
    const ipData = await ipResponse.json();
    const ip = ipData.ip;

    const locationResponse = await fetch('https://ipapi.co/json/');
    const locationData = await locationResponse.json();
    const location = `${locationData.city}, ${locationData.region}, ${locationData.country_name}`;

    const response = await api.post(`${BASE_URL}${CONFIRM}`, {
      code: otp,
      phone,
      is_erp: true,
      device: deviceName,
      ip_address: ip,
      location: location,
    });
    return response;

  } catch (error) {
    console.error('Error in reuqestErpConfirmPhone:', error);
    throw error;
  }
}



export function requestPassword(phone: string) {
  return api.post<{ result: boolean }>(RESET_PASSWORD, {
    phone,
  });
}

export function getUserByToken() {
  try {
    const res = api.get<UserModel>(GET_ME);
    return res;
  }catch (error:any) {
    console.error('Error in getUserByToken:', error);
    throw error;
  }
}
