import { NewPassword } from "./NewPassword";
import { NumberConfirm } from "./NumberConfirm";
import { PhoneNumber } from "./PhoneNumber";

const restorePassword = {
  NewPassword,
  NumberConfirm,
  PhoneNumber,
};

export default restorePassword;
