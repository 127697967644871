/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import {Link, useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import phoneFormat from "../../../../helpers/phoneFormat";
import onlyNumber from "../../../../helpers/onlyNumber";
import {PasswordMeterComponent} from "../../../../../_metronic/assets/ts/components";
import {URL_AUTH_JSHIR_REGISTER_INFO, URL_AUTH_LOGIN, URL_AUTH_REGISTER_CONFIRM,} from "../../../../mock/page_urls";
import {KTIcon} from "../../../../../_metronic/helpers";
import {register} from "../../../layout/auth/core/_requests";
import {PasswordInput} from "../../../common";
import {Helmet} from "react-helmet";

// const initialValues = {
//   jshir: "30904976070014",
//   phone: "+998 94 306-76-61",
//   password: "12345678a",
//   changepassword: "12345678a",
// };

const initialValues = {
  jshir: "",
  phone: "",
  password: "",
  changepassword: "",
};


export function Info() {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const registrationSchema = Yup.object().shape({
    jshir: Yup.string().length(14, intl.formatMessage({id: "Bu JSHSHIR emas"})).required(intl.formatMessage({id: "JSHSHIR majburiy"})),
    phone: Yup.string()
        .length(17, intl.formatMessage({id: "Telefon raqam emas"}))
        .required(intl.formatMessage({id: "Telefon raqam majburiy"})),
    password: Yup.string()
        .min(8, intl.formatMessage({id: "Minimal 8 ta belgi"}))
        // .matches(/[0-9]/, intl.formatMessage({id: "Kamida bitta raqam bo‘lishi majburiy"}))
        // .matches(/[a-z]/, intl.formatMessage({id: "Kamida bitta kichkina harf bo‘lishi majburiy"}))
        .required(intl.formatMessage({id: "Parol majburiy"})),
    changepassword: Yup.string()
        .min(3, intl.formatMessage({id: "Minimal 3 ta belg"}))
        .required(intl.formatMessage({id: "Parolni Tasdiqlash majburiy"}))
        .oneOf([Yup.ref("password")], intl.formatMessage({id: "Parol yuqoridagi bilan mos emas"})),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setSubmitting, setErrors, setFieldError}) => {
      setLoading(true);
      try {
        const {data: auth} = await register(
            values.jshir,
            onlyNumber(values.phone),
            values.password,
            values.changepassword
        );
        setLoading(false);
        setSubmitting(false);
        navigate(`/auth${URL_AUTH_REGISTER_CONFIRM}`);
        sessionStorage.setItem(
            "flx_register_phone_confirm",
            onlyNumber(values.phone)
        );
      } catch (error: any) {
        if ((error as any) && error.data) {
          setErrors(error.data);
          setSubmitting(false);
        }


        if ((error as any) && error.data.data.error_code == 4009) {
          setFieldError("jshir", error.data.data.message);
          setSubmitting(false);
          setShowModal(true)
          // setTimeout(() => {
          //   navigate(`/auth${URL_AUTH_JSHIR_REGISTER_INFO}`);
          // }, 5000);
        }

        if(error.data.status === 400){
          setFieldError("phone", error.data.data.phone.message);
          setSubmitting(false);
        }
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
      <>
        <Helmet>
          <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
          <title>
            {intl.formatMessage({id: "Ro'yxatdan o'tish | Ommalashtirish.uz - Oʻqituvchilarning ilgʻor tajribasi va faoliyati davomida qoʻllab kelayotgan zamonaviy metodikalarini ommalashtirish platformasi"})}
          </title>
          <meta charSet="UTF-8"/>
          <meta name="title"
                content="Ommalashtirish.uz - Oʻqituvchilarning ilgʻor tajribasi va faoliyati davomida qoʻllab kelayotgan zamonaviy metodikalarini ommalashtirish platformasi"/>
          <meta name="description"
                content="Maktabgacha va maktab taʼlimi tizimidagi oʻqituvchilarning ilgʻor ish tajribasi hamda faoliyati davomida qoʻllab kelayotgan zamonaviy metodikalarini ommalashtirish ishlarini maqbullashtirishga xizmat qiladi"/>
          <meta name="keywords" content="Ommalashtirish, ommalashtirish ishlari, Xalq ta’limi ommalashtirish, Respublika ta’lim markazi ommalashtirish, ommalashtirishga ish jo'natish, ishlarni ommalashtirish, pedagoglar ishlarini ommalashtirish, Xalq ta’limi respublikada ommalashtirish, viloyatda ommalashtirish, ommalashtirish tumanda, pedagoglar ishlarini Respublikada ommalashtirish, pedagoglarning ishlarini viloyatda ommalashtirish, pedagoglarning ishlarini tumanda ommalashtirish, Ommalashtirish , Omma, Omma ish, Ommalash ishi, ommalashtirish, omma, ommalash ishi , omma ish
  , Oммалаш, Оммалаштириш , Омма иши, Оммалаштириш, оммалаштириш ишлари, Халқ таълими оммалаштириш, Республика таълим маркази оммалаштириш, оммалаштиришга иш жўнатиш, ишларни оммалаштириш, педагоглар ишларини оммалаштириш, Халқ таълими республикада оммалаштириш, вилоятда оммалаштириш, оммалаштириш туманда, педагоглар ишларини Республикада оммалаштириш, педагогларнинг ишларини вилоятда оммалаштириш, педагогларнинг ишларини туманда оммалаштириш "/>
          <meta name="author" content="Felix-ITS LLC"/>
          <meta name="yandex-verification" content="7f3e2df6132fbb50"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta property="og:title"
                content="Ommalashtirish.uz - O'qituvchilar ishlarini ommalashtirish platformasi "/>
          <meta property="og:type" content="website"/>
          <meta property="og:locale" content="ru_RU"/>
          <meta property="og:url" content="https://ommalashtirish.uz/"/>
          <meta property="og:image" content="images/landing/logo2.svg"/>
          <meta property="og:image:type" content="image/jpeg"/>
          <meta property="og:image:width" content="300"/>
          <meta property="og:image:height" content="300"/>
          <meta property="og:image:alt" content="Лого ommalashtirish.uz"/>
          <meta property="og:site_name”" content="ommalashtirish.uz"/>
          <meta property="og:description”"
                content="Xalq ta’limi vazirligi huzuridagi Respublika ta’lim markazi yagona platformasi pedagoglarning ilg‘or ish - tajribalarni ommalashtirishga xizmat qiladi."/>
        </Helmet>
      <form
          className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          noValidate
          id="kt_login_signup_form"
          onSubmit={formik.handleSubmit}
      >

        {showModal && (
            <div>
              <div
                  className="position-fixed top-0 start-0 w-100 h-100"
                  style={{backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1040}}
              ></div>

              <div
                  className="shadow-sm position-absolute top-50 start-50 translate-middle alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10"
                  style={{zIndex: 1050}}
              >
                <button
                    type="button"
                    className="position-absolute top-0 end-0 m-2 btn btn-icon btn-icon-danger"
                    data-bs-dismiss="alert"
                    onClick={() => setShowModal(false)}
                >
                  <KTIcon iconName={"cross"} className={"fs-1"}/>
                </button>

                <KTIcon iconName={"information-5"} className={"fs-5tx text-danger mb-5"}/>

                <div className="text-center">
                  <h1 className="fw-bold mb-5 text-danger">
                    {intl.formatMessage({id: "Ushbu JSHSHIR bizning ERP malumotlarida topilmadi"})}
                  </h1>

                  <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                  <div className="mb-9 text-gray-900">
                    {intl.formatMessage({
                      id: "Iltimos JSHSHIR togri kiritilganligiga ishonch hosil qiling yoki ERP tizimisiz royxatdan oting",
                    })}
                  </div>

                  <div className="d-flex flex-center flex-wrap">
                    <a
                        className="btn btn-outline btn-outline-danger btn-active-danger m-2"
                        data-bs-dismiss="alert"
                        onClick={() => setShowModal(false)}
                    >
                      {intl.formatMessage({id: "Bekor qilish"})}
                    </a>
                    <Link
                        to={`/auth${URL_AUTH_JSHIR_REGISTER_INFO}`}
                        className="btn btn-danger m-2"
                    >
                      {intl.formatMessage({id: "ERPsiz royxatdan otish"})}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
        )}


        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">
            {intl.formatMessage({id: "Royhatdan otish"})}
          </h1>

          <div className="text-gray-500 fw-semibold fs-6">
            {intl.formatMessage({
              id: "Royhatdan otish uchun malumotlarni toldiring",
            })}
          </div>
        </div>

        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6">{intl.formatMessage({id: "JSHSHIR"})}</label>
          <input
              placeholder={intl.formatMessage({id: "JSHSHIRni kiriting"})}
              type="text"
              autoComplete="off"
              maxLength={14}
              onInput={(e) => {
                const target = e.target as HTMLInputElement;
                target.value = target.value.replace(/[^0-9]/g, '').slice(0, 14);
              }}
              {...formik.getFieldProps("jshir")}
              className={clsx(
                  "form-control bg-transparent",
                  {
                    "is-invalid": formik.touched.jshir && formik.errors.jshir,
                  },
                  {
                    "is-valid": formik.touched.jshir && !formik.errors.jshir,
                  }
              )}
          />

          {formik.touched.jshir && formik.errors.jshir && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.jshir}</span>
                </div>
              </div>
          )}
        </div>

        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({id: "Telefon raqam"})}
          </label>
          <input
              placeholder={intl.formatMessage({
                id: "Telefon raqamingizni kiriting",
              })}
              type="text"
              autoComplete="off"
              {...formik.getFieldProps("phone")}
              onChange={(e) =>
                  formik.setFieldValue("phone", phoneFormat(e.target.value))
              }
              className={clsx(
                  "form-control bg-transparent",
                  {
                    "is-invalid": formik.touched.phone && formik.errors.phone,
                  },
                  {
                    "is-valid": formik.touched.phone && !formik.errors.phone,
                  }
              )}
          />
          {formik.touched.phone && formik.errors.phone && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.phone}</span>
                </div>
              </div>
          )}
        </div>

        <div className="fv-row mb-8" data-kt-password-meter="true">
          <div className="mb-1">
            <label className="form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({id: "Parol"})}
            </label>
            <div className="position-relative mb-3">
              <PasswordInput
                  type="password"
                  placeholder={intl.formatMessage({id: "Parol o'ylab toping"})}
                  autoComplete="off"
                  {...formik.getFieldProps("password")}
                  className={clsx(
                      {
                        "is-invalid":
                            formik.touched.password && formik.errors.password,
                      },
                      {
                        "is-valid":
                            formik.touched.password && !formik.errors.password,
                      }
                  )}
              />
              {formik.touched.password && formik.errors.password && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.password}</span>
                    </div>
                  </div>
              )}
            </div>
          </div>
          <div className="text-muted">
            {intl.formatMessage({
              id: "8 yoki undan ko'p belgilar bilan foydalaning, harflar, raqamlar va belgilar aralashmasi bilan.",
            })}
          </div>
        </div>

        <div className="fv-row mb-5">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({id: "Parolni qayta kiriting"})}
          </label>
          <PasswordInput
              type="password"
              placeholder={intl.formatMessage({id: "Parolni qayta kiriting"})}
              autoComplete="off"
              {...formik.getFieldProps("changepassword")}
              className={clsx(
                  {
                    "is-invalid":
                        formik.touched.changepassword && formik.errors.changepassword,
                  },
                  {
                    "is-valid":
                        formik.touched.changepassword && !formik.errors.changepassword,
                  }
              )}
          />
          {formik.touched.changepassword && formik.errors.changepassword && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.changepassword}</span>
                </div>
              </div>
          )}
        </div>

        <div>
          {}
        </div>

        <div className="text-center">
          <button
              type="submit"
              id="kt_sign_up_submit"
              className="btn btn-lg btn-primary w-100 mb-5"
              disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
                <span className="indicator-label">
              {intl.formatMessage({id: "Royhatdan otish"})}
            </span>
            )}
            {loading && (
                <span className="indicator-progress" style={{display: "block"}}>
              {intl.formatMessage({id: "Iltimos kuting..."})}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
            )}
          </button>
        </div>

        <div className="text-gray-500 text-center fw-semibold fs-5">
          {intl.formatMessage({id: "JSHSHIR topilmadimi?"})}{" "}
          <Link
              to={`/auth${URL_AUTH_JSHIR_REGISTER_INFO}`}
              className="link-primary"
          >
            {intl.formatMessage({id: "erp.maktab.uz siz royhatdan otish"})}
          </Link>
        </div>
        <div className="text-gray-500 text-center fw-semibold fs-5">
          {intl.formatMessage({id: "Akkauntingiz bormi?"})}{" "}
          <Link to={`/auth${URL_AUTH_LOGIN}`} className="link-primary">
            {intl.formatMessage({id: "Kirish"})}
          </Link>
        </div>
      </form>
      </>
  );
}

