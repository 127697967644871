import React, { FC } from 'react';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import {ScrollTopComponent} from "../../../../_metronic/assets/ts/components";

interface PaginationProps {
  data: {
    current_page: number;
    total_pages: number;
    links: {
      next?: string;
      previous?: string;
    };
  };
  fetchData: any;
}

export const Pagination: FC<PaginationProps> = ({ data, fetchData }) => {
  const currentPage = data?.current_page || 1;
  const totalPages = data?.total_pages || 1;
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePageChange = (page: number) => {
    const params = new URLSearchParams(searchParams);
    params.set('page', page.toString());
    setSearchParams(params);
    ScrollTopComponent.goTop()
  };

  const handleNext = (event: React.MouseEvent) => {
    event.preventDefault();
    if (data?.links?.next) {
      handlePageChange(currentPage + 1);
      ScrollTopComponent.goTop()
    }
  };

  const handlePrevious = (event: React.MouseEvent) => {
    event.preventDefault();
    if (data?.links?.previous) {
      handlePageChange(currentPage - 1);
      ScrollTopComponent.goTop()
    }
  };

  const generatePageNumbers = () => {
    const pageNumbers: (number | string)[] = [];
    const range = 1;

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage > range + 2) {
        pageNumbers.push(1, '...');
      } else {
        for (let i = 1; i < currentPage; i++) {
          pageNumbers.push(i);
        }
      }

      for (let i = Math.max(currentPage - range, 1); i <= Math.min(currentPage + range, totalPages); i++) {
        if (!pageNumbers.includes(i)) {
          pageNumbers.push(i);
        }
      }

      if (currentPage < totalPages - range - 1) {
        pageNumbers.push('...', totalPages);
      } else {
        for (let i = currentPage + 1; i <= totalPages; i++) {
          if (!pageNumbers.includes(i)) {
            pageNumbers.push(i);
          }
        }
      }
    }

    return pageNumbers;
  };
  const pageNumbers = generatePageNumbers();

  return (
      <ul className="pagination mt-5">
        <li
            className={clsx('page-item previous', !data?.links?.previous && 'disabled')}
        >
          <button
              onClick={handlePrevious}
              className="page-link"
              aria-label="Previous page"
              disabled={!data?.links?.previous}
          >
            <i className="previous"></i>
          </button>
        </li>

        {pageNumbers.map((page, index) =>
            typeof page === 'number' ? (
                <li
                    key={index}
                    className={clsx('page-item', currentPage === page && 'active')}
                >
                  <button
                      onClick={(e) => {
                        e.preventDefault();
                        handlePageChange(page);
                      }}
                      className="page-link"
                      aria-label={`Page ${page}`}
                  >
                    {page}
                  </button>
                </li>
            ) : (
                <li key={index} className="page-item disabled">
                  <span className="page-link">...</span>
                </li>
            )
        )}

        <li
            className={clsx('page-item next', !data?.links?.next && 'disabled')}
        >
          <button
              onClick={handleNext}
              className="page-link"
              aria-label="Next page"
              disabled={!data?.links?.next}
          >
            <i className="next"></i>
          </button>
        </li>
      </ul>
  );
};
