import React, { FC } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Dropzone } from "../../components/common";
import { postSendMessage } from "../../api/_requests";
import { useNotification } from "../../context/NotificationContext";
import {PageLink, PageTitle} from "../../components/layout/core";
import {useNavigate} from "react-router-dom";
import {PROFILE_OVERVIEW} from "../../mock/page_urls";
import {useIntl} from "react-intl";


interface IProps {}

export const SendMessagePage: FC<IProps> = () => {
  const intl = useIntl();
  const [fileDrop, setFileDrop] = React.useState<File | null>(null);
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const sendMessageBreadCrumbs: PageLink[] = [
    {
      title: intl.formatMessage({id: "Administratorga xabar yo’llash"}),
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(400, intl.formatMessage({id: "Maksimal 400 belgi"})).required(intl.formatMessage({id: "Mavzu majburiy"})),
    description: Yup.string(),
  });


  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    if (!fileDrop) {
      showNotification(intl.formatMessage({id: "Fayl tanlanmagan"}), "", "error");
      setSubmitting(false);
      return;
    }

    const res = await postSendMessage(values.title, values.description, fileDrop);

    if (res) {
      if (res.status === 201) {
        showNotification(res?.data.message, "", "success");
        navigate(PROFILE_OVERVIEW)
      } else {
        showNotification(res?.data.message, "", "error");
      }
    }
    setSubmitting(false);
  };

  return (
      <div className="px-10">
        <div className="card rounded-4 border border-gray-200 p-5 p-md-10 p-lg-20 pb-0 pt-1">
          <PageTitle
              breadcrumbs={sendMessageBreadCrumbs}
              description={intl.formatMessage({id: "Profil ma’lumotlarini o’zgartirish uchun administratorga xabar yo’llash"})}
          />
          <div className="p-5 p-md-10 p-lg-10">
            <div className="mb-20">
              <h1 className="fw-bold">{intl.formatMessage({id: "Ma’lumotlarni kiriting"})}</h1>
              <p className="text-gray-500 fw-bold">{intl.formatMessage({id: "Xabar yuborish uchun ma’lumotlarni kiriting"})}</p>
            </div>
            <Formik
                initialValues={{title: '', description: '', file: null}}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
              {({setFieldValue, isSubmitting}) => (
                  <Form>
                    <div className="mb-5">
                      <label className="form-label">{intl.formatMessage({id: "Mavzu"})} <span className="text-danger fs-3">*</span></label>
                      <Field
                          type="text"
                          name="title"
                          className="form-control"
                          placeholder={intl.formatMessage({id: "Xabar mavzusini kiriting"})}
                      />
                      <ErrorMessage name="title" component="div" className="text-danger"/>
                    </div>

                    <div className="mb-5">
                      <label className="form-label">{intl.formatMessage({id: "Qo’shimcha izoh"})}</label>
                      <Field
                          as="textarea"
                          name="description"
                          className="form-control"
                          placeholder={intl.formatMessage({id: "Matnini kiriting"})}
                          rows={3}
                      />
                      <ErrorMessage name="description" component="div" className="text-danger"/>
                    </div>

                    <div className="mb-5">
                      <label className="form-label">{intl.formatMessage({id: "Passportingiz rasmi"})} <span
                          className="text-danger fs-3">*</span></label>
                      <Dropzone
                          title={intl.formatMessage({id: "Passportingizni shu yerga rasm(.jpg, .png, .jpeg) yoki PDF ko'rinishida yuklang. Aks holda arizangiz ko'rib chiqilmasligi mumkin."})}
                          fileDrop={fileDrop}
                          setFileDrop={setFileDrop}
                          accept={{'image/jpeg': [], 'image/png': [], 'application/pdf': []}}
                      />
                      <ErrorMessage name="file" component="div" className="text-danger"/>
                    </div>

                    <div className="text-end mt-20">
                      <button type="submit" className="btn btn-primary" disabled={isSubmitting}>{isSubmitting ? intl.formatMessage({id: "Yuborilmoqda..."}): intl.formatMessage({id: "Yuborish"})}</button>
                    </div>
                  </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
  );
};
