/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from "react";
import {useIntl} from "react-intl";
import {Link, useNavigate, useParams} from "react-router-dom";
import {PageLink, PageTitle, PageToolbar} from "../../components/layout/core";
import {useQuery} from "react-query";
import {getModeratorAppealId, moderatorUpdateAppeal} from "../../api/_requests";
import {Card} from "../../components/common";
import {BASE_URL} from "../../api/URLs";
import {checkStatus, nameGenerator, phoneFormat} from "../../helpers";
import formatDateDDMMYYY from "../../helpers/formatDateDDMMYYY";
import {NOT_ENTER} from "../../mock/text";
import {Form, Formik} from "formik";
import {useNotification} from "../../context/NotificationContext";
import {URL_ADMIN_APPEALS} from "../../mock/page_urls";

const AppealViewPage: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const router = useParams()
  const {id} = useParams<{ id: string }>();
  const [isRejectedNoteRead, setIsRejectedNoteRead] = useState<boolean>(false);
  const {data: moderatorAppealId, isSuccess} = useQuery(
      ["moderator_appeal_id", id],
      () => getModeratorAppealId(id),
      {
        enabled: !!id,
      }
  );


  const [comment, setComment] = useState<string>(moderatorAppealId?.data?.comment || "")
  const {showNotification} = useNotification();
  const appealViewBreadCrumbs: Array<PageLink> = [
    {
      title: `${intl.formatMessage({id: "Arizalar"})} / ${nameGenerator(moderatorAppealId?.data.user.first_name || NOT_ENTER, moderatorAppealId?.data.user.last_name || NOT_ENTER, moderatorAppealId?.data.user.family_name, "fullname")}`,
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];
  const appealViewToolbars: Array<PageToolbar> = [
    {
      title: intl.formatMessage({id: "Yuklangan ish"}),
      icon: "exit-right-corner",
      isActive: true,
      additional: {
        onClick: () => window.open(BASE_URL + moderatorAppealId?.data.file),
      },
    },
  ];
  const disabledStatus = ["RATED", "CANCELLED"]

  const disableStatusCheck = (): boolean => {
    return false
  }

  const handleSubmit = async (values: any, status: string) => {
    const response = await moderatorUpdateAppeal(id || 0, comment, status);
    if (response?.data?.status === "RATED") {
      showNotification(intl.formatMessage({id: "Ariza tasdiqlandi"}), "", "success");
      navigate(URL_ADMIN_APPEALS);
    } else if (response?.data?.status === "CANCELLED") {
      showNotification(intl.formatMessage({id: "Ariza rad etildi"}), "", "error")
      navigate(URL_ADMIN_APPEALS);
    }
  }

  return (
      <>
        <PageTitle
            breadcrumbs={appealViewBreadCrumbs}
            toolbar={appealViewToolbars}
            description={intl.formatMessage({id: "Ariza yuborish va uni ko’rish"})}
        />
        <div className="px-10">
          <div className="card rounded-4 border border-gray-200 p-10">
            <p className={"text-dark"}>{intl.formatMessage({id: "Ariza haqida"})}</p>
            <p className={"fs-3 lh-large text-gray-500"}><b
                className={"text-dark"}>{intl.formatMessage({id: "Nomi"})}: </b> {moderatorAppealId?.data.name}</p>

            <p className={"fs-3 text-gray-500 lh-lg"}><b
                className={"text-dark"}>{intl.formatMessage({id: "Tavsif"})}: </b>{moderatorAppealId?.data.description}</p>

            <div className="row row-gap-3">
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={moderatorAppealId?.data.science.name}
                    description={intl.formatMessage({id: "Fan"})}
                    icon="teacher"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={moderatorAppealId?.data.method.name}
                    description={intl.formatMessage({id: "Metodik ishlanma turi"})}
                    icon="note-2"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={intl.formatMessage({id: "Metodik ish fayl"})}
                    description={
                      `${intl.formatMessage({id: "Yuklangan ish"})} <a href=${BASE_URL}${moderatorAppealId?.data.file} target="_blank" download>${intl.formatMessage({id: "(Yuklab olish)"})}</a>`
                    }
                    icon="tablet-ok"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={checkStatus(moderatorAppealId?.data.status || "", "text")}
                    description={intl.formatMessage({id: "Status"})}
                    status={checkStatus(moderatorAppealId?.data.status || "")}
                    icon="award"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={formatDateDDMMYYY(moderatorAppealId?.data.created_at || "")}
                    description={intl.formatMessage({id: "Ariza yaratilgan vaqt"})}
                    icon="time"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={moderatorAppealId?.data.academic_year.name}
                    description={intl.formatMessage({id: "Topshirilgan o'quv yili"})}
                    icon="calendar-8"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
            </div>

            <h3 className={"text-gray-500 my-5"}>{intl.formatMessage({id: "Ariza yuboruvchi"})}</h3>
            <div className="row row-gap-3">
              <div className="col-md-4">
                <Card
                    title={nameGenerator(moderatorAppealId?.data.user.first_name || NOT_ENTER, moderatorAppealId?.data.user.last_name || NOT_ENTER, moderatorAppealId?.data.user.family_name, "fullname")}
                    description={intl.formatMessage({id: "F.I.O"})}
                    icon="user"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-md-4">
                <Card
                    title={moderatorAppealId?.data.user.jshir || NOT_ENTER}
                    description={intl.formatMessage({id: "JSHSHIR"})}
                    icon="key"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-md-4">
                <Card
                    title={moderatorAppealId?.data.user.address.region.name || NOT_ENTER}
                    description={intl.formatMessage({id: "Viloyat"})}
                    icon="map"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-md-4">
                <Card
                    title={moderatorAppealId?.data.user.address.district.name || NOT_ENTER}
                    description={intl.formatMessage({id: "Tuman / Shahar"})}
                    icon="geolocation"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-md-4">
                <Card
                    title={moderatorAppealId?.data.user.address.organization.name || NOT_ENTER}
                    description={intl.formatMessage({id: "Maktab"})}
                    icon="open-book"
                    iconSize="4x"
                    padding="p-10"
                />

              </div>
              <div className="col-md-4">
                <Card
                    title={phoneFormat(moderatorAppealId?.data.user.phone || NOT_ENTER)}
                    description={intl.formatMessage({id: "Telefon raqam"})}
                    icon="phone"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-md-4">
                <Card
                    title={moderatorAppealId?.data.user.gender === "MALE" ? intl.formatMessage({id: "Erkak"}) : moderatorAppealId?.data.user.gender === "FEMALE" ? intl.formatMessage({id: "Ayol"}) : NOT_ENTER}
                    description={intl.formatMessage({id: "Jins"})}
                    icon="abstract-28"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-md-4">
                <Card
                    title={intl.formatMessage({id: "Diplom"})}
                    description={
                      `${intl.formatMessage({id: "Mutahassislik diplomi"})} <a href=${BASE_URL + moderatorAppealId?.data.diploma_file} target="_blank" download>${intl.formatMessage({id: "(Yuklab olish)"})}</a>`
                    }
                    icon="tablet-ok"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-md-4">
                <Card
                    title={intl.formatMessage({id: "Ma'lumotnoma (Obyektivka)"})}
                    description={
                      `${intl.formatMessage({id: "Ma'lumotnoma"})} <a href=${BASE_URL + moderatorAppealId?.data.objective_file} target="_blank" download>${intl.formatMessage({id: "(Yuklab olish)"})}</a>`
                    }
                    icon="paper-clip"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
            </div>

            <h3 className={"text-gray-500 my-5"}>{intl.formatMessage({id: "Maktab Pedagogik kengashi haqida"})}</h3>
            <div className="row row-gap-3">
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={moderatorAppealId?.data.decision_date}
                    description={intl.formatMessage({id: "Maktab Pedagogik kengash qarori sanasi"})}
                    icon="profile-user"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={moderatorAppealId?.data.decision_number}
                    description={intl.formatMessage({id: "Maktab Pedagogik kengash qarori raqami"})}
                    icon="pin"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={intl.formatMessage({id: "Maktab Pedagogik kengash qarori fayli"})}
                    description={
                      `${intl.formatMessage({id: "Maktab Pedagogik kengash qarori"})} <a href=${BASE_URL + moderatorAppealId?.data.decision_file} target="_blank" download>${intl.formatMessage({id: "(Yuklab olish)"})}</a>`
                    }
                    icon="verify"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
            </div>

            <h3 className={"text-gray-500 my-5"}>{intl.formatMessage({id: "Baholar"})}</h3>
            <div className="row row-gap-3">
              <div className="col-sm-6 col-md-6 col-lg-4 align-items-stretch">
                <Card
                    title={`${moderatorAppealId?.ball} ball (${nameGenerator(moderatorAppealId?.moderators[0]?.first_name || "", moderatorAppealId?.moderators[0]?.last_name || "", moderatorAppealId?.moderators[0]?.family_name, "fullname")} ${intl.formatMessage({id: "(Metodist)"})})`}
                    description={intl.formatMessage({id: "Birinchi bosqichda qo’yilgan baho"})}
                    icon="like"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4 align-items-stretch">
                <Card
                    title={`${moderatorAppealId?.country_ball} ${intl.formatMessage({id: "ball"})}`}
                    description={intl.formatMessage({id: "Ikkinchi bosqichda qo’yilgan baho"})}
                    icon="cup"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={`${Number(moderatorAppealId?.ball_avg)} ${intl.formatMessage({id: "ball"})}`}
                    description={intl.formatMessage({id: "Umumiy ball"})}
                    icon="ranking"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <Card
                    title={`${moderatorAppealId?.self_ball}`}
                    description={intl.formatMessage({id: "O’zi qo’ygan bahosi"})}
                    icon="chart-simple"
                    iconSize="4x"
                    padding="p-10"
                />
              </div>
            </div>

          </div>

          <Formik
              initialValues={{comment: moderatorAppealId?.data.comment || ""}}
              onSubmit={(values) => handleSubmit(values, "RATED")}
          >
            <Form>
              {moderatorAppealId?.data.status === "CANCELLED" &&
                  <div className="card rounded-4 border border-gray-200 p-10 mt-5">
                      <label className="form-label">{intl.formatMessage({id: "Izoh"})}</label>
                      <textarea
                          className="form-control"
                          name="comment"
                          value={moderatorAppealId?.data.comment}
                          placeholder={intl.formatMessage({id: "Matnini kiriting"})}
                          disabled={true}
                          rows={5}
                      />
                  </div>}

              <div className="d-flex justify-content-end mt-5">
                <div className="d-flex gap-5">
                  <Link to={`${disableStatusCheck() ? "" : `/appeals/evaluation/${router.id}`}`}>
                    <button className="btn btn-success"
                            disabled={disableStatusCheck()}>
                      {disableStatusCheck() ? intl.formatMessage({id: "Baholangan"}) : intl.formatMessage({id: "Baholashga o'tish"})}
                    </button>
                  </Link>
                </div>
              </div>
            </Form>
          </Formik>

        </div>
      </>
  );
};


export {AppealViewPage}
