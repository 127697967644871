import {  Cabinet } from "./Cabinet"
import {ChangePasswordPage} from "./ChangePassword";
import {SendMessagePage} from "./SendMessage";

const toAll = {
  Cabinet,
  ChangePasswordPage,
  SendMessagePage
}

export default toAll