/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {useIntl} from "react-intl";
import {PageLink, PageTitle} from "../../components/layout/core";
import {useNavigate, useParams} from "react-router-dom";
import {KTIcon} from "../../../_metronic/helpers";
import {useQuery} from "react-query";
import {getTeacherReportId} from "../../api/_requests";
import formatDateDDMMYYY from "../../helpers/formatDateDDMMYYY";
import {checkStatus} from "../../helpers";
import { BASE_URL_MEDIA} from "../../api/URLs";
import {Empty, Loader} from "../../components/common";
import {ERROR_404} from "../../mock/page_urls";

const ReportViewPage = () => {
  const intl = useIntl();
  const {id} = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {data: teacherReportId, isSuccess, isError, isLoading} = useQuery(
      ["teacher_report_id", id],
      () => getTeacherReportId(id),
      {enabled: !!id}
  );

  if (isError) {
    navigate(ERROR_404);
    return null;
  }

  const reportsBreadCrumbs: Array<PageLink> = [
    {
      title: `${intl.formatMessage({id: "Shikoyatlar"})} / ${teacherReportId?.data.title}`,
      path: "/",
      isSeparator: false,
      isActive: false,
    },
  ];

  return (
      <>
        {isLoading && <Loader/>}
        {
          isSuccess ? <>
            <PageTitle
                description={intl.formatMessage({id: "Yuborilgan shikoyat va uning natijasi"})}
                breadcrumbs={reportsBreadCrumbs}
            />
            <div className="px-6 px-md-10">
              <div className="bg-light rounded-4 border border-gray-200 p-10 p-md-20 pb-0 pt-1">
                <div className="py-10 py-md-20">
                  <h2 className="fw-bold mb-10">{intl.formatMessage({id: "Yuborilgan shikoyat"})}</h2>
                  <div className="row row-gap-5">
                    <div className="col-md-5 pe-5">
                      {teacherReportId?.data?.file && (
                          <iframe
                              src={`${BASE_URL_MEDIA + teacherReportId?.data?.file + "#toolbar=0"}`}
                              width="100%"
                              height="500px"
                              title="Teacher Report"
                          ></iframe>
                      )}
                    </div>
                    <div className="col-md-7">
                      <div className="d-flex gap-10">
                        <div className="d-flex align-items-center gap-3">
                          <KTIcon iconName="time" className="fs-1 text-primary"/>{" "}
                          <span className="fs-2 text-gray-500">
                            {formatDateDDMMYYY(teacherReportId?.data?.created_at)}
                          </span>
                        </div>

                        <div className="d-flex align-items-center gap-3">
                          <KTIcon
                              iconName="check"
                              className={`fs-1 text-${checkStatus(teacherReportId?.data?.status)}`}
                          />
                          <span className="fs-2 text-gray-500">
                            {checkStatus(teacherReportId?.data.status, "text")}
                          </span>
                        </div>
                      </div>
                      <h1 className="my-10 text-gray-800">{teacherReportId?.data?.title}</h1>
                      <p className="fs-3 text-gray-600 lh-lg text-break">
                        {teacherReportId?.data?.message}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {teacherReportId?.data.response && (
                  <div className="mt-10 bg-light rounded-4 border border-gray-200 p-10 p-md-20 pb-0 pt-1">
                    <div className="py-10 py-mb-20">
                      <h2 className="fw-bold mb-10">{intl.formatMessage({id: "Shikoyatning javobi"})}</h2>
                      <div className="row">
                        <div className="col-md-5 pe-0">
                          {teacherReportId?.data.response_file && (
                              <iframe
                                  src={`${BASE_URL_MEDIA + teacherReportId?.data.response_file + "#toolbar=0"}`}
                                  width="100%"
                                  height="500px"
                                  title="Teacher Report Response"
                              ></iframe>
                          )}
                        </div>
                        <div className="col-md-7">
                          <p className="fs-3 text-gray-600 lh-lg p-5 pt-md-10 pt-lg-0 text-break">
                            {teacherReportId?.data.response}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
              )}
            </div>
          </> : <Empty title={intl.formatMessage({id: "Shikoyat ma'lumotlari topilmadi!"})}/>
        }
      </>
  );
};

export {ReportViewPage};
