import React, {useEffect, useState} from "react";
import {Navigate, Outlet, Route, Routes, useNavigate, useSearchParams} from "react-router-dom";
import Components from "app/components";
import {URL_TEACHER_CHECK_MYSELF, URL_TEACHER_MAIN_NEW_ABOUT} from "app/mock/page_urls";
import {PageLink, PageTitle} from "../../components/layout/core";
import {getTeacherAppeal, postTeacherAppeal} from "../../api/_requests";
import {useNotification} from "../../context/NotificationContext";
import {useIntl} from "react-intl";
import {clearFormData, getFormData, saveFormData} from "../../helpers/indexedDB";

const AppealNewPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';

  const [aboutForm, setAboutForm] = useState({ type: '' });
  const [pedForm, setPedForm] = useState(null);
  const [dataForm, setDataForm] = useState(null);
  const navigate = useNavigate();
  const intl = useIntl();
  const { showNotification } = useNotification();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadFormData = async () => {
      try {
        const savedData = await getFormData();
        if (savedData) {
          setAboutForm(savedData.aboutForm);
          setPedForm(savedData.pedForm);
          setDataForm(savedData.dataForm);
        }
      } catch (error:any) {
        console.error("Error loading form data:", error);
      } finally {
        setLoading(false);
      }
    };
    loadFormData();
  }, []);

  const handleSave = async () => {
    try {
      const res = await postTeacherAppeal(aboutForm, pedForm, dataForm);
      if (res.status === 201) {
        showNotification(res.data.message, "", "success");
        localStorage.setItem("lastestCreatedAppeal", JSON.stringify(res?.data?.results));
        await clearFormData();
        setAboutForm({ type: '' });
        setPedForm(null);
        setDataForm(null);
        window.location.href = URL_TEACHER_CHECK_MYSELF;
      }
    } catch (error:any) {
      showNotification(error?.data?.data.message || "An unexpected error occurred.", "", "error");
    }
  };

  useEffect(() => {
    const saveDataToDB = async () => {
      await saveFormData({ aboutForm, pedForm, dataForm });
    };
    saveDataToDB();
  }, [aboutForm, pedForm, dataForm]);

  if (loading) return <div>Loading...</div>;

  const components = Components.section.teacher.appeal.appealNew;

  const breadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: "Mening arizam"}),
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];

  return (
      <>
        <PageTitle breadcrumbs={breadCrumbs} description={intl.formatMessage({ id: "Metodik ishlanmani ommalashtirish uchun ariza yuborish" })} />
        <div className={"px-10"}>
          <Routes>
            <Route
                path="*"
                element={
                  <Outlet
                      context={{
                        aboutForm,
                        setAboutForm,
                        dataForm,
                        setDataForm,
                        pedForm,
                        setPedForm,
                        handleSave
                      }}
                  />
                }
            >
              <Route path="about" element={<components.AppealAboutSection />} />
              <Route path="ped" element={<components.AppealPedSection />} />
              <Route path="data" element={<components.AppealDataSection />} />
              <Route index element={<Navigate to={URL_TEACHER_MAIN_NEW_ABOUT} />} />
            </Route>
          </Routes>
        </div>
      </>
  );
};

export { AppealNewPage };


