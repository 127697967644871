/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from "react";
import {PageLink, PageTitle, PageToolbar} from "../../components/layout/core";
import {useNavigate} from "react-router-dom";
import CheckMySelfSection from "../../components/section/teacher/checkMySelf/CheckMySelf";
import {BASE_URL} from "../../api/URLs";
import {useIntl} from "react-intl";


const CheckMyselfPage: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const lastestCreatedAppeal = localStorage.getItem("lastestCreatedAppeal");
  const lastestCreatedAppealData = JSON.parse(lastestCreatedAppeal || "[]");


  const myselfToolbars: Array<PageToolbar> = [
    {
      title: intl.formatMessage({id: "Metodik ishlanmani ko’rish"}),
      icon: "exit-right-corner",
      isActive: true,
      additional: {
        onClick: () => window.open(BASE_URL + lastestCreatedAppealData?.file, "_blank"),
      },
    },
  ];

  const myselfBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: "O’zining ishi bo’yicha talablarni tekshirish"}),
      path: "/",
      isSeparator: false,
      isActive: false,
    },
  ];


  return (
      <>
        <PageTitle
            description={intl.formatMessage({id: "Metodik ishlanma va savollar"})}
            breadcrumbs={myselfBreadCrumbs}
            toolbar={myselfToolbars}
        />
       <div className="px-10">
         <CheckMySelfSection data={lastestCreatedAppealData}/>
       </div>
      </>
  );
};

export {CheckMyselfPage};
