/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from "react";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import {PageLink, PageTitle} from "../../components/layout/core";
import Components from "../../components";
import {PROFILE_OVERVIEW} from "../../mock/page_urls";
import {useIntl} from "react-intl";


const Cabinet: FC = () => {
  const components = Components.section.cabinet;
  const intl = useIntl();

  const cabinetBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: "Kabinet"}),
      path: "/",
      isSeparator: false,
      isActive: false,
    },
  ];

  return (
      <div className="px-10">
        <Routes>
          <Route
              element={
                <>
                  <PageTitle
                      description={intl.formatMessage({id: "Umumiy ko’rinish va Sozlamalar"})}
                      breadcrumbs={cabinetBreadCrumbs}
                  />

                  <components.ProfileSection/>
                  <Outlet/>
                </>
              }
          >
            <Route
                path="overview"
                element={<components.OverviewSection/>}
            />
            <Route
                path="settings"
                element={<components.SettingsSection/>}
            />
            <Route
                path="security"
                element={<components.SecuritySection/>}
            />
            <Route index element={<Navigate to={PROFILE_OVERVIEW}/>}/>
          </Route>
        </Routes>
      </div>
  );
};

export {Cabinet};
