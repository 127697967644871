import {ReportsPage} from "./Reports";
import {ReportNewPage} from "./ReportNew";
import {CheckMyselfPage} from "./CheckMyself";
import {AppealsPage} from "./Appeals";
import {AppealNewPage} from "./AppealNew";
import {AppealViewPage} from "./AppealView";
import {ReportViewPage} from "./ReportView";

const teacher = {
  AppealsPage,
  AppealNewPage,
  AppealViewPage,
  ReportsPage,
  ReportNewPage,
  ReportViewPage,
  CheckMyselfPage,
}

export default teacher