import React, {FC, memo} from 'react';
import {useIntl} from "react-intl";

interface IAnswer {
  id: number;
  name: string;
  ball?: number;
}

interface IQuestion {
  id: number;
  text: string;
  variant: IAnswer[];
  type: 'RADIO' | 'CHECKBOX';
  required?: boolean;
}

interface IProps {
  question: IQuestion;
  index: number;
  onInputChange?: (questionId: number, answerId: number, isChecked: boolean) => void;
  selectedAnswers?: { [key: number]: number[] };
  selectedRadioAnswer?: { [key: number]: number };
  initialChecked?: boolean;
}

export const Question: FC<IProps> = ({question, index, onInputChange, selectedAnswers, selectedRadioAnswer, initialChecked}) => {
  const shouldRenderBorder = index !== 3 && index !== 25;
  const intl = useIntl();

  return (
      <div className="d-flex mt-5">
        <div className="d-flex align-items-center flex-column">
          <div className="symbol symbol-50px symbol-circle mb-5">
            <span className="symbol-label bg-light-gray-300 text-gray-500 fs-5 fw-bolder">{index + 1}</span>
          </div>
          {shouldRenderBorder &&
              <div style={{borderWidth: 1}} className="h-100 border-end-dashed border-gray-300"></div>}
        </div>
        <div className="ps-5">
          <h3>{question.text}</h3>
          <p className="text-gray-500">{intl.formatMessage({id: "Savol"})} {question.id}</p>

          <div className="d-flex my-10 gap-10">
            {question.variant.map((answer) => {
              const isChecked = question.type === 'CHECKBOX'
                  ? (selectedAnswers ? selectedAnswers[question.id]?.includes(answer.id) || false : false)
                  : false;
              const isRadioChecked = question.type === 'RADIO'
                  ? (selectedRadioAnswer ? selectedRadioAnswer[question.id] === answer.id : false)
                  : false;

              return (
                  <div key={answer.id} className="me-10 form-check form-check-custom form-check-solid form-check-lg">
                    <input
                        name={question?.text}
                        className="form-check-input h-30px w-30px"
                        type={question.type === 'CHECKBOX' ? 'checkbox' : 'radio'}
                        id={`q${question.id}a${answer.id}`}
                        value={answer.id}
                       checked={initialChecked ? true : (question.type === 'CHECKBOX' ? isChecked : isRadioChecked)}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          if (onInputChange) {
                            onInputChange(question.id, answer.id, isChecked);
                          }
                        }}
                    />
                    <label className="form-check-label text-gray-700 fw-bold" htmlFor={`q${question.id}a${answer.id}`}>
                      {answer.name} {answer?.ball && `- ${answer?.ball}`}
                    </label>
                  </div>
              );
            })}
          </div>
        </div>
      </div>
  );
};