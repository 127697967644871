// import React from 'react';
// import {useIntl} from "react-intl";
//
// export const Loader = () => {
//   const intl = useIntl();
//   return (
//       <div
//           className="d-flex justify-content-center align-items-center"
//           style={{
//             position: 'absolute',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             backgroundColor: 'rgba(255, 255, 255, 0.8)',
//             zIndex: 9999
//           }}
//       >
//         <div className="spinner-grow text-primary" role="status">
//           <span className="visually-hidden">{intl.formatMessage({id: "Yuklanmoqda..."})}</span>
//         </div>
//       </div>
//   );
// };


import React, { FC } from 'react';
import { Spinner } from 'react-bootstrap';
import localTranslate from "../../../helpers/localTranslate";

type Props = {
  text?: string;
  color?: string;
  type?: 'border' | 'grow';
  height?: string;
  size?: 'sm' | undefined;
};

export const Loader: FC<Props> = ({
                                    text = localTranslate('Yuklanmoqda...', 'Загрузка...', 'Loading...'),
                                    color = 'primary',
                                    type = 'border',
                                    height = '100vh',
                                    size,
                                  }) => {
  return (
      <div className="d-flex justify-content-center align-items-center" style={{ height }}>
        <Spinner animation={type} role="status" variant={color} size={size && size}>
          <span className="visually-hidden">{text}</span>
        </Spinner>
        <span className="ms-3">{text}</span>
      </div>
  );
};
