import {useState} from "react";
import {KTIcon} from "../../../../_metronic/helpers";

export const PasswordInput = (props)=>{
  const [showPassword, setShowPassword] = useState<boolean>(false)
  return (
      <div className={"d-flex align-items-center  border border-secondary rounded"}>
        <input
            {...props}
            type={showPassword ? "text" : "password"}
            className={"form-control bg-transparent border-0"}
        />
        <div className={"p-3 cursor-pointer"} onClick={()=>setShowPassword(!showPassword)}>
          <KTIcon iconName={showPassword ? "eye-slash" : "eye"} className={"fs-3"}/>
        </div>
      </div>
  )
}