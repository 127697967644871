import {AppealDataSection} from "./Data";
import {AppealAboutSection} from "./About";
import {AppealPedSection} from "./Ped";

const appealNew = {
  AppealAboutSection,
  AppealPedSection,
  AppealDataSection,
}

export default appealNew