export const BASE_URL = "https://ommalashtirish.uz/backend/api/v2"
export const BASE_URL_MEDIA = "https://ommalashtirish.uz/backend/"
export const TELEGRAM_BOT_URL = "https://t.me/ommalashtirishuzbot?start=start"
export const TELEGRAM_GROUP_URL = "https://t.me/ommalashyordam"
export const CREATOR_URL = "https://felix-its.uz/"
export const ERP_MAKTAB_URL = "https://erp.maktab.uz/"
// export const BASE_URL = "https://aff8-213-230-76-74.ngrok-free.app/backend/api/v2/"

export const CONFIRM = "/auth/confirm/"
export const CONFIRM_RESET = "/auth/confirm/reset/"
export const ERP_REGISTER = "/auth/erp_register/"
export const RESEND = "/auth/resend/"
export const GET_ME = "/auth/me/"
export const RESET_PASSWORD = "/auth/reset/password/"
export const CHECK_USER_AUTH = "/auth/check/user/"
export const TOKEN = "/auth/token/"
export const TOKEN_REFRESH = "/auth/token/refresh/"
export const TOKEN_VERIFY = "/auth/token/verify"
export const REGISTER = "/auth/register/"
export const EDIT_PROFILE = "/auth/user/edit/"
export const CHANGE_NAME = "/auth/change-name/"
export const CHANGE_PASSWORD = "/auth/change-password/"
export const AUTH_SESSIONS = "/auth/sessions/"

export const ADDRESS = "/address/"
export const APPEALS = "/appeals/"
export const APPEAL_INFO = "/appeal/info/"
export const APPEAL_CREATE = "/appeal/create/"
export const APPEAL_ANSWER = "/appeal/answer/"
export const MODERATOR_APPEAL_ANSWER = "/moderator/appeal/answer/"
export const APPEAL_STATUS = "/appeal/status/"
export const REPORTS_STATUS = "/reports/status/"
export const ADDRESS_DISTRICT = "/address/districts/"
export const ADDRESS_REGIONS = "/address/regions/"
export const ADDRESS_SCIENCES = "/address/science/"
export const DISTRICTS = "/districts/"
export const GENERATE_CERTIFICATES = "/certificate/generate_certificates/"
export const GET_OTP = "/get-otp/"
export const GET_CERTIFICATE = "/certificate/get_certificate/"
export const ORGANIZATION = "/organization/"
export const POSTS = "/posts/"
export const APPEAL_QUESTIONS = "/appeal/questions/"
export const MODERATOR_QUESTIONS = "/moderator/questions/"
export const MODERATOR_REPORT_CHANGE_NAME = "/moderator/change-name/"
export const REPORT = "/report/"
export const MY_REPORT = "/report/my-report/"
export const SCIENCE = "/sicence/"
export const SCIENCES = "/sicences/"
export const METHODS = "/methods/"
export const MODERATOR_APPEALS_EXPORT = "/moderator/appeals/export/"
export const MODERATOR_APPEALS = "/moderator/appeal/"
export const MODERATOR_APPEAL_UPDATE = "/moderator/appeal/update/"
export const MODERATOR_STATICTICS = "/moderator/statistics/"
export const MODERATOR_MODERATORS = "/moderator/moderators/"
export const APPEAL_ACADEMIC_YEAR = "/appeal/academic_year/"
export const MODERATOR_FILTER_REGIONS = "/moderator/filter/regions/"
export const MODERATOR_FILTER_DISTRICTS = "/moderator/filter/districts/"
export const MODERATOR_FILTER_USERS = "/moderator/filter/users"
export const MODERATOR_FILTER_SCIENCE = "/moderator/filter/science"
export const NOTIFICATIONS = "/notifications/"
export const EXPORT_TASK = "/export/task/"
export const MODERATOR_REPUBLIC_RATE = "/moderator/appeal/republic/rate/"
export const CONTROL_SETTINGS = "/settings"

// <-- LANDING PAGE URLS -->
export const LANDING_NAVBARS = "/home/navbars/"
export const LANDING_MAIN_PAGES = "/home/main-pages/"
export const LANDING_APPEALS_STATISTICS = "/home/appeals/statistics/"
export const LANDING_REGULATORY_DOCUMENTS = "/home/regulatory-documents/"
export const LANDING_POPULARIZATION_PROCEDURES = "/home/popularization-procedures/"
export const LANDING_METHODOLOGICAL_PRODUCTS = "/home/methodological-products/"
export const LANDING_LIMITED_POPULAR_APPEALS = "/home/limited_popular_appeals/"
export const LANDING_FOOTERS = "/home/footers/"
export const LANDING_POPULAR_APPEALS = "/home/popular_appeals/"
