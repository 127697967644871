import { languages } from "./languages"
import { authPages, teacherPages, methodistPages } from "./pages"
import {queryCacheOptions} from "./cacheOptions";
import {tableOptions} from "./tableOptions";
import {faq} from "./faq";

export const mock = {
  languages,
  authPages,
  teacherPages,
  methodistPages,
  queryCacheOptions,
  tableOptions,
  faq
}
