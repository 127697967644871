/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {KTIcon,} from '../../../helpers'
import {updateNotificationStatus} from "../../../../app/api/_requests";
import formatDateDDMMYYY from "../../../../app/helpers/formatDateDDMMYYY";
import {useIntl} from "react-intl";

interface Notification {
  id: number;
  title: string;
  text: string;
  created_at: string;
  updated_at: string;
  is_read: boolean;
}

interface HeaderNotificationsMenuProps {
  notifications: Notification[];
  refetch: () => void;
}

const HeaderNotificationsMenu: FC<HeaderNotificationsMenuProps> = ({notifications, refetch}) => {
  const intl = useIntl();
  const handleReadNotification = async (id: number) => {
    try {
      await updateNotificationStatus(id);
      await refetch();
    }
    catch (error) {}
  }

  return (
      <>
            <div
                className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
                data-kt-menu='true'
            >
        {notifications?.length > 0 ? (
              <div className='scroll-y mh-325px my-5 px-8'>
                {notifications.map((alert, index) => (
                    <div
                        key={`alert${index}`}
                        className='d-flex flex-stack py-4 position-relative'
                        onClick={() => !alert.is_read && handleReadNotification(alert?.id)}
                    >
                      <div className='d-flex'>
                        <div className='symbol symbol-35px me-4'>
                    <span className={clsx('symbol-label', 'bg-light-success')}>
                      <KTIcon iconName={'sms'} className='fs-2 text-success' />
                    </span>
                        </div>

                        <div className='mb-0 me-2'>
                          <a className='fs-6 text-gray-800 text-hover-primary fw-bolder cursor-pointer'>
                            {alert.title}
                          </a>
                          <div className='text-gray-400 fs-7'>
                            <p>{alert.text}</p>
                            <span>{formatDateDDMMYYY(alert.created_at)}</span>
                          </div>
                        </div>
                      </div>
                      {!alert.is_read && (
                          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute top-50 translate-middle end-0 animation-blink'></span>
                      )}
                    </div>
                ))}
              </div>
        ) : <p className={"text-dark text-center pt-5 user-select-none text-dark"}>{intl.formatMessage({id: "Bildirishnomalar mavjud emas"})}</p>}
            </div>
      </>
  )
}

export {HeaderNotificationsMenu}
