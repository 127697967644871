import {AppealsPage} from "./Appeals";
import {AppealViewPage} from "./AppealView";
import {ReportsPage} from "./Reports";
import {ReportViewPage} from "./ReportView";
import {MethodistsPage} from "./Methodists";
import {DVEvaluationPage} from "./DVEvaluation";
import {ReportChangeNameViewPage} from "./ReportChangeNameView";
import {MethodistViewPage} from "./MethodistView";
import {MethodistNewPage} from "./MethodistNew";
import {StatisticsPage} from "./Statistics";

const admin = {
  AppealsPage,
  AppealViewPage,
  ReportsPage,
  ReportViewPage,
  MethodistsPage,
  DVEvaluationPage,
  ReportChangeNameViewPage,
  MethodistViewPage,
  MethodistNewPage,
  StatisticsPage
}

export default admin