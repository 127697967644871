import localTranslate from "./localTranslate";

const checkStatus = (statusText: string, type?: "text" | "reverseText") => {
  const successStatus = [
    "RECOMENDATION_SCHOOL",
    "RECOMENDATION_REGION",
    "RECOMENDATION_REPUBLIC",
    "RECOMENDATION_DISTRICT",
    "REGION_RECOMENDATION_REPUBLIC",
    "RATED",
    "CONFIRMED",
    "APPROVED",
      "UNBLOCKED"
  ];
  const dangerStatus = ["CANCELLED", "CANCELED", "REJECTED", "BLOCKED"];
  const warningStatus = [
    "PENDING",
    "SELF_ASSESSED",
    "EVALUATION",
  ];

  if (type === "text") {
    switch (statusText) {
      case "PENDING":
        return "Kutilmoqda";
      case "SELF_ASSESSED":
        return "O'zini Baholagan";
      case "EVALUATION":
        return "Baholanmoqda";
      case "RECOMENDATION_SCHOOL":
        return "Maktabga tavsiya etilgan"
      case "RECOMENDATION_REGION":
        return "Viloyatga tavsiya etilgan"
      case "RECOMENDATION_REPUBLIC":
        return "Respublikaga tavsiya etilgan"
      case "RECOMENDATION_DISTRICT":
        return "Tumanga tavsiya etilgan"
      case "REGION_RECOMENDATION_REPUBLIC":
        return "Viloyatda ommalashib Respublikaga tavsiya etilgan"
      case "RATED":
        return "Baholangan"
      case "CONFIRMED":
        return "Javob berilgan"
      case "CANCELLED":
        return "Rad etilgan"
      case "CANCELED":
        return "Rad etilgan"
      case "APPROVED":
        return "Bajarildi"
      case "REJECTED":
        return "Bajarilmadi"
      case "BLOCKED":
        return "Bloklangan"
      case "UNBLOCKED":
        return "Bloklanmagan"
      default:
        return "Noma'lum status"
    }
  }
  if (type === "reverseText") {
    switch (statusText) {
      case "Kutilmoqda":
        return "PENDING";
      case "O'zini Baholagan":
        return "SELF_ASSESSED";
      case "Baholanmoqda":
        return "EVALUATION";
      case "Maktabga tavsiya etilgan":
        return "RECOMENDATION_SCHOOL";
      case "Viloyatga tavsiya etilgan":
        return "RECOMENDATION_REGION";
      case "Respublikaga tavsiya etilgan":
        return "RECOMENDATION_REPUBLIC";
      case "Tumanga tavsiya etilgan":
        return "RECOMENDATION_DISTRICT";
      case "Viloyatda ommalashib Respublikaga tavsiya etilgan":
        return "REGION_RECOMENDATION_REPUBLIC";
      case "Baholangan":
        return "RATED";
      case "Javob berilgan":
        return "CONFIRMED";
      case "Rad etilgan":
        return "CANCELLED";
      case "Bajarildi":
        return "APPROVED"
      case "Bajarilmadi":
        return "REJECTED"
      case "Bloklangan":
        return "BLOCKED"
      case "Bloklanmagan":
        return "UNBLOCKED"
      default:
        return "UNKNOWN_STATUS";
    }
  } else {
    if (successStatus.includes(statusText)) {
      return "success";
    } else if (dangerStatus.includes(statusText)) {
      return "danger";
    } else if (warningStatus.includes(statusText)) {
      return "warning";
    } else {
      return "unknown";
    }
  }
};

export default checkStatus;
