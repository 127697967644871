import clsx from "clsx";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { ThemeModeComponent } from "../../../assets/ts/layout";
import { ThemeModeType, useThemeMode } from "../theme-mode/ThemeModeProvider";
import { setLanguage, useLang } from "../../../i18n/Metronici18n";
import { mock } from "../../../../app/mock";

// const languages = [
//   {
//     lang: "en",
//     name: "English",
//     flag: toAbsoluteUrl("/media/flags/united-states.svg"),
//   },
//   {
//     lang: "zh",
//     name: "Mandarin",
//     flag: toAbsoluteUrl("/media/flags/china.svg"),
//   },
//   {
//     lang: "es",
//     name: "Spanish",
//     flag: toAbsoluteUrl("/media/flags/spain.svg"),
//   },
//   {
//     lang: "ja",
//     name: "Japanese",
//     flag: toAbsoluteUrl("/media/flags/japan.svg"),
//   },
//   {
//     lang: "de",
//     name: "German",
//     flag: toAbsoluteUrl("/media/flags/germany.svg"),
//   },
//   {
//     lang: "fr",
//     name: "French",
//     flag: toAbsoluteUrl("/media/flags/france.svg"),
//   },
// ];

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string;
  toggleBtnIconClass?: string;
  menuPlacement?: string;
  menuTrigger?: string;
};

const systemMode = ThemeModeComponent.getSystemMode() as "light" | "dark";

const LanguageSwitcher = ({
  toggleBtnClass = "",
  toggleBtnIconClass = "fs-1",
  menuPlacement = "bottom-end",
  menuTrigger = "{default: 'click'}",
}: Props) => {
  const { mode, menuMode, updateMode, updateMenuMode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;
  const switchMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode);
    updateMode(_mode);
  };
  const lang = useLang();
  const currentLanguage = mock.languages.find((x) => x.lang === lang);

  return (
    <>
      {/* begin::Menu toggle */}
      <a
        href="#"
        className={clsx("btn btn-icon", toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach="parent"
        data-kt-menu-placement={menuPlacement}
      >
        <img
          className={clsx("w-15px h-15px rounded-1", toggleBtnIconClass)}
          src={currentLanguage?.flag}
          alt="metronic"
        />
      </a>
      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px"
        data-kt-menu="true"
      >

        {/* <div className="menu-sub menu-sub-dropdown w-175px py-4"> */}
          {mock.languages.map((l) => (
            <div
              className="menu-item px-3"
              key={l.lang}
              onClick={() => {
                setLanguage(l.lang);
              }}
            >
              <a
                href="#"
                className={clsx("menu-link d-flex px-5", {
                  active: l.lang === currentLanguage?.lang,
                })}
              >
                <span className="symbol symbol-20px me-4">
                  <img className="rounded-1" src={l.flag} alt="metronic" />
                </span>
                {l.name}
              </a>
            </div>
          ))}
        {/* </div> */}
      </div>
      {/* end::Menu */}
    </>
  );
};

export { LanguageSwitcher };
