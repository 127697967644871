import React, { FC, useState } from 'react';
import {  Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { PageLink, PageTitle } from '../../components/layout/core';
import { Dropzone } from '../../components/common';
import { useAuth } from "../../components/layout/auth";
import { phoneFormat } from "../../helpers";
import { postTeacherReport } from "../../api/_requests";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../context/NotificationContext";
import { URL_TEACHER_REPORTS } from "../../mock/page_urls";

const ReportNewPage: FC = () => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  const [fileDrop, setFileDrop] = useState<File | null>(null);

  const validationSchema = Yup.object({
    subject: Yup.string().required(intl.formatMessage({ id: 'Mavzu majburiy' })),
    additionalNotes: Yup.string()
        .required(intl.formatMessage({ id: 'Qo’shimcha izoh majburiy' }))
        .max(2000, intl.formatMessage({ id: "Qo’shimcha izoh 2000 belgidan oshmasligi kerak" })),
    additionalPhoneNumber: Yup.string()
        .matches(/^\+998 \d{2}-\d{3}-\d{2}-\d{2}$/, intl.formatMessage({ id: 'Telefon raqam formati xato' }))
        .nullable(),
    file: Yup.mixed().required(intl.formatMessage({ id: 'Ariza fayli majburiy' })),
  });

  return (
      <Formik
          initialValues={{
            subject: '',
            phoneNumber: currentUser ? phoneFormat(currentUser?.data.phone) : '',
            additionalPhoneNumber: '',
            additionalNotes: '',
            file: null,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              const res = await postTeacherReport(
                  values.additionalNotes,
                  values.subject,
                  phoneFormat(values.phoneNumber, 'toNumber'),
                  `${currentUser?.data?.address?.region?.name} - ${currentUser?.data?.address?.district?.name} - ${currentUser?.data?.address?.organization?.name}`,
                  values.file
              );

              if (res.status === 201) {
                showNotification(intl.formatMessage({ id: 'Shikoyatingiz muvaffaqiyatli yuborildi' }), '', 'success');
                navigate(URL_TEACHER_REPORTS);
              }
            } catch (error: any) {
              showNotification(error?.data?.data.message, '', 'error');
              navigate(URL_TEACHER_REPORTS);
            }
          }}
      >
        {({ setFieldValue, errors, touched, values }) => (
            <>
              <PageTitle breadcrumbs={[
                { title: intl.formatMessage({id: 'Shikoyat yuborish'}), path: '/', isSeparator: false, isActive: false }
              ]} />
              <div className="px-10">
                <div className="card rounded-4 border border-gray-200 p-5 p-md-10 p-lg-20 pb-0 pt-1">
                  <div className="p-5 p-md-10 p-lg-10">
                    <div className="mb-20">
                      <h1 className="fw-bold">{intl.formatMessage({ id: 'Ma’lumotlarni kiriting' })}</h1>
                      <p className="text-gray-500 fw-bold">{intl.formatMessage({ id: 'Shikoyat yuborish uchun ma’lumotlarni kiriting' })}</p>
                    </div>
                    <Form>
                      <div className="mb-5">
                        <label className="form-label">
                          {intl.formatMessage({ id: 'Mavzu' })} <span className="text-danger fs-3">*</span>
                        </label>
                        <Field
                            type="text"
                            name="subject"
                            className={`form-control ${touched.subject && errors.subject ? 'is-invalid' : ''}`}
                            placeholder={intl.formatMessage({ id: 'Shikoyat mavzusini kiriting' })}
                        />
                        <ErrorMessage name="subject" component="div" className="invalid-feedback" />
                      </div>

                      <div className="mb-5">
                        <label className="form-label">{intl.formatMessage({ id: 'Telefon raqam' })}</label>
                        <input
                            type="text"
                            name="phoneNumber"
                            className="form-control"
                            placeholder="+998(90) 123-45-67"
                            disabled
                            value={values.phoneNumber}
                        />
                      </div>

                      <div className="mb-5">
                        <label className="form-label">{intl.formatMessage({ id: 'Qo’shimcha telefon raqam' })}</label>
                        <Field
                            type="text"
                            name="additionalPhoneNumber"
                            className={`form-control ${touched.additionalPhoneNumber && errors.additionalPhoneNumber ? 'is-invalid' : ''}`}
                            placeholder={intl.formatMessage({ id: 'Qo’shimcha telefon raqam' })}
                            onChange={(e) => setFieldValue("additionalPhoneNumber", phoneFormat(e.target.value))}
                        />
                        <ErrorMessage name="additionalPhoneNumber" component="div" className="invalid-feedback" />
                      </div>

                      <div className="mb-5">
                        <label className="form-label">
                          {intl.formatMessage({ id: "Ariza matni (PDF ko’rinishida)" })} <span className="text-danger fs-3">*</span>
                        </label>
                        <Dropzone
                            title={intl.formatMessage({
                              id: "Iltimos shikoyat arizangizni oq qo'goz(A4)da yozib bu yerga pdf ko'rinishida yuklang. Aks holda arizangiz ko'rib chiqilmasligi mumkin.",
                            })}
                            fileDrop={values.file}
                            setFileDrop={(file) => setFieldValue("file", file)}
                        />
                        <ErrorMessage name="file" component="div" className="invalid-feedback" />
                      </div>

                      <div className="mb-5">
                        <label className="form-label">
                          {intl.formatMessage({ id: 'Qo’shimcha izoh' })} <span className="text-danger fs-3">*</span>
                        </label>
                        <Field
                            as="textarea"
                            name="additionalNotes"
                            className={`form-control ${touched.additionalNotes && errors.additionalNotes ? 'is-invalid' : ''}`}
                            placeholder={intl.formatMessage({ id: 'Matnini kiriting' })}
                            rows={3}
                        />
                        <ErrorMessage name="additionalNotes" component="div" className="invalid-feedback" />
                      </div>

                      <div className="text-end">
                        <button type="submit" className="btn btn-primary">
                          {intl.formatMessage({ id: 'Saqlash' })}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </>
        )}
      </Formik>
  );
};

export { ReportNewPage };
