import React from 'react';
import {Form, Formik, FormikHelpers} from 'formik';
import * as Yup from 'yup';
import {PageLink, PageTitle} from "../../components/layout/core";
import {postChangePassword} from "../../api/_requests";
import {useNotification} from "../../context/NotificationContext";
import {AxiosError} from "axios";
import {PROFILE_OVERVIEW} from "../../mock/page_urls";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import {PasswordInput} from "../../components/common";

interface FormValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const ChangePasswordPage = () => {
  const {showNotification} = useNotification();
  const navigate = useNavigate();
  const intl = useIntl();

  const validationSchema = Yup.object<FormValues>({
    oldPassword: Yup.string()
        .min(8, intl.formatMessage({id: "Minimal 8 ta belgi"}))
        // .matches(/[0-9]/, intl.formatMessage({id: "Kamida bitta raqam bo‘lishi majburiy"}))
        // .matches(/[a-z]/, intl.formatMessage({id: "Kamida bitta kichkina harf bo‘lishi majburiy"}))
        .required(intl.formatMessage({id: 'Eski parolni kiriting'})),
    newPassword: Yup.string()
        .min(8, intl.formatMessage({id: "Minimal 8 ta belgi"}))
        .required(intl.formatMessage({id: 'Yangi parolni kiriting'})),
        // .matches(/[0-9]/, intl.formatMessage({id: "Kamida bitta raqam bo‘lishi majburiy"}))
        // .matches(/[a-z]/, intl.formatMessage({id: "Kamida bitta kichkina harf bo‘lishi majburiy"})),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], intl.formatMessage({id: 'Parollar mos kelmaydi'}))
        .required(intl.formatMessage({id: 'Parolni tasdiqlang'})),
  });

  const breadcrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: "Parolni o’zgartirish"}),
      path: "/",
      isSeparator: false,
      isActive: false,
    },
  ];

  const handleSubmit = async (
      values: FormValues,
      { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      const res = await postChangePassword({
        old_password: values.oldPassword,
        new_password: values.newPassword,
        new_password2: values.confirmPassword,
      });

      if (res) {
        if (res.status === 200) {
          showNotification(res.data.message, '', 'success');
          navigate(PROFILE_OVERVIEW);
        } else {
          showNotification(res.data.message, '', 'error');
        }
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        showNotification(error.response?.data?.message || intl.formatMessage({id: 'An error occurred'}), '', 'error');
      } else if (error instanceof Error) {
        showNotification(error.message, '', 'error');
      } else {
        showNotification(error.data.data.message[0], '', 'error');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
      <>
        <PageTitle
            description={intl.formatMessage({id: "Akaunt parolini o’zgartirish"})}
            breadcrumbs={breadcrumbs}
        />

        <div className="px-10">
          <div className="py-10 px-10 card rounded-4 border border-gray-200">
            <Formik
                initialValues={{
                  oldPassword: '',
                  newPassword: '',
                  confirmPassword: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
              {({isSubmitting, handleBlur,  setFieldValue, errors, touched}) => (
                  <Form style={{width: '60%', margin: 'auto'}}>
                    <div className="mb-5">
                      <label className="form-label">{intl.formatMessage({id: 'Eski parol'})}</label>
                      <PasswordInput
                          type="password"
                          name="oldPassword"
                          className="form-control"
                          onBlur={handleBlur}
                          placeholder={intl.formatMessage({id: 'Eski parolni kiriting'})}
                          onChange={(e) => setFieldValue('oldPassword', e.target.value)}
                      />
                      {touched.oldPassword && errors.oldPassword && (
                          <div className="text-danger">{errors.oldPassword}</div>
                      )}
                    </div>
                    <div className="mb-5">
                      <label className="form-label">{intl.formatMessage({id: 'Yangi parol'})}</label>
                      <PasswordInput
                          type="password"
                          name="newPassword"
                          className="form-control"
                          onBlur={handleBlur}
                          placeholder={intl.formatMessage({id: 'Yangi parolni kiriting'})}
                          onChange={(e) => setFieldValue('newPassword', e.target.value)}
                      />
                      {touched.newPassword && errors.newPassword && (
                          <div className="text-danger">{errors.newPassword}</div>
                      )}
                    </div>
                    <div className="mb-5">
                      <label className="form-label">{intl.formatMessage({id: 'Yangi parolni tasdiqlang'})}</label>
                      <PasswordInput
                          type="password"
                          name="confirmPassword"
                          className="form-control"
                          onBlur={handleBlur}
                          placeholder={intl.formatMessage({id: 'Parolni tasdiqlang'})}
                          onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
                      />
                      {touched.confirmPassword && errors.confirmPassword && (
                          <div className="text-danger">{errors.confirmPassword}</div>
                      )}
                    </div>

                    <div className="text-center">
                      <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                        {isSubmitting ? intl.formatMessage({id: 'Yangilanmoqda...'}) : intl.formatMessage({id: 'Parolni o’zgartirish'})}
                      </button>
                    </div>
                  </Form>
              )}
            </Formik>
          </div>
        </div>
      </>
  );
};
