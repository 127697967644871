import { useIntl } from "react-intl";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useAuth } from "../../../auth";
import { Roles } from "../../../../../types/role";
import {
  adminSidebarItems,
  methodistSidebarItems,
  teacherSidebarItems,
} from "../../../../../mock/sidebar";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { KTIcon } from "../../../../../../_metronic/helpers";
import React from "react";

const useSidebarItems = () => {
  const { currentUser } = useAuth();

  switch (currentUser?.data.role) {
    case Roles.METHODIST:
      return methodistSidebarItems;
    case Roles.TEACHER:
      return teacherSidebarItems;
    case Roles.SUPERADMIN:
      return adminSidebarItems;

    default:
      return [];
  }
};

const SidebarMenuMain = () => {
  const intl = useIntl();
  const routes = useSidebarItems();
  const filterOnlyVisible = routes.filter((e) => e.name);
  const { logout } = useAuth();

  return (
    <>
      {filterOnlyVisible.map((item, key) => {
        return (
          <React.Fragment key={key}>
            {item.blockTitle ? (
              <>
                <div className="menu-item" key={key}>
                  <div className="menu-content pt-8 pb-2">
                    <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                      {item.blockTitle}
                    </span>
                  </div>
                </div>
                <SidebarMenuItem
                  to={item.path}
                  icon={item.icon}
                  title={intl.formatMessage({ id: item.name })}
                />
              </>
            ) : (
              <SidebarMenuItem
                to={item.path}
                icon={item.icon}
                title={intl.formatMessage({ id: item.name })}
              />
            )}
          </React.Fragment>
        );
      })}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            {intl.formatMessage({id: "Chiqish"})}
          </span>
        </div>
      </div>
      <div className="menu-item">
        <Link
          onClick={logout}
          className={clsx("menu-link without-sub")}
          to={"/auth/login"}
        >
          <span className="menu-icon">
            <KTIcon iconName={"entrance-right"} className="fs-2" />
          </span>
          <span className="menu-title fw-bold">{intl.formatMessage({id: "Chiqish"})}</span>
        </Link>
      </div>
    </>
  );
};

export { SidebarMenuMain };
