import * as Yup from "yup";
import React, {FC} from 'react';
import {postRepublicRatingBall} from "../../../../api/_requests";
import {BASE_URL_MEDIA} from "../../../../api/URLs";
import {useNotification} from "../../../../context/NotificationContext";
import {useNavigate, useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {Field, Form, Formik} from "formik";
import {URL_ADMIN_APPEALS} from "../../../../mock/page_urls";
import {Question} from "../../../common";
import {transformAndGroupByCategory} from "../../../../helpers";
import {CategoryWithQuestions} from "../../../../types";

interface IProps {
  data: any;
}

const CheckMyselfSection: FC<IProps> = ({data}) => {
  const intl = useIntl();
  const {id} = useParams<string>();
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const handleAddRatingSubmit = async (values) => {
    try {
      const res = await postRepublicRatingBall(Number(id), values)
      if (res && res.status === 200) {
        showNotification(res?.data.message, "", "success")
        navigate(URL_ADMIN_APPEALS)
      }
    }catch(err:any){
      showNotification(err?.response?.data?.message || intl.formatMessage({id: "Server xatolik!"}), "", "error")
    }
  }
  const addRatingSchema = Yup.object().shape({
    country_ball: Yup.number().required(intl.formatMessage({id: "Ballni kiriting"})).min(0, intl.formatMessage({id: "Ball minimal 0"})).max(20, intl.formatMessage({id: "Ball maximal 20"}))
  })

  const questionsData = transformAndGroupByCategory(data?.data.answers) as CategoryWithQuestions[]

  return (
      <div className="row row-gap-5 align-items-start">
        <div className="col-md-4 card rounded-4 border border-gray-200 p-10 position-sticky start-0 h-100" style={{top: "80px"}}>
          <iframe
              style={{ border: "none", overflow: "hidden" }}
              width="100%"
              height="500px"
              src={`${BASE_URL_MEDIA + data?.data?.file}`}
              allowFullScreen={true}
          >
          </iframe>
          <div className={"mt-10"}>
            <Formik enableReinitialize validationSchema={addRatingSchema} initialValues={{country_ball: data?.country_ball}} onSubmit={handleAddRatingSubmit}>
              {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                  <Form>
                    <div className={"d-flex gap-3 align-items-end"}>
                      <div className={"w-100"}>
                        <label htmlFor="country_ball" className={"form-label"}>{intl.formatMessage({id: "Ball"})}</label>
                        <Field min={0} type={"number"} placeholder={intl.formatMessage({id: "Ball"})} name={"country_ball"}
                               value={values.country_ball}
                               className={"form-control form-control-solid"}/>
                      </div>
                      <button className={"btn btn-light-success"}
                              disabled={isSubmitting}>{isSubmitting ? intl.formatMessage({id: "Baholanmoqda..."}) : intl.formatMessage({id: "Baholash"})}</button>
                    </div>
                    {errors.country_ball && touched.country_ball && <div className={"text-danger"}>{String(errors.country_ball)}</div>}
                  </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="col-md-8 overflow-auto">
          <form>
            <div className="d-flex flex-column gap-3">
              <div className="card rounded-4 border border-gray-200 p-10">
                <h4 className="text-gray-500">{questionsData[0]?.name}</h4>
                <div className="d-flex flex-column">
                  {questionsData[0]?.question.map((question, index) => (
                      <Question
                          key={question.id}
                          question={question}
                          index={index}
                          initialChecked={true}
                      />
                  ))}
                </div>
              </div>

              <div className="card rounded-4 border border-gray-200 p-10">
                <h4 className="text-gray-500">{questionsData[1]?.name}</h4>
                <div className="d-flex flex-column">
                  {questionsData[1]?.question.map((question, index) => (
                      <Question
                          key={question.id}
                          question={question}
                          index={index}
                          initialChecked={true}
                      />
                  ))}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
  );
};

export  default CheckMyselfSection