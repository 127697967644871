import { Login } from "./Login";
import { Registration } from "./Registration";
import { RestorePassword } from "./RestorePassword";
import { RegistrationJSHIR } from "./RegistrationJSHIR";

const auth = {
  Login,
  Registration,
  RestorePassword,
  RegistrationJSHIR
}

export default auth