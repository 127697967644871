import {useEffect, useRef, useState} from "react";
import * as Yup from "yup";
import {Link, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {useIntl} from "react-intl";
import {requestRestorePasswordNumber} from "app/api/_requests";
import {URL_AUTH_RESET_PASSWORD_NEW, URL_AUTH_RESET_PASSWORD_PHONE_NUMBER} from "../../../../mock/page_urls";
import {toAbsoluteUrl} from "../../../../../_metronic/helpers";
import {TELEGRAM_BOT_URL} from "../../../../api/URLs";

const initialValues = {
  otp: "",
};

const forgotPasswordSchema = Yup.object().shape({
  otp: Yup.string(),
});

export function NumberConfirm() {
  const length = 6;
  const navigate = useNavigate();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const confirmNumber = sessionStorage.getItem("flex_restore_password_phone");
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus }) => {
      setLoading(true);
      setHasErrors(undefined);
      try {
        await requestRestorePasswordNumber(
          String(confirmNumber),
          Number(values.otp)
        );
        setLoading(false);
        setHasErrors(false);
        sessionStorage.setItem("flx_restore_password_confirmed_code", values.otp);
        navigate(`/auth${URL_AUTH_RESET_PASSWORD_NEW}`);
      } catch (error:any) {
        setLoading(false);
        setHasErrors(true);
        setStatus(error?.response?.data?.message || intl.formatMessage({id: "Xato"}));
      }
    },
  });
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef<any>([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) {
      formik.setFieldValue("otp", combinedOtp);
    }

    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, length);
    const newOtp = pasteData.split("").map((char, index) => {
      if (isNaN(char)) return otp[index];
      return char;
    });

    setOtp(newOtp);
    formik.setFieldValue("otp", newOtp.join(""));

    newOtp.forEach((char, index) => {
      if (inputRefs.current[index]) {
        inputRefs.current[index].value = char;
      }
    });

    if (inputRefs.current[length - 1]) {
      inputRefs.current[length - 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1].focus();
    }
  };

  useEffect(() => {
    if (!confirmNumber) {
      navigate(`/auth${URL_AUTH_RESET_PASSWORD_PHONE_NUMBER}`);
    }
  }, [confirmNumber, navigate]);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework d-flex flex-column gap-2"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10 d-flex flex-column align-items-center gap-2">
        <img
            alt={"Phone number confirmation"}
          className="w-85px"
          src={toAbsoluteUrl("/media/svg/misc/smartphone-2.svg")}
        />
        <h1 className="text-dark fw-bolder mb-3">
          {intl.formatMessage({ id: "Telefon raqamni tasdiqlash" })}
        </h1>
        <div className="text-gray-500 fw-semibold fs-6">
          <span className="fw-bold text-gray-900">
            ********{confirmNumber?.slice(-4)}
          </span>{" "}
          {intl.formatMessage({id: "Raqamiga"})}{" "}
          <a rel="noreferrer" target={"_blank"} href={TELEGRAM_BOT_URL} className="link-primary">
            {intl.formatMessage({id: "Telegram bot"})}{" "}
          </a>
          {intl.formatMessage({id: "orqali yuborilgan"})}{" "}
          <span className="fw-bold text-gray-900">{intl.formatMessage({id: "6 xonali"})}</span> {intl.formatMessage({id: "tasdiqlash kodini kiriting"})}
        </div>
      </div>

      {hasErrors && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">
            {formik.status}
          </div>
        </div>
      )}

      <div className="fv-row mb-8 d-flex flex-row gap-6 align-items-center justify-content-center">
          <div className={"d-flex gap-3 flex-wrap justify-content-center w-100"}>
            {otp.map((value, index) => {
              return (
                  <input
                      key={index}
                      type="text"
                      ref={(input) => (inputRefs.current[index] = input)}
                      value={value}
                      onChange={(e) => handleChange(index, e)}
                      onClick={() => handleClick(index)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      onPaste={handlePaste}
                      className="border-2 fs-2x border rounded-2 border-gray-300 w-60px h-60px text-center"
                  />
              );
            })}
          </div>
        {formik.touched.otp && formik.errors.otp && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.otp}</span>
            </div>
          </div>
        )}
      </div>

      <div className="d-flex justify-content-center pb-lg-0 gap-2">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary w-100"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          <span className="indicator-label">
            {!loading && intl.formatMessage({ id: "Davom etish" })}
          </span>
          {loading && (
            <span className="indicator-progress d-block">
              {intl.formatMessage({ id: "Iltimos kuting..." })}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link
          to={`/auth${URL_AUTH_RESET_PASSWORD_PHONE_NUMBER}`}
          onClick={() => sessionStorage.removeItem("flex_restore_password_phone")}
          className="w-100"
        >
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light w-100"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {intl.formatMessage({ id: "Ortga" })}
          </button>
        </Link>{" "}
      </div>
    </form>
  );
}
