import {createRoot} from "react-dom/client";
import "./global.scss"
import {Chart, registerables} from "chart.js";
import {QueryClient, QueryClientProvider} from "react-query";
import {MetronicI18nProvider} from "./_metronic/i18n/Metronici18n";
import "./_metronic/assets/fonticon/fonticon.css";
import "./_metronic/assets/keenicons/duotone/style.css";
import "./_metronic/assets/keenicons/outline/style.css";
import "./_metronic/assets/keenicons/solid/style.css";
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/plugins.scss";
import "./_metronic/assets/sass/style.react.scss";
import {AppRoutes} from "./app/routing/AppRoutes";
import {AuthProvider} from "./app/components/layout/auth";
import {NotificationProvider} from "./app/context/NotificationContext";

Chart.register(...registerables);

const queryClient = new QueryClient();
const container = document.getElementById("root");
if (container) {
  createRoot(container).render(
      <QueryClientProvider client={queryClient}>
        <MetronicI18nProvider>
          <NotificationProvider>
            <AuthProvider>
              {/* <RegionsProvider> */}
                <AppRoutes/>
              {/* </RegionsProvider> */}
            </AuthProvider>
          </NotificationProvider>
        </MetronicI18nProvider>
      </QueryClientProvider>
  );
}
