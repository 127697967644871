import React from "react";

interface TagProps {
  label: string;
  onRemove: () => void;
}

const Tag: React.FC<TagProps> = ({label, onRemove}) => {
  return (
      <div className="tag badge badge-light-success cursor-pointer">
        {label}
      </div>
  );
};

export default  Tag
