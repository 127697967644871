import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Components from "app/components";

export function RestorePassword() {
  const auth = Components.section.auth.restorePassword
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route path="phone-number" element={<auth.PhoneNumber />} />
        <Route path="number-confirm" element={<auth.NumberConfirm />} />
        <Route path="new-password" element={<auth.NewPassword />} />
        <Route
          index
          element={<Navigate to={"/auth/reset-password/phone-number"} />}
        />
      </Route>
    </Routes>
  );
}
