import { useIllustrationsPath } from "_metronic/helpers";
import { FC } from "react";
import { useIntl } from "react-intl";
import "./styles.scss"

interface Props {
  title?: string;
}

export const Empty: FC<Props> = ({ title = "Ma'lumotlar topilmadi" }) => {
  const { formatMessage } = useIntl();
  return (
    <div className="user-select-none w-100 h-100 d-flex align-items-center justify-content-center flex-column gap-3 empty-component">
      <img
        src={useIllustrationsPath("20.png")}
        alt="20.png"
        className="mw-100 mh-300px"
      />
      <p className="fs-4 text-muted">{formatMessage({ id: title })}</p>
    </div>
  );
};
