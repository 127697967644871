import React from 'react';
import {Header} from "../../components/layout/landing/header";
import Components from "../../components";
import {useQuery} from "react-query";
import {getLandingHeaders} from "../../api/_requests";
import {queryCacheOptions} from "../../mock/cacheOptions";
import {useIntl} from "react-intl";
import {Helmet} from "react-helmet";
import {KTIcon} from "../../../_metronic/helpers";
import {TELEGRAM_GROUP_URL} from "../../api/URLs";

export const HomePage = () => {
  const intl = useIntl();
  const {data: headerData, isLoading: headerIsLoading, isSuccess: headerIsSuccess} = useQuery(
      ["getLandingHeaders", "home"],
      () => getLandingHeaders("home"),
      {...queryCacheOptions}
  );

  if (headerIsLoading || !headerIsSuccess) {
    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              zIndex: 9999
            }}
        >
          <div className="spinner-grow text-primary" role="status">
            <span className="visually-hidden">{intl.formatMessage({id: "Yuklanmoqda..."})}</span>
          </div>
        </div>
    );
  }


  return (
      <div className="w-100 p-0 m-0 text-dark">
        <Helmet>
          <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
          <title>
            Ommalashtirish.uz
            - {headerData?.results[0]?.title || 'Xalq ta’limi vazirligi huzuridagi Respublika ta’lim markazi yagona platformasi pedagoglarning ilg‘or ish - tajribalarni ommalashtirishga xizmat qiladi.'}
          </title>
          <meta charSet="UTF-8"/>
          <meta
              name="title"
              content={`Ommalashtirish.uz - ${headerData?.results[0]?.title || 'Xalq ta’limi vazirligi huzuridagi Respublika ta’lim markazi yagona platformasi pedagoglarning ilg‘or ish - tajribalarni ommalashtirishga xizmat qiladi.'}`}
          />
          <meta
              name="description"
              content={headerData?.results[0]?.description || 'Default description here'}
          />
          <meta
              name="keywords"
              content="Ommalashtirish, ommalashtirish ishlari, Xalq ta’limi ommalashtirish, Respublika ta’lim markazi ommalashtirish, ommalashtirishga ish jo'natish, ishlarni ommalashtirish, pedagoglar ishlarini ommalashtirish, Xalq ta’limi respublikada ommalashtirish, viloyatda ommalashtirish, ommalashtirish tumanda, pedagoglar ishlarini Respublikada ommalashtirish, pedagoglarning ishlarini viloyatda ommalashtirish, pedagoglarning ishlarini tumanda ommalashtirish, Ommalashtirish, Omma, Omma ish, Ommalash ishi, ommalashtirish, omma, ommalash ishi, omma ish, Oммалаш, Оммалаштириш, Омма иши, Оммалаштириш, оммалаштириш ишлари, Халқ таълими оммалаштириш, Республика таълим маркази оммалаштириш, оммалаштиришга иш жўнатиш, ишларни оммалаштириш, педагоглар ишларини оммалаштириш, Халқ таълими республикада оммалаштириш, вилоятда оммалаштириш, оммалаштириш туманда, педагоглар ишларини Республикада оммалаштириш, педагогларнинг ишларини вилоятда оммалаштириш, педагогларнинг ишларини туманда оммалаштириш"
          />
          <meta name="author" content="Felix-ITS LLC"/>
          <meta name="yandex-verification" content="7f3e2df6132fbb50"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta
              property="og:title"
              content={`Ommalashtirish.uz - ${headerData?.results[0]?.title || 'Xalq ta’limi vazirligi huzuridagi Respublika ta’lim markazi yagona platformasi pedagoglarning ilg‘or ish - tajribalarni ommalashtirishga xizmat qiladi.'}`}
          />
          <meta property="og:type" content="website"/>
          <meta property="og:locale" content="uz_UZ"/>
          <meta property="og:url" content="https://ommalashtirish.uz/"/>
          <meta property="og:image" content="favicon.png"/>
          <meta property="og:image:type" content="image/jpeg"/>
          <meta property="og:image:width" content="300"/>
          <meta property="og:image:height" content="300"/>
          <meta property="og:image:alt" content="Лого ommalashtirish.uz"/>
          <meta property="og:site_name" content="ommalashtirish.uz"/>
          <meta
              property="og:description"
              content="Xalq ta’limi vazirligi huzuridagi Respublika ta’lim markazi yagona platformasi pedagoglarning ilg‘or ish - tajribalarni ommalashtirishga xizmat qiladi."
          />
        </Helmet>
        <Header
            style={{height: "60vh"}}
            title={headerData?.results[0]?.title}
            description={headerData?.results[0]?.description}
        >
          <div className="d-flex flex-column flex-sm-row gap-5 mt-5">
            {headerData?.results[0]?.buttons?.map((button, index) => (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    href={button?.url}
                    className={`btn btn-${button?.type === "secondary" ? "light-primary" : button?.type} ${button?.type === "secondary" && "px-20"} fw-bolder`}
                >
                  {button?.name}
                </a>
            ))}
          </div>
        </Header>

        <Components.section.landing.ResultsSection/>
        <Components.section.landing.DocumentsSection/>
        <Components.section.landing.OrderSection/>
        <Components.section.landing.MethodProductsSection/>
        <Components.section.landing.PopularWorksSection/>
        {/*<Components.section.landing.FaqSection/>*/}

        <a target={"_blank"} href={TELEGRAM_GROUP_URL} className="btn btn-icon pulse pulse-success position-fixed p-3"
           style={{bottom: "40px", right: "50px"}}>
          <KTIcon iconName={"messages"} className={"fs-5x text-success"}/>
          <span className="pulse-ring"></span>
        </a>
      </div>
  );
};
