// bu funksiya moderator belgilagan javoblarni guruhlab qaytaradi, Question componentga tushishi uchun
import {QuestionWithVariants} from "../types";

export default function transformAndGroupByCategory(answers: QuestionWithVariants[]) {
  const groupedByCategory = {};
  answers?.forEach(answer => {
    const categoryName = answer.question.category.name;
    if (!groupedByCategory[categoryName]) {
      groupedByCategory[categoryName] = {
        id: answer.question.category.id,
        name: categoryName,
        question: []
      };
    }

    groupedByCategory[categoryName].question.push({
      id: answer.question.id,
      text: answer.question.text,
      variant: answer.variant.map(v => ({
        id: v.id,
        name: v.name,
        ball: v.ball
      })),
      type: answer.question.type,
      is_active: answer.question.is_active,
      category: {
        id: answer.question.category.id,
        name: answer.question.category.name
      },
      method: [answer.question.method.name]
    });
  });

  return Object.values(groupedByCategory);
}