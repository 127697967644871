import React, {ChangeEvent, FC, useEffect} from "react";
import {useFormik} from "formik";
import {useQuery} from "react-query";
import {Link, useSearchParams} from "react-router-dom";
import {KTIcon} from "../../../../../_metronic/helpers";
import {getModeratorReportChangeName, getModeratorReports} from "../../../../api/_requests";
import Components from "../../../index";
import {Dropdown} from "../../../common/table/dropdown";
import {Empty, Loader} from "../../../common";
import {checkStatus} from "../../../../helpers";
import {mock} from "../../../../mock";
import {useIntl} from "react-intl";

const AdditionalsSection: FC = () => {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';

  const formik = useFormik({
    initialValues: {
      status: null,
      search: null,
    },
    onSubmit: (values) => {},
  });

  const {data: reportsData, refetch: refetchChangeName, isLoading} = useQuery(
      ['reports_change_name', formik.values, page],
      () => getModeratorReportChangeName({...formik.values}, page),
      {
        refetchOnWindowFocus: false,
        enabled: !!formik.values,
      }
  );

  useEffect(() => {
    refetchChangeName();
  }, [page, refetchChangeName]);

  const statusReportDataFormatted = [
    {
      id: "APPROVED",
      label: "APPROVED",
      type: 'status',
    },
    {
      id: "REJECTED",
      label: "REJECTED",
      type: 'status',
    }
  ];

  return (
      <div>
        <div className="bg-light w-100 d-flex gap-5 align-items-center p-5 border border-top-0 border-gray-200">

          <div className="col-md-1">
            <Dropdown
                options={statusReportDataFormatted}
                defaultLabel={intl.formatMessage({id: "Status"})}
                selectedLabel={formik.values.status || intl.formatMessage({id: "Status"})}
                onSelect={(value) => formik.setFieldValue('status', value === intl.formatMessage({id: "Status"}) ? null : checkStatus(value || "", "reverseText"))}
            />
          </div>
          <div className="col-md-3 d-flex align-items-center position-relative">
            <KTIcon iconName="magnifier" className="fs-1 position-absolute ms-4"/>
            <input
                type="text"
                data-kt-docs-table-filter="search"
                className="form-control w-250px ps-15"
                placeholder={intl.formatMessage({id: "Qidiruv..."})}
                name="search"
                value={formik.values.search || ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('search', e.target.value)}
                onBlur={formik.handleBlur}
            />
          </div>
        </div>

        {isLoading ? (
            <Loader height={"70vh"}/>
        ) : reportsData?.results.length > 0 ? (
            <Components.Table
                header={mock.tableOptions.adminChangeNameHeader}
                removeClassNames={true}
                forPaginationData={reportsData}
                title=" "
                body={reportsData?.results.map((d) => ({
                  ...d,
                  actions: (
                      <div className="d-flex flex-row gap-2">
                        <Link to={`/reports/change-name/${d.id}`} className="btn btn-icon btn-secondary">
                          <KTIcon iconName="eye" className="fs-1"/>
                        </Link>
                      </div>
                  ),
                }))}
                bodyKey={[...mock.tableOptions.adminChangeNameBodyKey, "actions"]}
            />
        ) : (
            <Empty title={intl.formatMessage({id: "Shikoyatlar mavjud emas!"})}/>
        )}
      </div>
  );
};

export default AdditionalsSection;
