import axios, { AxiosError, AxiosResponse } from "axios";
import { get } from "lodash";
import { BASE_URL, TOKEN_REFRESH } from "./URLs";
import {
  AUTH_LOCAL_STORAGE_KEY,
  getAuth,
  removeAuth,
} from "../components/layout/auth";
import {URL_AUTH_LOGIN} from "../mock/page_urls";

const baseURL = BASE_URL;


export const api = axios.create({
  baseURL,
});


const getLanguageFromLocalStorage = () => {
  const i18nConfig = JSON.parse(localStorage.getItem('i18nConfig') || '{}');
  return i18nConfig.selectedLang || 'uz';
};


api.interceptors.request.use((config) => {
  const language = getLanguageFromLocalStorage();
  config.headers['Accept-Language'] = language;
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const apiWithoutToken = axios.create({
  baseURL,
});



apiWithoutToken.interceptors.request.use((config) => {
  const language = getLanguageFromLocalStorage();
  config.headers['Accept-Language'] = language;
  return config;
}, (error) => {
  return Promise.reject(error);
});

class APIError {
  status: number;
  data: {
    message: string;
    status: string;
  };

  constructor(data: any, status: number) {
    this.status = status;
    this.data = data;
  }
}

const refresh = async () => {
  try {
    const response = await axios.post(`${BASE_URL}${TOKEN_REFRESH}`, {
      refresh: getAuth()?.refresh,
    });
    const newAccessToken = response.data.access;
    setHeaderToken(newAccessToken);
    const lsValue = JSON.stringify({
      access: newAccessToken,
      refresh: getAuth()?.refresh,
    });
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
    return newAccessToken;
  } catch (error) {}
};

api.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async (err: AxiosError) => {
      const data = get(err, "response");
      const status = err.response?.status || 500;

      switch (status) {
        case 401: {
          const errorMessage = get(data, "data.code");
          if (errorMessage === "user_not_found") {
            removeAuth();
            window.location.href = URL_AUTH_LOGIN;
            return Promise.reject(new APIError(data || err, 401));
          }

          try {
            const newToken = await refresh();
            if (newToken && err.config) {
              err.config.headers["Authorization"] = `Bearer ${newToken}`;
              return api.request(err.config);
            }
          } catch (refreshError) {
            removeAuth();
            window.location.href = URL_AUTH_LOGIN;
            return Promise.reject(refreshError);
          }
          break;
        }

        case 403: {
          return Promise.reject(new APIError(data || err, 403));
        }

        case 400: {
          return Promise.reject(new APIError(data || err, status));
        }

        case 404: {
          return Promise.reject(new APIError(data || err, 404));
        }

        case 409: {
          return Promise.reject(new APIError(data || err, 409));
        }

        case 422: {
          return Promise.reject(new APIError(data || err, 422));
        }

        default: {
          return Promise.reject(new APIError(data || err, 500));
        }
      }
    }
);

export const setHeaderToken = (token: string) => {
  api.defaults.headers.Authorization = `Bearer ${token}`;
};

export const removeHeaderToken = () => {
  delete api.defaults.headers.Authorization;
};
