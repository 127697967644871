/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import {Link, useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import Flatpickr from "react-flatpickr";
import {URL_AUTH_JSHIR_REGISTER_CONFIRM, URL_AUTH_LOGIN, URL_AUTH_REGISTER_INFO,} from "app/mock/page_urls";
import {useQuery} from "react-query";
import {DistrictProps} from "app/types/district";
import {getDistrict, getRegions, requestJshirRegister} from "app/api/_requests";
import phoneFormat from "app/helpers/phoneFormat";
import onlyNumber from "app/helpers/onlyNumber";
import {PasswordInput} from "../../../common";
import {ERP_MAKTAB_URL} from "../../../../api/URLs";
import {ScrollTopComponent} from "../../../../../_metronic/assets/ts/components";

// const initialValues = {
//   jshir: "30904976070014",
//   firstname: "Azizbek",
//   lastname: "Usmonov",
//   familyname: "ikki",
//   detebirth: "2000-01-01",
//   gender: "Male",
//   county: "1",
//   district: "1",
//   organization: "16 DIMI",
//   password: "12345678a",
//   changepassword: "12345678a",
//   phone: "+998 94 306-76-61",
// };


const initialValues = {
  jshir: "",
  firstname: "",
  lastname: "",
  familyname: "",
  detebirth: "",
  gender: "",
  county: "",
  district: "",
  organization: "",
  password: "",
  changepassword: "",
  phone: "",
};


export function Info() {
  const [loading, setLoading] = useState(false);
  const {data: regions} = useQuery("regions", getRegions);
  const [selectedRegion, setSelectedRegion] = useState<any>(1);
  const {data: districtId} = useQuery<DistrictProps[]>(
      ["district", selectedRegion],
      () => getDistrict(selectedRegion)
  );

  const intl = useIntl();
  const registrationSchema = Yup.object().shape({
    jshir: Yup.string()
        .length(14, intl.formatMessage({id: "Bu JSHSHIR emas"}))
        .required(intl.formatMessage({id: "JSHSHIR majburiy"})),
    firstname: Yup.string()
        .min(3, intl.formatMessage({id: "Minimal 3 ta belgi"}))
        .max(30, intl.formatMessage({id: "Maksimal 20 ta belgi"}))
        .required(intl.formatMessage({id: "Ism majburiy"})),
    lastname: Yup.string()
        .min(3, intl.formatMessage({id: "Minimal 3 ta belgi"}))
        .max(30, intl.formatMessage({id: "Maksimal 20 ta belgi"}))
        .required(intl.formatMessage({id: "Familiya majburiy"})),
    familyname: Yup.string()
        .min(3, intl.formatMessage({id: "Minimal 3 ta belgi"}))
        .max(30, intl.formatMessage({id: "Maksimal 20 ta belgi"}))
        .required(intl.formatMessage({id: "Sharif majburiy"})),
    detebirth: Yup.date()
        .required(intl.formatMessage({id: "Tug'ilgan kun majburiy"})),
    gender: Yup.string()
        .required(intl.formatMessage({id: "Jins majburiy"})),
    phone: Yup.string()
        .length(17, intl.formatMessage({id: "Telefon raqam emas"}))
        .required(intl.formatMessage({id: "Telefon raqam majburiy"})),
    county: Yup.string()
        .required(intl.formatMessage({id: "Viloyat majburiy"})),
    district: Yup.string()
        .required(intl.formatMessage({id: "Tuman/Shahar majburiy"})),
    organization: Yup.string()
        .required(intl.formatMessage({id: "Maktab majburiy"})),
    password: Yup.string()
        .min(8, intl.formatMessage({id: "Minimal 8 ta belgi"}))
        // .matches(/[0-9]/, intl.formatMessage({id: "Kamida bitta raqam bo‘lishi majburiy"}))
        // .matches(/[a-z]/, intl.formatMessage({id: "Kamida bitta kichkina harf bo‘lishi majburiy"}))
        .required(intl.formatMessage({id: "Parol majburiy"})),
    changepassword: Yup.string()
        .min(3, intl.formatMessage({id: "Minimal 3 ta belgi"}))
        .required(intl.formatMessage({id: "Parolni tasdiqlash majburiy"}))
        .oneOf([Yup.ref("password")], intl.formatMessage({id: "Parol yuqoridagi bilan mos emas"})),
  });

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setSubmitting, setStatus, setErrors}) => {
      setLoading(true);

      try {
        const {data: auth} = await requestJshirRegister(
            values.jshir,
            values.firstname,
            values.lastname,
            values.familyname,
            values.detebirth,
            values.gender,
            values.county,
            values.district,
            values.organization,
            values.password,
            values.phone
        );

        setLoading(false);
        setSubmitting(false);
        sessionStorage.setItem(
            "flx_jshir_register_phone_confirm",
            onlyNumber(values.phone)
        );
        navigate(`/auth${URL_AUTH_JSHIR_REGISTER_CONFIRM}`);
      } catch (error: any) {
        if(error?.response?.data?.error_code === "4012"){
          setStatus(error.response.data?.message);
         setTimeout(() => {
           navigate(`/auth${URL_AUTH_REGISTER_INFO}`);
         }, 2000)
          ScrollTopComponent.goTop()
        }
        if ((error.response as any) && error.response.data) {
          setErrors(error.response.data);
          setSubmitting(false);
        }
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const handleSelectRegion = (e) => {
    formik.setFieldValue("county", e.target.value);
    setSelectedRegion(e.target.value);
  };

  return (
      <form
          className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          noValidate
          id="kt_login_signup_form"
          onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">
            <a target={"_blank"} href={ERP_MAKTAB_URL} className="link-primary">
              erp.maktab.uz
            </a>{" "}
            {intl.formatMessage({id: "siz royhatdan otish"})}
          </h1>

          <div className="text-gray-500 fw-semibold fs-6">
            {intl.formatMessage({
              id: "Royhatdan otish uchun malumotlarni toldiring",
            })}
          </div>
        </div>

        {formik.status && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
        )}

        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({id: "JSHSHIR"})}
          </label>
          <input
              placeholder={intl.formatMessage({id: "JSHSHIR"})}
              type="text"
              autoComplete="off"
              maxLength={14}
              pattern="\d*"
              onInput={(e) => {
                const target = e.target as HTMLInputElement;
                target.value = target.value.replace(/[^0-9]/g, '').slice(0, 14);
              }}
              {...formik.getFieldProps("jshir")}
              className={clsx(
                  "form-control bg-transparent",
                  {
                    "is-invalid": formik.touched.jshir && formik.errors.jshir,
                  },
                  {
                    "is-valid": formik.touched.jshir && !formik.errors.jshir,
                  }
              )}
          />
          {formik.touched.jshir && formik.errors.jshir && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.jshir}</span>
                </div>
              </div>
          )}
        </div>

        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({id: "Telefon raqam"})}
          </label>
          <input
              placeholder={intl.formatMessage({
                id: "Telefon raqamingizni kiriting",
              })}
              type="text"
              autoComplete="off"
              {...formik.getFieldProps("phone")}
              onChange={(e) =>
                  formik.setFieldValue("phone", phoneFormat(e.target.value))
              }
              className={clsx(
                  "form-control bg-transparent",
                  {
                    "is-invalid": formik.touched.phone && formik.errors.phone,
                  },
                  {
                    "is-valid": formik.touched.phone && !formik.errors.phone,
                  }
              )}
          />
          {formik.touched.phone && formik.errors.phone && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.phone}</span>
                </div>
              </div>
          )}
        </div>

        <div className="d-flex justify-content-between gap-3">
          <div className="fv-row mb-8 w-100">
            <label className="form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({id: "Ism"})}
            </label>
            <input
                placeholder={intl.formatMessage({id: "Ismingizni kiriting"})}
                type="text"
                autoComplete="off"
                {...formik.getFieldProps("firstname")}
                className={clsx(
                    "form-control bg-transparent",
                    {
                      "is-invalid":
                          formik.touched.firstname && formik.errors.firstname,
                    },
                    {
                      "is-valid":
                          formik.touched.firstname && !formik.errors.firstname,
                    }
                )}
            />
            {formik.touched.firstname && formik.errors.firstname && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.firstname}</span>
                  </div>
                </div>
            )}
          </div>

          <div className="fv-row mb-8 w-100">
            <label className="form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({id: "Familiya"})}
            </label>
            <input
                placeholder={intl.formatMessage({id: "Familiyangizni kiriting"})}
                type="text"
                autoComplete="off"
                {...formik.getFieldProps("lastname")}
                className={clsx(
                    "form-control bg-transparent",
                    {
                      "is-invalid": formik.touched.lastname && formik.errors.lastname,
                    },
                    {
                      "is-valid": formik.touched.lastname && !formik.errors.lastname,
                    }
                )}
            />
            {formik.touched.lastname && formik.errors.lastname && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.lastname}</span>
                  </div>
                </div>
            )}
          </div>
        </div>

        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({id: "Sharif"})}
          </label>
          <input
              placeholder={intl.formatMessage({id: "Sharifingizni kiriting"})}
              type="text"
              autoComplete="off"
              {...formik.getFieldProps("familyname")}
              className={clsx(
                  "form-control bg-transparent",
                  {
                    "is-invalid":
                        formik.touched.familyname && formik.errors.familyname,
                  },
                  {
                    "is-valid":
                        formik.touched.familyname && !formik.errors.familyname,
                  }
              )}
          />
          {formik.touched.familyname && formik.errors.familyname && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.familyname}</span>
                </div>
              </div>
          )}
        </div>

        <div className="d-flex justify-content-between gap-3">
          <div className="fv-row mb-8 w-100">
            <label className="form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({id: "Tug’ilgan sanangiz"})}
            </label>
            <Flatpickr
                {...formik.getFieldProps("detebirth")}
                onChange={(e) => formik.setFieldValue("detebirth", e)}
                className="form-control bg-transparent"
                placeholder={intl.formatMessage({id: "Tug’ilgan sanangiz"})}
            />
            {formik.touched.detebirth && formik.errors.detebirth && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.detebirth}</span>
                  </div>
                </div>
            )}
          </div>

          <div className="fv-row mb-8 w-100">
            <label className="form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({id: "Jins"})}
            </label>
            <div>
              <select
                  className="form-select bg-transparent"
                  data-kt-select2="true"
                  {...formik.getFieldProps("gender")}
                  onChange={(e) => formik.setFieldValue("gender", e.target.value)}
                  data-placeholder="Select option"
                  data-allow-clear="true"
              >
                <option value={""} selected disabled>{intl.formatMessage({id: "Tanlash"})}</option>
                <option value="MALE">{intl.formatMessage({id: "Erkak"})}</option>
                <option value="FEMALE">{intl.formatMessage({id: "Ayol"})}</option>
              </select>
            </div>
            {formik.touched.gender && formik.errors.gender && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.gender}</span>
                  </div>
                </div>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-between gap-3">
          <div className="fv-row mb-8 w-100">
            <label className="form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({id: "Viloyat"})}
            </label>
            <div>
              <select
                  className="form-select bg-transparent"
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-allow-clear="true"
                  {...formik.getFieldProps("county")}
                  onChange={(e) => handleSelectRegion(e)}
              >
                <option selected value={""}>{intl.formatMessage({id: "Tanlash"})}</option>
                {regions &&
                    regions?.map((region) => (
                        <option key={region.id} value={region.id}>
                          {region.name}
                        </option>
                    ))}
              </select>
            </div>
            {formik.touched.county && formik.errors.county && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.county}</span>
                  </div>
                </div>
            )}
          </div>

          <div className="fv-row mb-8 w-100">
            <label className="form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({id: "Tuman/Shahar"})}
            </label>
            <div>
              <select
                  className="form-select bg-transparent"
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-allow-clear="true"
                  {...formik.getFieldProps("district")}
                  onChange={(e) => formik.setFieldValue("district", e.target.value)}
              >
                <option>{intl.formatMessage({id: "Tanlash"})}</option>
                {districtId &&
                    districtId?.map((district) => (
                        <option key={district.id} value={district.id}>
                          {district.name}
                        </option>
                    ))}
              </select>
            </div>
            {formik.touched.district && formik.errors.district && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.district}</span>
                  </div>
                </div>
            )}
          </div>
        </div>

        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({id: "Maktab"})}
          </label>
          <input
              placeholder={intl.formatMessage({id: "Maktabingizni kiriting"})}
              type="text"
              autoComplete="off"
              {...formik.getFieldProps("organization")}
              className={clsx(
                  "form-control bg-transparent",
                  {
                    "is-invalid":
                        formik.touched.organization && formik.errors.organization,
                  },
                  {
                    "is-valid":
                        formik.touched.organization && !formik.errors.organization,
                  }
              )}
          />
          {formik.touched.organization && formik.errors.organization && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.organization}</span>
                </div>
              </div>
          )}
        </div>

        <div className="fv-row mb-8" data-kt-password-meter="true">
          <div className="mb-1">
            <label className="form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({id: "Parol"})}
            </label>
            <div className="position-relative mb-3">
              <PasswordInput
                  type="password"
                  placeholder={intl.formatMessage({id: "Parol o'ylab toping"})}
                  autoComplete="off"
                  {...formik.getFieldProps("password")}
                  className={clsx(
                      "form-control bg-transparent",
                      {
                        "is-invalid":
                            formik.touched.password && formik.errors.password,
                      },
                      {
                        "is-valid":
                            formik.touched.password && !formik.errors.password,
                      }
                  )}
              />
              {formik.touched.password && formik.errors.password && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.password}</span>
                    </div>
                  </div>
              )}
            </div>
          </div>
          <div className="text-muted">
            {intl.formatMessage({
              id: "8 yoki undan ko'p belgilar bilan foydalaning, harflar, raqamlar va belgilar aralashmasi bilan.",
            })}
          </div>
        </div>

        <div className="fv-row mb-5">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({id: "Parolni qayta kiriting"})}
          </label>
          <PasswordInput
              type="password"
              placeholder={intl.formatMessage({id: "Parolni qayta kiriting"})}
              autoComplete="off"
              {...formik.getFieldProps("changepassword")}
              className={clsx(
                  "form-control bg-transparent",
                  {
                    "is-invalid":
                        formik.touched.changepassword && formik.errors.changepassword,
                  },
                  {
                    "is-valid":
                        formik.touched.changepassword && !formik.errors.changepassword,
                  }
              )}
          />
          {formik.touched.changepassword && formik.errors.changepassword && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.changepassword}</span>
                </div>
              </div>
          )}
        </div>

        <button
            type="submit"
            id="kt_sign_up_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
              <span className="indicator-label">
            {intl.formatMessage({id: "Ro'yhatdan o'tish"})}
          </span>
          )}
          {loading && (
              <span className="indicator-progress" style={{display: "block"}}>
            {intl.formatMessage({id: "Iltimos kuting..."})}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
          )}
        </button>
        <div className="text-gray-500 text-center fw-semibold fs-5">
          {intl.formatMessage({id: "Akkauntingiz bormi?"})}{" "}
          <Link to={`/auth${URL_AUTH_LOGIN}`} className="link-primary">
            {intl.formatMessage({id: "Kirish"})}
          </Link>
        </div>
      </form>
  );
}
