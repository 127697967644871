import React, { createContext, useContext, useState, ReactNode } from 'react';
import Components from "../components";

interface Notification {
  status?: "success" | "warning" | "error";
  title?: string | number;
  message?: string | number;
}

interface NotificationContextType {
  notification: Notification | null;
  showNotification: (title?: string| number, message?: string| number, status?: "success" | "warning" | "error") => void;
  hideNotification: () => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [notification, setNotification] = useState<Notification | null>(null);

  const showNotification = (title?: string|number, message?: string| number, status?: "success" | "warning" | "error") => {
    setNotification({ status, title, message });
  };

  const hideNotification = () => {
    setNotification(null);
  };

  return (
      <NotificationContext.Provider value={{ notification, showNotification, hideNotification }}>
        {children}
        {notification && <Components.Notification notification={notification} onHide={hideNotification} />}
      </NotificationContext.Provider>
  );
};
