import { Route, Routes } from "react-router-dom";
import { AuthLayout } from "../components/layout/auth/AuthLayout";
import { mock } from "../mock";

const AuthPage = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        {mock.authPages.map((page) => (
          <Route key={page.path} path={page.path} element={<page.component />} />
        ))}
      </Route>
    </Routes>
  );
};

export { AuthPage };
