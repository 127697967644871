import React, { FC } from 'react';
import { Navbar } from "../navbar";

interface IProps {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const Header: FC<IProps> = ({ title, description, children, style }) => {
  const isDark = localStorage.getItem("kt_theme_mode_value") === "dark";

  return (
      <div
          id={"home"}
          className="object-fit-cover m-5 m-sm-5 m-md-5 m-lg-10  p-5 p-sm-5 p-md-5 p-lg-10  rounded-4"
          style={{
            maxHeight: "80vh",
            backgroundColor: isDark ? "rgba(50, 50, 50, 0.5)" :"rgba(249, 249, 249, 1)",
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundImage: "url(/media/patterns/vector-white.png)"
          }}
      >
        <Navbar />
        <div className={"d-flex align-items-center"} style={{...style}}>
          <div className="mx-auto w-100 w-sm-100 w-md-75 w-lg-50 text-center text-dark my-15">
            <h1 className="display-6 display-md-5 display-lg-4">
              {title}
            </h1>
            <p className="fs-5 fs-md-4 fs-lg-3">
              {description}
            </p>
            <div className="d-flex justify-content-center">
              {children}
            </div>
          </div>
        </div>
      </div>
  );
};
