/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { WithChildren } from "../../../../_metronic/helpers";

export interface PageLink {
  title: string;
  path: string;
  isActive: boolean;
  isSeparator?: boolean;
}

export interface PageToolbar {
  title: string;
  isActive: boolean;
  icon?: string;
  additional?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}

export interface PageDataContextModel {
  pageDescription?: string;
  setPageDescription: (_description: string) => void;
  pageBreadcrumbs?: Array<PageLink>;
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void;
  pageToolbar?: Array<PageToolbar>;
  setPageToolbar?: (_toolbar: Array<PageToolbar>) => void;
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  setPageToolbar: (_toolbar: Array<PageToolbar>) => {}
});

const PageDataProvider: FC<WithChildren> = ({ children }) => {
  const [pageDescription, setPageDescription] = useState<string>("");
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([]);
  const [pageToolbar, setPageToolbar] = useState<Array<PageToolbar>>([])
  const value: PageDataContextModel = {
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    pageToolbar,
    setPageToolbar,
  };
  return (
      <PageDataContext.Provider value={value}>
        {children}
      </PageDataContext.Provider>
  );
};

function usePageData() {
  return useContext(PageDataContext);
}

type Props = {
  description?: string;
  breadcrumbs?: Array<PageLink>;
  toolbar?: Array<PageToolbar>;
};

const PageTitle: FC<Props & WithChildren> = ({ description, breadcrumbs, toolbar }) => {
  const { setPageDescription, setPageBreadcrumbs, setPageToolbar } = usePageData();

  useEffect(() => {
    if (description) {
      setPageDescription(description);
    }
    return () => {
      setPageDescription("");
    };
  }, [description]);

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs);
    }
    return () => {
      setPageBreadcrumbs([]);
    };
  }, [breadcrumbs]);

  useEffect(() => {
    if(toolbar) {
      setPageToolbar?.(toolbar)
    }
    return () => {
      setPageToolbar?.([])
    }
  }, [toolbar])

  return <></>;
};

const PageDescription: FC<WithChildren> = ({ children }) => {
  const { setPageDescription } = usePageData();
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString());
    }
    return () => {
      setPageDescription("");
    };
  }, [children]);
  return <></>;
};

export { PageDescription, PageTitle, PageDataProvider, usePageData };
