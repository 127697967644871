import React, {FC, useState} from 'react';
import './styles.scss';
import {checkStatus} from "../../../../helpers";

interface IProps {
  options: { id: number | string, label: string; type: string, className?: string }[],
  defaultLabel: string,
  selectedLabel: string,
  onSelect?: (id: number | string | any) => void,
}

export const Dropdown: FC<IProps> = ({options, defaultLabel, selectedLabel, onSelect}) => {
  const [selected, setSelected] = useState<string | null>(selectedLabel);

  const handleSelect = (option: { id: number | string, type: string, label: string }) => {
    setSelected(option.type === "status" ? checkStatus(option.label, "text") : option.label);
    if (onSelect) {
      onSelect(option.type === "status" ? checkStatus(option.label, "text") : option.label);
    }
  };

  return (
      <div className="dropdown">
        <button
            className="btn w-100 d-flex justify-content-between p-3 align-items-center btn-light border border-gray-300 dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
        <span
            className={`${selected === defaultLabel ? "text-gray-600 ps-0 fs-6" : ""} badge badge-light-${checkStatus(checkStatus(selected || "", "reverseText"))}`}>
          {selected === defaultLabel ? defaultLabel : selected}
        </span>
        </button>
        <ul className={`dropdown-menu w-100 ${options.length > 10 && "h-200px"} overflow-y-scroll`}
            aria-labelledby="dropdownMenuButton">
          {options.length>0 ? options.map((option, index) => (
              <li key={index} className={"user-select-none truncate-2"}>
                {
                  options.length > 0 ? <a
                          className={`dropdown-item d-flex align-items-center justify-content-between ${selected === option.label ? "bg-light-primary" : ""}`}
                          onClick={() => handleSelect(option)}
                      >
                    <span
                        className={`badge badge-light-${option.type === "status" ? checkStatus(option.label) : option.label}`}>
                      {option.type === "status" ? checkStatus(option.label, "text") : option.label}
                    </span>
                      </a>
                      : <>Bo'sh</>
                }
              </li>
          )): <li className={"user-select-none text-center text-gray-500 mt-5"}>Bo'sh</li>}
        </ul>
      </div>
  );
};
