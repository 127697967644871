import restorePassword from "./restore-password";
import register from "./register";
import jshirregister from "./jshir-register";

const auth = {
  restorePassword,
  register,
  jshirregister
};

export default auth;
