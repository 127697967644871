import {ResultsSection} from "./results";
import {DocumentsSection} from "./documents";
import {OrderSection} from "./order";
import {MethodProductsSection} from "./methodProducts";
import {PopularWorksSection} from "./popularWorks";
import {FaqSection} from "./faq";



const landing = {
  ResultsSection,
  DocumentsSection,
  OrderSection,
  MethodProductsSection,
  PopularWorksSection,
  FaqSection
}

export default landing
