import { openDB } from 'idb';

const DB_NAME = 'teacherAppealDB';
const STORE_NAME = 'formData';

export const initDB = async () => {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id' });
      }
    },
  });
};

export const saveFormData = async (data: any) => {
  const db = await initDB();
  await db.put(STORE_NAME, { id: 1, ...data });
};

export const getFormData = async () => {
  const db = await initDB();
  return await db.get(STORE_NAME, 1);
};

export const clearFormData = async () => {
  const db = await initDB();
  await db.delete(STORE_NAME, 1);
};