import React, { useEffect, useState } from 'react';
import { KTIcon } from "../../../../_metronic/helpers";
import "./styles.scss"

interface NotificationProps {
  notification: {
    status?: "success" | "warning" | "error";
    title?: string | number;
    message?: string | number;
  };
  onHide: () => void;
}

export const Notification: React.FC<NotificationProps> = ({ notification, onHide }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      setTimeout(onHide, 300);
    }, 3000);

    return () => clearTimeout(timer);
  }, [notification, onHide]);

  const { status = "error", message = "", title } = notification;

  return (
      <div
          className={`alert alert-dismissible bg-${status === "success" ? "success" : status === "error" ? "danger" : "warning"} d-flex flex-column align-items-center flex-sm-row p-5 mb-10 ${show ? 'fade-in' : 'fade-out'}`}
          style={{
            position: 'fixed',
            top: "10px",
            right: "10px",
            zIndex: 9999,
            maxWidth: '100%',
            transition: 'opacity 0.3s ease-out',
          }}
      >
        <KTIcon
            iconName={`${status === "success" ? "shield-tick" : status === "error" ? "shield-cross" : "information-3"}`}
            className="fs-3x text-white me-2"
            iconType="duotone"
        />
        <div className="d-flex flex-column text-light pe-0 ps-3 pe-sm-10">
          <h5 className="mb-1 text-white capitalize">{title}</h5>
          <span>{message}</span>
        </div>
        <button
            type="button"
            className="position-absolute top-0 end-0 m-2 btn btn-icon"
            onClick={() => setShow(false)}
        >
          <KTIcon iconName="cross" className="fs-2x text-white" iconType="duotone" />
        </button>
      </div>
  );
};
