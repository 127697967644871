/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from "react";
import {useIntl} from "react-intl";
import {PageLink, PageTitle, PageToolbar} from "../../components/layout/core";
import {useNavigate, useParams} from "react-router-dom";
import {Card} from "../../components/common";
import CheckMySelfSection from "../../components/section/admin/checkMySelf/CheckMySelf";
import {useQuery} from "react-query";
import {getModeratorAppealId} from "../../api/_requests";
import {nameGenerator} from "../../helpers";
import {BASE_URL} from "../../api/URLs";

const DVEvaluationPage: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate()
  const {id} = useParams<{ id: string }>();

  const {data: moderatorAppealId, isSuccess} = useQuery(
      ["moderator_appeal_id", id],
      () => getModeratorAppealId(id),
      {
        enabled: !!id,
      }
  );

  const evaluationViewBreadCrumbs: Array<PageLink> = [
    {
      title: `${intl.formatMessage({id: "Arizalar"})} / ${nameGenerator(moderatorAppealId?.data?.user?.first_name || "", moderatorAppealId?.data?.user?.last_name || "", moderatorAppealId?.data?.user?.family_name)} / ${intl.formatMessage({id: "Baholash"})}`,
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];

  const evaluationViewToolbars: Array<PageToolbar> = [
    {
      title: intl.formatMessage({id: "Metodik ishlanmani ko’rish"}),
      icon: "exit-right-corner",
      isActive: true,
      additional: {
        onClick: () => window.open(BASE_URL + moderatorAppealId?.data.file),
      },
    },
  ];

  return (
      <>
        <PageTitle
            breadcrumbs={evaluationViewBreadCrumbs}
            toolbar={evaluationViewToolbars}
            description={intl.formatMessage({id: "Muallif, metodik ishlanma va savollar"})}
        />
        <div className="px-10">
          <div className={"card rounded-4 border border-gray-200 p-10"}>
            <p className={"text-gray-700"}>{intl.formatMessage({id: "Ariza haqida"})}</p>
            <div className="row">
              <div className="col-md-4">
                <Card
                    iconSize={"4x"}
                    padding={"p-10"}
                    title={nameGenerator(moderatorAppealId?.data?.user?.first_name || "", moderatorAppealId?.data?.user?.last_name || "", moderatorAppealId?.data?.user?.family_name)}
                    description={intl.formatMessage({id: "F.I.O"})}
                    icon={"user"}
                />
              </div>
              <div className="col-md-4">
                <Card
                    iconSize={"4x"}
                    padding={"p-10"}
                    title={moderatorAppealId?.data.science?.name}
                    description={intl.formatMessage({id: "Fan"})}
                    icon={"teacher"}
                />
              </div>
              <div className="col-md-4">
                <Card
                    iconSize={"4x"}
                    padding={"p-10"}
                    title={moderatorAppealId?.data.method?.name}
                    description={intl.formatMessage({id: "Metodik ishlanma turi"})}
                    icon={"note-2"}
                />
              </div>
              <div className="col-md-4">
                <Card
                    iconSize={"4x"}
                    padding={"p-10"}
                    title={moderatorAppealId?.data.academic_year.name}
                    description={intl.formatMessage({id: "Topshirilgan o’quv yili"})}
                    icon={"calendar-8"}
                />
              </div>
            </div>
          </div>

          <div className={"ms-3 mt-5"}>
            <CheckMySelfSection data={moderatorAppealId}/>
          </div>
        </div>
      </>
  );
};

export {DVEvaluationPage};
