import React from "react";
import {Dropzone} from "../../../../common";
import {Link, useOutletContext} from "react-router-dom";
import {useIntl} from "react-intl";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {
  URL_TEACHER_MAIN_NEW_ABOUT,
  URL_TEACHER_MAIN_NEW_DATA,
  URL_TEACHER_MAIN_NEW_PED,
} from "../../../../../mock/page_urls";

export function AppealDataSection() {
  const intl = useIntl();
  const { dataForm, setDataForm, handleSave } = useOutletContext<any>();

  const validationSchema = Yup.object({
    diploma_file: Yup.mixed().required(intl.formatMessage({ id: "Diplom faylini yuklash majburiy" })),
    objective_file: Yup.mixed().required(intl.formatMessage({ id: "Obyektivka faylini yuklash majburiy" })),
  });

  return (
      <Formik
          initialValues={{
            diploma_file: dataForm?.diploma_file || null,
            objective_file: dataForm?.objective_file || null,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, validateForm }) => {
            const errors = await validateForm();
            if (Object.keys(errors).length === 0) {
              setDataForm(values);
              handleSave();
            }
            setSubmitting(false);
          }}
      >
        {({ setFieldValue, errors, values, touched }) => (
            <Form>
              <div className="card rounded-4 border border-gray-200 p-5 p-md-10 p-lg-20 pb-0 pt-1">
                <div className="p-5 p-md-10 p-lg-10">
                  <div className="d-flex flex-wrap column-gap-5 column-gap-md-10 column-gap-lg-20 fs-3">
                    <Link to={URL_TEACHER_MAIN_NEW_ABOUT} className="text-dark pb-0 pb-md-3 pb-lg-5">
                      {intl.formatMessage({ id: "Malumot" })}
                    </Link>
                    <Link to={URL_TEACHER_MAIN_NEW_PED} className="text-dark pb-0 pb-md-3 pb-lg-5">
                      {intl.formatMessage({ id: "Maktab Pedagogik kengash qarori" })}
                    </Link>
                    <Link to={URL_TEACHER_MAIN_NEW_DATA} className="pb-0 pb-md-3 pb-lg-5 fw-bolder text-primary border-primary border-bottom ">
                      {intl.formatMessage({ id: "Mutahasislik ma’lumotlari" })}
                    </Link>
                  </div>

                  <div className="my-20">
                    <h1 className="fw-bold">{intl.formatMessage({ id: "Malumotlar" })}</h1>
                    <p className="text-gray-500 fw-bold">
                      {intl.formatMessage({ id: "Mutahasislik ma’lumotlari bo’yicha ma’lumotlarni kiriting" })}
                    </p>
                  </div>

                  <div className="row">
                    <div className="mb-5">
                      <label className="form-label">
                        {intl.formatMessage({ id: "Diplomingizni yuklang" })}{" "}
                        <span className="text-danger fs-3">*</span>
                      </label>
                      <Dropzone
                          title={intl.formatMessage({ id: "Diplomingizni yuklang (PDF)" })}
                          fileDrop={values?.diploma_file}
                          setFileDrop={(file) => {
                            setFieldValue("diploma_file", file);
                            setDataForm((prev) => ({ ...prev, diploma_file: file }));
                          }}
                      />
                      {touched.diploma_file && errors.diploma_file && (
                          <div className="text-danger mt-2">{String(errors.diploma_file)}</div>
                      )}
                    </div>

                    <div className="mb-5">
                      <label className="form-label">
                        {intl.formatMessage({ id: "Obyektivkangizni yuklang" })}{" "}
                        <span className="text-danger fs-3">*</span>
                      </label>
                      <Dropzone
                          title={intl.formatMessage({ id: "Obyektivkangizni yuklang (PDF)" })}
                          fileDrop={values?.objective_file}
                          setFileDrop={(file) => {
                            setFieldValue("objective_file", file);
                            setDataForm((prev) => ({ ...prev, objective_file: file }));
                          }}
                      />
                      {touched.objective_file && errors.objective_file && (
                          <div className="text-danger mt-2">{String(errors.objective_file)}</div>
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-end mt-10 gap-5">
                    <Link to="/main/new/ped" className="btn btn-secondary">
                      {intl.formatMessage({ id: "Ortga qaytish" })}
                    </Link>
                    <button type="submit" className="btn btn-primary">
                      {intl.formatMessage({ id: "Saqlash" })}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
        )}
      </Formik>
  );
}
