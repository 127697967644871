import { I18N_CONFIG_KEY } from "../../_metronic/i18n/Metronici18n";

const localTranslate = (uz, ru, en) => {
  const storedLang = localStorage.getItem(I18N_CONFIG_KEY);
  if (storedLang) {
    const parsedLang = JSON.parse(storedLang);
    if (parsedLang && parsedLang.selectedLang) {
      switch (parsedLang.selectedLang) {
        case 'uz':
          return uz;
        case 'ru':
          return ru;
        case 'en':
          return en;
        default:
          return uz;
      }
    }
  }
  return uz;
};

export default localTranslate;
