/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {PageLink, PageTitle, PageToolbar} from "../../components/layout/core";
import {Empty, Loader} from "../../components/common";
import {KTIcon} from "../../../_metronic/helpers";
import {
  getAllModerators
} from "../../api/_requests";
import {checkStatus, downloadFile} from "../../helpers";
import {useQuery} from "react-query";
import Components from "../../components";
import {Dropdown} from "../../components/common/table/dropdown";
import {useFormik} from "formik";
import {URL_ADMIN_METHODISTS, URL_ADMIN_METHODISTS_NEW} from "../../mock/page_urls";
import {useAuth} from "../../components/layout/auth";
import {mock} from "../../mock";



const MethodistsPage: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {currentUser} = useAuth()
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';


  const methodistsBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: "Barcha metodistlar"}),
      path: "/",
      isSeparator: false,
      isActive: false,
    },
  ];

  const methodistsToolbars: Array<PageToolbar> = [
    {
      title: intl.formatMessage({id: "Metodist qo'shish"}),
      icon: "plus-square",
      isActive: true,
      additional: {
        onClick: () => navigate(URL_ADMIN_METHODISTS_NEW)
      },
    },
  ];

  const formik = useFormik({
    initialValues: {
      is_active: null,
      search: null,
    },
    onSubmit: (values) => {
    },
  });

  const {data: moderatorsData, refetch:refetchModerators, isLoading} = useQuery(
      ['moderators', formik.values, page],
      () => getAllModerators(formik.values, page),
      {
        refetchOnWindowFocus: false,
        enabled: !!formik.values,
      }
  );

  const statusReportDataFormatted = [
    {
      id: "UNBLOCKED",
      label: "UNBLOCKED",
      type: 'status',
    },
    {
      id: "BLOCKED",
      label: "BLOCKED",
      type: 'status',
    }
  ];

  useEffect(() => {
    refetchModerators();
  }, [page, refetchModerators]);

  return (
      <>
        <PageTitle
            description={`${intl.formatMessage({id: "Sizga tegishli viloyat"})} (${currentUser?.data?.address?.region?.name}) ${intl.formatMessage({id: "bo’yicha barcha metodistlar"})}`}
            breadcrumbs={methodistsBreadCrumbs}
            toolbar={methodistsToolbars}
        />
        <div className="px-10">
          <div
              className="card rounded-top-4 rounded-bottom-0 pb-5 border border-gray-200  d-flex flex-column w-100">
            <div className={"ps-7 pt-7"}>
              <h1 className="fs-3">{intl.formatMessage({id: "Metodistlar"})}</h1>
            </div>
            <div className={"d-flex px-5 gap-3"}>
              <div className="col-md-1">
                <Dropdown
                    options={statusReportDataFormatted}
                    defaultLabel={intl.formatMessage({id: "Status"})}
                    selectedLabel={formik.values.is_active || intl.formatMessage({id: "Status"})}
                    onSelect={(value) => formik.setFieldValue('is_active', value === 'Status' ? null : checkStatus(value || "", "reverseText"))}
                />
              </div>
              <div className="col-md-3 d-flex align-items-center position-relative">
                <KTIcon iconName="magnifier" className="fs-1 position-absolute ms-4"/>
                <input
                    type="text"
                    data-kt-docs-table-filter="search"
                    className="form-control w-250px ps-15"
                    placeholder={intl.formatMessage({id: "Qidiruv..."})}
                    name="search"
                    value={formik.values.search || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('search', e.target.value)}
                    onBlur={formik.handleBlur}
                />
              </div>
            </div>
          </div>


          {isLoading ? (
             <Loader height={"70vh"}/>
          ) : moderatorsData?.results.length > 0 ? (
              <Components.Table
                  header={mock.tableOptions.adminMethodistHeader}
                  removeClassNames={true}
                  forPaginationData={moderatorsData}
                  title=" "
                  body={moderatorsData?.results.map((d) => ({
                    ...d,
                    actions: (
                        <div className="d-flex flex-row gap-2">
                          <Link to={`${URL_ADMIN_METHODISTS}/${d.id}`} className="btn btn-icon btn-secondary">
                            <KTIcon iconName="eye" className="fs-1"/>
                          </Link>
                        </div>
                    ),
                  }))}
                  bodyKey={[...mock.tableOptions.adminMethodistBodyKey, "actions"]}
              />
          ) : <Empty title={intl.formatMessage({id: "Metodistlar mavjud emas!"})}/>
          }
        </div>
      </>
  );
};

export {MethodistsPage};
