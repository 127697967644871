import React from "react";
import {Dropzone} from "../../../../common";
import {KTIcon} from "../../../../../../_metronic/helpers";
import {Link, useNavigate, useOutletContext} from "react-router-dom";
import Flatpickr from "react-flatpickr";
import {useIntl} from "react-intl";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {
  URL_TEACHER_MAIN_NEW_ABOUT,
  URL_TEACHER_MAIN_NEW_DATA,
  URL_TEACHER_MAIN_NEW_PED
} from "../../../../../mock/page_urls";

export function AppealPedSection() {
  const navigate = useNavigate();
  const intl = useIntl();
  const { pedForm, setPedForm, handleSave } = useOutletContext<any>();

  const validationSchema = Yup.object({
    decision_date: Yup.date().required(intl.formatMessage({ id: "Qaror qabul qilingan sana majburiy" })),
    decision_number: Yup.string().required(intl.formatMessage({ id: "Qaror raqami majburiy" })),
    decision_file: Yup.mixed().required(intl.formatMessage({ id: "Maktab Pedagogik kengashi qarorini yuklang" })),
  });

  const handleDateChange = ([decision_date]: [Date]) => {
    setPedForm((prev) => ({ ...prev, decision_date }));
  };

  const handleFileDrop = (decision_file: File | null) => {
    setPedForm((prev) => ({ ...prev, decision_file }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPedForm((prev) => ({ ...prev, [name]: value }));
  };

  return (
      <>
        <Formik
            initialValues={{
              decision_number: pedForm?.decision_number || "",
              decision_date: pedForm?.decision_date || "",
              decision_file: pedForm?.decision_file || null,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              setPedForm(values);
            }}
        >
          {({ setFieldValue, validateForm, values, setTouched, errors, touched }) => (
              <Form>
                <div className="card rounded-4 border border-gray-200 p-5 p-md-10 p-lg-20 pb-0 pt-1">
                  <div className="p-5 p-md-10 p-lg-10">
                    <div className="d-flex flex-wrap gap-5 gap-md-10 gap-lg-20 fs-3">
                      <Link to={URL_TEACHER_MAIN_NEW_ABOUT} className="text-dark pb-0 pb-md-3 pb-lg-5">{intl.formatMessage({id: "Malumot"})}</Link>
                      <Link to={URL_TEACHER_MAIN_NEW_PED} className="pb-0 pb-md-3 pb-lg-5 fw-bolder text-primary border-primary border-bottom">
                        {intl.formatMessage({id: "Maktab Pedagogik kengashi qarori"})}
                      </Link>
                      <Link to={URL_TEACHER_MAIN_NEW_DATA} className="text-dark pb-0 pb-md-3 pb-lg-5">{intl.formatMessage({id: "Mutahasislik ma’lumotlari"})}</Link>
                    </div>

                    <div className="my-20">
                      <h1 className="fw-bold">{intl.formatMessage({id: "Maktab Pedagogik kengashi qarori"})}</h1>
                      <p className="text-gray-500 fw-bold">
                        {intl.formatMessage({id: "Maktab Pedagogik kengashi bo’yicha ma’lumotlarni kiriting"})}
                      </p>
                    </div>

                    <div className="row">
                      <div className="mb-5 col-6">
                        <label className="form-label">
                          {intl.formatMessage({id: "Qaror qabul qilingan sana "})}
                          <span className="text-danger fs-3">*</span>
                        </label>
                        <Flatpickr
                            value={values.decision_date}
                            onChange={([date]) => setFieldValue("decision_date", date)}
                            className="form-control"
                            name={"decision_date"}
                            placeholder={intl.formatMessage({id: "Tanlash"})}
                            options={{dateFormat: "d-m-Y"}}
                        />
                        {touched.decision_date && errors.decision_date && (
                            <div className="text-danger">{String(errors.decision_date)}</div>
                        )}
                      </div>

                      <div className="mb-5 col-6">
                        <label className="form-label">
                          {intl.formatMessage({id: "Qaror raqami"})} <span className="text-danger fs-3">*</span>
                        </label>
                        <Field
                            type="text"
                            className="form-control"
                            placeholder={intl.formatMessage({id: "Qaror raqami"})}
                            name={"decision_number"}
                            onChange={(e)=>setFieldValue("decision_number", e.target.value)}
                        />
                        {touched.decision_number && errors.decision_number && (
                            <div className="text-danger">{String(errors.decision_number)}</div>
                        )}
                      </div>

                      <div className="mb-5">
                        <label
                            className="form-label">{intl.formatMessage({id: "Maktab Pedagogik kengashi qarorini yuklang"})} </label>
                        <Dropzone
                            title={intl.formatMessage({id: "Maktab Pedagogik kengashi qarorini yuklang"})}
                            fileDrop={values.decision_file}
                            setFileDrop={(file) => setFieldValue("decision_file", file)}
                        />
                        {touched.decision_file && errors.decision_file && (
                            <div className="text-danger">{String(errors.decision_file)}</div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-end mt-10 gap-5">
                      <Link to="/main/new/about" className="btn btn-secondary">
                        {intl.formatMessage({id: "Ortga qaytish"})}
                      </Link>
                      <button
                          type="button"
                          className="btn btn-primary"
                          onClick={async () => {
                            setTouched({
                              decision_number: true,
                              decision_date: true,
                              decision_file: true,
                            });
                            const errors = await validateForm();
                            if (Object.keys(errors).length === 0) {
                              setPedForm(values);
                              navigate("/main/new/data");
                            }
                          }}
                      >
                        {intl.formatMessage({id: "Davom etish"})} <KTIcon iconName="arrow-right" className="fs-1"/>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
          )}
        </Formik>
      </>
  );
}
