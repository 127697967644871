import React from 'react'
import {KTIcon} from "../../../../../_metronic/helpers";
import {PopularWorksCard} from "./popularWorksCard";
import {URL_FILTER_MANUALS} from "../../../../mock/page_urls";
import {Link} from "react-router-dom";
import {getLandingLimiterPopularAppeals} from "../../../../api/_requests";
import {useQuery} from "react-query";
import {localTranslate} from "../../../../helpers";
import moment from "moment";
import {queryCacheOptions} from "../../../../mock/cacheOptions";

export const PopularWorksSection = () => {
  const {data:popularAppeals} = useQuery(["getLandingLimiterPopularAppeals", "limit"], ()=>getLandingLimiterPopularAppeals(3), {...queryCacheOptions})

  return (
      <div className={"bg-gray-200 p-5 p-lg-10 m-5 my-20 m-lg-10 my-lg-20 rounded-4"}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className={"mx-auto mb-10"}>
                <h1 className={"display-6 my-5"}>{localTranslate("Ommalashtirilgan ishlar", "Популярные произведения", "Popular works")}</h1>
                <p className={"fs-5"}>{moment().year()-1}-{moment().year()} {localTranslate("o'quv yilida Respublika bosqichida ommalashgan ishlar bilan tanishing", "знакомиться с произведениями, популяризированными на республиканском этапе в учебном году", "get acquainted with works popularized at the Republican stage in the academic year")}</p>
              </div>
            </div>
            <div className="col-md-6">
              <Link className={"fs-5 d-flex justify-content-end align-items-center gap-2"} to={URL_FILTER_MANUALS}>{localTranslate("Barchasini ko'rish", "Посмотреть все", "See all")} <KTIcon iconName={"arrow-right"} className={"fs-1 text-primary"} iconType={"solid"}/></Link>
            </div>
          </div>
          <div className="row mt-5 row-gap-5">
            {
              popularAppeals?.results?.map((appeal, index) => (
                  <div key={index} className="col-md-4">
                    <PopularWorksCard appeal={appeal}/>
                  </div>
              ))
            }
          </div>
        </div>
      </div>
  )
}
