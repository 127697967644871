import React, { FC } from 'react';
import { KTIcon } from "../../../../_metronic/helpers";
import { useDropzone } from 'react-dropzone';
import './styles.scss';
import { useIntl } from "react-intl";

interface IProps {
  title: string;
  fileDrop: File | null;
  setFileDrop: (file: File | null) => void;
  accept?: { [key: string]: string[] };
}

export const Dropzone: FC<IProps> = ({ title, fileDrop, setFileDrop, accept = { 'application/pdf': [] } }) => {
  const intl = useIntl();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    multiple: false,
    onDrop: (acceptedFiles: File[], fileRejections) => {
      if (fileRejections.length > 0) {
        alert(intl.formatMessage({ id: "Bunday turdagi fayl mumkin emas" }));
      } else {
        setFileDrop(acceptedFiles.length > 0 ? acceptedFiles[0] : null);
      }
    },
  });

  const handleCancel = () => {
    setFileDrop(null);
  };

  return (
    <div className="dropzone-container">
      <div
        {...getRootProps()}
        className={`dropzone-area d-flex align-items-center justify-content-center text-center rounded border border-dashed border-dark border-gray-300 w-100 h-300px ${isDragActive ? 'bg-light-primary text-white' : 'bg-light text-dark'}`}
        style={{ textAlign: 'center' }}
        aria-label="Dropzone area for file upload"
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className="dropzone-content w-700px">
            <KTIcon iconName="file-up" className="size-large text-primary mb-4" />
            <h1 className="fw-light res-fs">{title}</h1>
            <p className="text-gray-500 fw-bold mt-5">{intl.formatMessage({ id: "Faylni shu yerga tashlang" })}</p>
            <div className="d-flex align-items-center">
              <div className="horizontal-rule d-flex align-items-center">
                <span className="text">{intl.formatMessage({ id: "Yoki" })}</span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-7">
              <button type="button" className="btn btn-light-primary px-20 fw-bolder">{intl.formatMessage({ id: "Tanlash" })}</button>
            </div>
          </div>
        ) : (
          <div className="dropzone-content w-700px">
            <KTIcon iconName="file-up" className="size-large text-primary mb-4" />
            <h1 className="fw-light res-fs">{title}</h1>
            {!fileDrop && (
              <>
                <p className="text-gray-500 fw-bold mt-5">{intl.formatMessage({ id: "Faylni shu yerga tashlang" })}</p>
                <div className="d-flex align-items-center">
                  <div className="horizontal-rule d-flex align-items-center">
                    <span className="text bg-light">{intl.formatMessage({ id: "Yoki" })}</span>
                  </div>
                </div>
              </>
            )}
            {fileDrop ? (
              <div className="mt-3 gap-3 d-flex align-items-center justify-content-center">
                <p className="text-muted mb-0">{intl.formatMessage({ id: "Tanlandi" })}: {fileDrop.name}</p>
                <i className="fas fa-xmark fs-2 p-1 text-danger" onClick={handleCancel} style={{ borderRadius: '50%' }}></i>
              </div>
            ) : (
              <div className="d-flex justify-content-center mt-7">
                <button type="button" className="btn btn-light-primary px-20 fw-bolder">{intl.formatMessage({ id: "Tanlash" })}</button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};