import {useState} from "react";
import * as Yup from "yup";
import clsx from "clsx";
import {Link, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {useIntl} from "react-intl";
import {URL_AUTH_LOGIN, URL_AUTH_RESET_PASSWORD_CONFIRM,} from "../../../../mock/page_urls";
import phoneFormat from "../../../../helpers/phoneFormat";
import onlyNumber from "../../../../helpers/onlyNumber";
import {checkUserPhoneExist} from "app/api/_requests";

const initialValues = {
  phone: phoneFormat(sessionStorage.getItem("flex_restore_password_phone") ?? "") || "+998",
};


export function PhoneNumber() {
  const navigate = useNavigate();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  // const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);

  const forgotPasswordSchema = Yup.object().shape({
    phone: Yup.string()
        .length(17, intl.formatMessage({id: "Bu telefon raqam emas"}))
        .required(intl.formatMessage({id:"Telefon raqam majburiy"})),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting, setFieldError }) => {
      setLoading(true);
      try {
        const res =await checkUserPhoneExist(
          onlyNumber(values.phone),
        );
        if(res.data.data.success){
          navigate(`/auth${URL_AUTH_RESET_PASSWORD_CONFIRM}`);
        }else{
          setFieldError("phone", intl.formatMessage({id: "Ushbu raqam bo'yicha foydalanuvchi topilmadi"}));
        }
        setLoading(false);
        sessionStorage.setItem("flex_restore_password_phone", onlyNumber(values.phone));
      } catch (error: any) {
        if ((error.response as any) && error.response?.data?.message) {
          setFieldError("phone", error.response?.data?.message)
        }
        setLoading(false);
        setSubmitting(false);
        sessionStorage.setItem("flex_restore_password_phone", onlyNumber(values.phone));
      }
    },
  });

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">
          {intl.formatMessage({ id: "Parolni qayta tiklash" })}
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-500 fw-semibold fs-6">
          {intl.formatMessage({
            id: "Parolni tiklash uchun telefon raqamingizni kiriting",
          })}
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {/* {hasErrors === true && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className="mb-10 bg-light-info p-8 rounded">
          <div className="text-info">
            Sent password reset. Please check your phone
          </div>
        </div>
      )} */}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <label className="form-label fw-bolder text-gray-900 fs-6">
          {intl.formatMessage({ id: "Telefon raqam" })}
        </label>
        <input
          type="text"
          placeholder="Telefon raqamingiz"
          autoComplete="off"
          {...formik.getFieldProps("phone")}
          onChange={(e) =>
            formik.setFieldValue("phone", phoneFormat(e.target.value))
          }
          className={clsx(
            "form-control bg-transparent",
            { "is-invalid": formik.touched.phone && formik.errors.phone },
            {
              "is-valid": formik.touched.phone && !formik.errors.phone,
            }
          )}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="d-flex justify-content-center pb-lg-0 gap-2">
        <button
          type="submit"
          // onClick={() => navigate(`/auth${URL_AUTH_RESET_PASSWORD_CONFIRM}`)}
          id="kt_password_reset_submit"
          className="btn btn-primary w-100"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          <span className="indicator-label">
            {!loading && intl.formatMessage({ id: "Davom etish" })}
          </span>
          {loading && (
            <span className="indicator-progress" style={{display: "block"}}>
              {intl.formatMessage({ id: "Iltimos kuting..." })}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to={`/auth${URL_AUTH_LOGIN}`} className="w-100">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light w-100"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {intl.formatMessage({ id: "Bekor qilish" })}
          </button>
        </Link>{" "}
      </div>
      {/* end::Form group */}
    </form>
  );
}
