import { toAbsoluteUrl } from "../../_metronic/helpers";

export const languages = [
  {
    lang: 'uz',
    name: 'O\'zbekcha',
    flag: toAbsoluteUrl('/media/flags/uzbekistan.svg'),
  },
  {
    lang: 'ru',
    name: 'Русский',
    flag: toAbsoluteUrl('/media/flags/russia.svg'),
  },
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
]