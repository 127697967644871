import React from 'react'
import {Card} from "../../../common";
import {Link} from "react-router-dom";
import {useAuth} from "../../../layout/auth";
import {PROFILE_SENDMESSAGE, PROFILE_SETTINGS} from "../../../../mock/page_urls";
import {nameGenerator, phoneFormat} from "../../../../helpers";
import {NOT_ENTER} from "../../../../mock/text";
import {useIntl} from "react-intl";

export const OverviewSection = () => {
  const intl = useIntl();
  const {currentUser, logout} = useAuth();

  return (
      <>
        {
            currentUser && <div className={"card rounded-4 border border-gray-200 mt-10"}>
                <div>
                    <div className="p-10 pb-3">
                        <div className={"d-flex align-items-center justify-content-between mt-5"}>
                            <h2>{intl.formatMessage({id: "Umumiy ma’lumotlar"})}</h2>
                            <Link to={PROFILE_SETTINGS} className={"btn btn-primary"}>{intl.formatMessage({id: "Profil tahrirlash"})}</Link>
                        </div>
                    </div>
                    <hr className={"text-gray-500"}/>

                    <div className={"p-10 pt-5"}>
                        <div className="row fw-bold fs-3">
                            <div className="col-md-4 text-gray-500">
                                <p>{intl.formatMessage({id: "Ism, Familiya, Otasining ismi"})}</p>
                            </div>
                            <div className="col-md-8 text-gray-800">
                                <p>{nameGenerator(
                                    currentUser?.data?.first_name,
                                    currentUser?.data?.last_name,
                                    currentUser?.data?.family_name
                                )}</p>
                            </div>
                        </div>

                        <div className="row fw-bold fs-3">
                            <div className="col-md-4 text-gray-500">
                                <p>{intl.formatMessage({id: "Viloyat"})}</p>
                            </div>
                            <div className="col-md-8 text-gray-800">
                                <p>{(currentUser?.data?.address) && currentUser?.data?.address?.region?.name ? currentUser?.data?.address?.region?.name : NOT_ENTER}</p>
                            </div>
                        </div>

                        <div className="row fw-bold fs-3">
                            <div className="col-md-4 text-gray-500">
                                <p>{intl.formatMessage({id: "Maktab"})}</p>
                            </div>
                            <div className="col-md-8 text-gray-800">
                                <p>{currentUser?.data.address && currentUser?.data?.address?.organization.name ? currentUser?.data?.address?.organization?.name : NOT_ENTER}</p>
                            </div>
                        </div>

                        <div className="row fw-bold fs-3">
                            <div className="col-md-4 text-gray-500">
                                <p>{intl.formatMessage({id: `Telefon raqam`})}</p>
                            </div>
                            <div className="col-md-8 text-gray-800">
                                <p>{currentUser?.data.phone ? phoneFormat(currentUser?.data.phone) : NOT_ENTER}</p>
                            </div>
                        </div>

                    </div>

                  {
                      currentUser?.data.role !== "super_admin" && <div className={"p-10"}>
                          <Card
                              padding={"p-0"}
                              icon={"shield-cross"}
                              status={"warning"}
                              title={intl.formatMessage({id: "Shaxsiy ma’lumotlarda xatolik bormi?"})}
                              description={`${intl.formatMessage({id: "Shaxsiy ma’lumotlaringizda xatolik bo’lsa"})} <a href="${PROFILE_SENDMESSAGE}" target="_blank" rel="noopener noreferrer">${intl.formatMessage({id: "Administratorga xabar yo’llang"})}</a>`}
                          />
                      </div>
                  }


                </div>
            </div>
        }
      </>
  )
}
