import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {BASE_URL_MEDIA} from "../../api/URLs";
import {KTIcon} from "../../../_metronic/helpers";
import formatDateDDMMYYY from "../../helpers/formatDateDDMMYYY";
import {checkStatus, Fancybox, nameGenerator, phoneFormat} from "../../helpers";
import {useIntl} from "react-intl";
import {
  getDistrict,
  getModeratorReportChangeNameId,
  getRegions,
  updateModeratorReportChangeNameId
} from "../../api/_requests";
import {useNotification} from "../../context/NotificationContext";
import {PageLink, PageTitle} from "../../components/layout/core";

interface Region {
  id: string;
  name: string;
}

interface District {
  id: string;
  name: string;
}

export const ReportChangeNameViewPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {id} = useParams<{ id: string }>();
  const {showNotification} = useNotification();

  const validationSchema = Yup.object({
    first_name: Yup.string().required(intl.formatMessage({id: "Ism majburiy"})),
    last_name: Yup.string().required(intl.formatMessage({id: "Familiya majburiy"})),
    family_name: Yup.string().required(intl.formatMessage({id: "Otasining ismi majburiy"})),
    region: Yup.string().required(intl.formatMessage({id: "Viloyat majburiy"})),
    district: Yup.string().required(intl.formatMessage({id: "Tuman/Shahar tanlash majburiy"})),
    organization: Yup.string().required(intl.formatMessage({id: "Maktab nomi majburiy"})),
    phone: Yup.string().required(intl.formatMessage({id: "Telefon raqam majburiy"})),
  });


  const {data: moderatorReportChangeNameId, isSuccess} = useQuery(
      ["moderator_report_change_name_id", id],
      () => getModeratorReportChangeNameId(id),
      {
        enabled: !!id,
      }
  );

  const {data: regionData} = useQuery("regions", getRegions);

  const [districts, setDistricts] = useState<District[]>([]);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      family_name: '',
      region: '',
      district: '',
      organization: '',
      phone: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const res = await updateModeratorReportChangeNameId(id, values, "APPROVED");
      if (res.status === 200) {
        showNotification(intl.formatMessage({id: "Shikoyat hal etildi"}), "", "success");
        navigate("/reports/?section=additional");
      }
    },
  });

  const applicationViewBreadCrumbs: Array<PageLink> = [
    {
      title: `${intl.formatMessage({id: "Shikoyatlar"})} / ${nameGenerator(moderatorReportChangeNameId?.results.user.first_name || "", moderatorReportChangeNameId?.results.user.last_name || "", moderatorReportChangeNameId?.results.user.family_name || "")}`,
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];


  const {data: districtData} = useQuery<District[]>(
      ["districts", formik.values.region],
      () => formik.values.region ? getDistrict(formik.values.region) : Promise.resolve([]),
      {
        enabled: !!formik.values.region,
        onSuccess: (data) => setDistricts(data),
      }
  );

  useEffect(() => {
    if (moderatorReportChangeNameId) {
      formik.setValues({
        first_name: moderatorReportChangeNameId.results.user.first_name || '',
        last_name: moderatorReportChangeNameId.results.user.last_name || '',
        family_name: moderatorReportChangeNameId.results.user.family_name || '',
        region: String(moderatorReportChangeNameId.results.user.address.region.id) || '',
        district: String(moderatorReportChangeNameId.results.user.address.district.id) || '',
        organization: moderatorReportChangeNameId.results.user.address.organization.name || '',
        phone: moderatorReportChangeNameId.results.user.phone || '',
      });
    }
  }, [moderatorReportChangeNameId]);

  const handleRejectChangeName = async () => {
    const res = await updateModeratorReportChangeNameId(id, "", "REJECTED");
    if (res.status === 200) {
      showNotification(intl.formatMessage({id: "Shikoyat rad etildi"}), "", "success");
      navigate("/reports/?section=additional");
    }
  };

  const handleRegionChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue('region', e.target.value);
    formik.setFieldValue('district', '');
    setDistricts([]);
  };
  //
  // useEffect(() => {
  //   if(!isSuccess){
  //     navigate("/404");
  //   }
  // }, []);

  return (
      <div>
        <PageTitle
            breadcrumbs={applicationViewBreadCrumbs}
            description={intl.formatMessage({id: "Yuborilgan ariza"})}
        />
        <div className="px-10">
          <div className="bg-light rounded-4 border border-gray-200 p-10 p-md-20 pb-0 pt-1">
            <div className="py-10 py-md-20">
              <h2 className="fw-bold mb-10">{intl.formatMessage({id: "Ariza haqida"})}</h2>
              <div className="row row-gap-5">
                <div className="col-md-5 pe-5 border">
                  <Fancybox>
                    <a data-fancybox="gallery"
                       data-src={BASE_URL_MEDIA + moderatorReportChangeNameId?.results?.image ? BASE_URL_MEDIA + moderatorReportChangeNameId?.results?.image : "https://via.placeholder.com/300"}
                       data-caption={moderatorReportChangeNameId?.results?.title}
                    >
                      <img
                          width="100%"
                          className={"object-fit-contain h-300px"}
                          src={BASE_URL_MEDIA + moderatorReportChangeNameId?.results?.image}
                          alt={moderatorReportChangeNameId?.results?.title}
                      />
                    </a>
                  </Fancybox>
                </div>
                <div className="col-md-7">
                  <div className="d-flex gap-10 flex-wrap">
                    <div className="d-flex align-items-center gap-3">
                      <KTIcon iconName="time" className="fs-1 text-primary"/>
                      <span className="fs-2 text-gray-500">
                        {formatDateDDMMYYY(moderatorReportChangeNameId?.results?.created_at || "")}
                      </span>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <KTIcon
                          iconName="check"
                          className={`fs-1 text-${checkStatus(moderatorReportChangeNameId?.results.status || "")}`}
                      />
                      <span className={`fs-2 text-gray-500`}>
                        {checkStatus(moderatorReportChangeNameId?.results.status || "", "text")}
                      </span>
                    </div>
                  </div>
                  <h1 className="my-10 text-gray-800">
                    {moderatorReportChangeNameId?.results.title}
                  </h1>
                  <p className="fs-3 text-gray-600 lh-lg text-break">
                    {moderatorReportChangeNameId?.results.reason}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-light rounded-4 border border-gray-200 mt-5">
            <div className="pt-10 ps-10 pb-3">
              <h3 className={"text-gray-800 fw-bold"}>{intl.formatMessage({id: "Ma’lumotlarni o’zgartirish"})}</h3>
            </div>
            <hr className={"text-gray-400"}/>

            <form onSubmit={formik.handleSubmit}>
              <div className={"row px-10 py-3 align-items-center"}>
                <div className="col-md-3">
                  <label htmlFor="first_name" className="form-label text-gray-700 fw-bold">
                    {intl.formatMessage({id: "Ism, Familiya, Otasining ismi"})} <span className={"text-danger"}>*</span>
                  </label>
                </div>
                <div className="col-md-9">
                  <div className="row row-gap-5">
                    <div className="col-md-4">
                      <input
                          type="text"
                          name="first_name"
                          className={"form-control"}
                          placeholder={intl.formatMessage({id: "Ism"})}
                          value={formik.values.first_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                      />
                      {formik.touched.first_name && formik.errors.first_name ? (
                          <div className="text-danger">{formik.errors.first_name}</div>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <input
                          type="text"
                          name="last_name"
                          className={"form-control"}
                          placeholder={intl.formatMessage({id: "Familiya"})}
                          value={formik.values.last_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                      />
                      {formik.touched.last_name && formik.errors.last_name ? (
                          <div className="text-danger">{formik.errors.last_name}</div>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <input
                          type="text"
                          name="family_name"
                          className={"form-control"}
                          placeholder={intl.formatMessage({id: "Otasining ismi"})}
                          value={formik.values.family_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                      />
                      {formik.touched.family_name && formik.errors.family_name ? (
                          <div className="text-danger">{formik.errors.family_name}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className={"row px-10 py-3 align-items-center"}>
                <div className="col-md-3">
                  <label htmlFor="region" className="form-label text-gray-700 fw-bold">
                    {intl.formatMessage({id: "Viloyat"})} <span className={"text-danger"}>*</span>
                  </label>
                </div>
                <div className="col-md-9">
                  <select
                      name="region"
                      className="form-select"
                      aria-label="Select example"
                      value={formik.values.region}
                      onChange={handleRegionChange}
                      onBlur={formik.handleBlur}
                  >
                    <option value="" disabled>
                      {regionData && regionData.length > 0 ? intl.formatMessage({id: "Tanlash"}) : intl.formatMessage({id: "Viloyatlar yo'q"})}
                    </option>
                    {regionData?.map((region) => (
                        <option
                            key={region.id}
                            value={region.id}
                        >
                          {region.name}
                        </option>
                    ))}
                  </select>
                  {formik.touched.region && formik.errors.region ? (
                      <div className="text-danger">{formik.errors.region}</div>
                  ) : null}
                </div>
              </div>

              <div className={"row px-10 py-3 align-items-center"}>
                <div className="col-md-3">
                  <label htmlFor="district" className="form-label text-gray-700 fw-bold">
                    {intl.formatMessage({id: "Tuman / Shahar"})} <span className={"text-danger"}>*</span>
                  </label>
                </div>
                <div className="col-md-9">
                  <select
                      name="district"
                      className="form-select"
                      aria-label="Select example"
                      value={formik.values.district}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                  >
                    <option value="" disabled>
                      {districts.length > 0 ? intl.formatMessage({id: "Tanlash"}) : intl.formatMessage({id: "Tumanlar yo'q"})}
                    </option>
                    {districts.map((district) => (
                        <option
                            key={district.id}
                            value={district.id}
                        >
                          {district.name}
                        </option>
                    ))}
                  </select>
                  {formik.touched.district && formik.errors.district ? (
                      <div className="text-danger">{formik.errors.district}</div>
                  ) : null}
                </div>
              </div>

              <div className={"row px-10 py-3 align-items-center"}>
                <div className="col-md-3">
                  <label htmlFor="organization" className="form-label text-gray-700 fw-bold">
                    {intl.formatMessage({id: "Maktab"})} <span className={"text-danger"}>*</span>
                  </label>
                </div>
                <div className="col-md-9">
                  <input
                      type="text"
                      name="organization"
                      className={"form-control"}
                      placeholder={intl.formatMessage({id: "Maktab"})}
                      value={formik.values.organization}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                  />
                  {formik.touched.organization && formik.errors.organization ? (
                      <div className="text-danger">{formik.errors.organization}</div>
                  ) : null}
                </div>
              </div>

              <div className={"row px-10 py-3 align-items-center"}>
                <div className="col-md-3">
                  <label htmlFor="phone" className="form-label text-gray-700 fw-bold">
                    {intl.formatMessage({id: "Telefon raqam"})} <span className={"text-danger"}>*</span>
                  </label>
                </div>
                <div className="col-md-9">
                  <input
                      type="text"
                      name="phone"
                      className={"form-control"}
                      placeholder={intl.formatMessage({id: "Telefon raqam"})}
                      value={phoneFormat(formik.values.phone)}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                      <div className="text-danger">{formik.errors.phone}</div>
                  ) : null}
                </div>
              </div>

              <hr className={"text-gray-400"}/>
              <div className={"row px-10 py-3"}>
                <div className="col-md-12 text-end">
                  <button type="button" onClick={handleRejectChangeName} className="btn btn-light-danger mb-7 me-5">
                    {intl.formatMessage({id: "Rad etish"})}
                  </button>
                  <button type="submit" className="btn btn-primary mb-7">
                    {intl.formatMessage({id: "Saqlash"})}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
  );
};
